import React, { useState } from 'react';
import { RequestDayFilterEnum } from '../Enum/RequestDayFilterEnum';
import { TimePeriodFilterEnum } from '../Enum/TimePeriodFilterEnum';
import { WidgetTimeFilterEnum } from '../Enum/WidgetTimeFilterEnum';
import Authorization from '../utils/Authorization';

const WidgetContext = React.createContext();

export const WidgetProvider = ({ children }) => {
    const authData = new Authorization();

    const [selectedProvider, setSelectedProvider] = useState('');
    const [showInActiveProviders, setShowInActiveProviders] = useState(false);

    const [engagementSelectedPlan, setEngagementSelectedPlan] = useState('All');
    const [engagementSelectedPopulation, setEngagementSelectedPopulation] = useState(0);
    const [engagementDateFilter, setEngagementDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [engagementFromDate, setEngagementFromDate] = useState(null);
    const [engagementToDate, setEngagementToDate] = useState(null);
    const [engagementSelectedTeam, setEngagementSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [engagementSelectedProvider, setEngagementSelectedProvider] = useState('All');
    const [loadedEngagementTeam, setLoadedEngagementTeam] = useState(false);
    const [engagementShowInActiveProviders, setEngagementShowInActiveProviders] = useState(false);
    const [engagementShowInActiveTeams, setEngagementShowInActiveTeams] = useState(false);

    const [schedulingDateFilter, setSchedulingDateFilter] = useState(WidgetTimeFilterEnum.Last30Days);
    const [schedulingFromDate, setSchedulingFromDate] = useState(null);
    const [schedulingToDate, setSchedulingToDate] = useState(null);
    const [schedulingSelectedPlan, setSchedulingSelectedPlan] = useState('All');
    const [schedulingSelectedPopulation, setSchedulingSelectedPopulation] = useState(0);
    const [schedulingSelectedProvider, setSchedulingSelectedProvider] = useState('All');
    const [schedulingSelectedStaff, setSchedulingSelectedStaff] = useState('All');
    const [schedulingSelectedTeam, setSchedulingSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [loadedSchedulingTeam, setLoadedSchedulingTeam] = useState(false);
    const [schedulingShowInActiveProviders, setSchedulingShowInActiveProviders] = useState(false);
    const [schedulingShowInActiveTeams, setSchedulingShowInActiveTeams] = useState(false);
    const [schedulingShowInActiveStaffs, setSchedulingShowInActiveStaffs] = useState(false);

    const [requestSelectedPlan, setRequestSelectedPlan] = useState('All');
    const [requestSelectedProvider, setRequestSelectedProvider] = useState('All');
    const [requestSelectedTeam, setRequestSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [selectedRequestType, setSelectedRequestType] = useState('All');
    const [loadedRequestTeam, setLoadedRequestTeam] = useState(false);

    const [appointmentSelectedProvider, setAppointmentSelectedProvider] = useState('All');
    const [appointmentSelectedTeam, setAppointmentSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [appointmentDateFilter, setAppointmentDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [appointmentFromDate, setAppointmentFromDate] = useState(null);
    const [appointmentToDate, setAppointmentToDate] = useState(null);
    const [loadedAppointmentTeam, setLoadedAppointmentTeam] = useState(false);
    const [appointmentShowInActiveProviders, setAppointmentShowInActiveProviders] = useState(false);
    const [appointmentShowInActiveTeams, setAppointmentShowInActiveTeams] = useState(false);

    const [teamMemberSelectedPlan, setTeamMemberSelectedPlan] = useState('All');
    const [teamMemberSelectedPopulation, setTeamMemberSelectedPopulation] = useState(0);
    const [teamMemberSelectedTeam, setTeamMemberSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [loadedTeamMemberTeam, setLoadedTeamMemberTeam] = useState(false);
    const [teamShowInActiveTeams, setTeamShowInActiveTeams] = useState(false);
    const [teamMemberFromDate, setTeamMemberFromDate] = useState(null);
    const [teamMemberToDate, setTeamMemberToDate] = useState(null);

    const [reqSelectedProvider, setReqSelectedProvider] = useState('All');
    const [reqDayFilter, setReqDayFilter] = useState(RequestDayFilterEnum.WithIn15Days);
    const [reqDateFilter, setReqDateFilter] = useState(TimePeriodFilterEnum.Last3Months);
    const [reqFromDate, setReqFromDate] = useState(null);
    const [reqToDate, setReqToDate] = useState(null);
    const [loadedReqProvider, setLoadedReqProvider] = useState(false);
    const [requestShowInActiveProviders, setRequestShowInActiveProviders] = useState(false);

    const [tcTeam, setTcTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [tcFacility, setTcFacility] = useState('All');
    const [tcDateFilter, setTcDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [tcFromDate, setTcFromDate] = useState(null);
    const [tcToDate, setTcToDate] = useState(null);
    const [tcShowInActiveTeams, setTcShowInActiveTeams] = useState(false);

    const [engagementSummaryFilter, setEngagementSummaryFilter] = useState({
        dateFilter: TimePeriodFilterEnum.Last6Months,
        provider: 'All',
        team: Boolean(authData.TeamId) ? authData.TeamId : 'All',
        fromDate: null,
        toDate: null,
        showInActiveProviders: false,
        showInActiveTeams: false,
    });
    const [memberWithoutEncounterFilter, setMemberWithoutEncounterFilter] = useState({
        team: Boolean(authData.TeamId) ? authData.TeamId : 'All',
        healthPlan: 'All',
        population: 0,
        dateFilter: WidgetTimeFilterEnum.Last30Days,
        fromDate: null,
        toDate: null,
        populations: [],
        showInActiveTeams: false,
    });

    const [undeterminedProvider, setUndeterminedProvider] = useState('');
    const [undeterminedMeansOfAttempt, setUndeterminedMeansOfAttempt] = useState('0');
    const [undeterminedDateFilter, setUndeterminedDateFilter] = useState(WidgetTimeFilterEnum.Last30Days);
    const [undeterminedFromDate, setUndeterminedFromDate] = useState(null);
    const [undeterminedToDate, setUndeterminedToDate] = useState(null);
    const [undeterminedShowInActiveProviders, setUndeterminedShowInActiveProviders] = useState(false);

    const [inProgressProvider, setInProgressProvider] = useState('');
    const [inProgressSelectedDay, setInProgressSelectedDay] = useState('0');
    const [inProgressDateFilter, setInProgressDateFilter] = useState(WidgetTimeFilterEnum.Last30Days);
    const [inProgressFromDate, setInProgressFromDate] = useState(null);
    const [inProgressToDate, setInProgressToDate] = useState(null);
    const [inProgressShowInActiveProviders, setInProgressShowInActiveProviders] = useState(false);

    const [closedSelectedFilter, setClosedSelectedFilter] = useState('1');
    const [closedDateFilter, setClosedDateFilter] = useState(TimePeriodFilterEnum.Last3Months);
    const [closedFromDate, setClosedFromDate] = useState(null);
    const [closedToDate, setClosedToDate] = useState(null);

    const [qualityMetricsDateFilter, setQualityMetricsDateFilter] = useState(WidgetTimeFilterEnum.Last30Days);
    const [qualityMetricsFromDate, setQualityMetricsFromDate] = useState(null);
    const [qualityMetricsToDate, setQualityMetricsToDate] = useState(null);
    const [qualityMetricsTab, setQualityMetricsTab] = useState(1);

    const [exceptionTab, setExceptionTab] = useState(1);

    const [individualEngagementDateFilter, setIndividualEngagementDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [individualEngagementFromDate, setIndividualEngagementFromDate] = useState(null);
    const [individualEngagementToDate, setIndividualEngagementToDate] = useState(null);
    const [individualEngagementSelectedProvider, setIndividualEngagementSelectedProvider] = useState('All');
    const [individualEngagementShowInActiveProviders, setIndividualEngagementShowInActiveProviders] = useState(false);

    const value = {
        selectedProvider,
        setSelectedProvider,
        showInActiveProviders,
        setShowInActiveProviders,

        engagementSelectedPlan,
        setEngagementSelectedPlan,
        engagementSelectedPopulation,
        setEngagementSelectedPopulation,
        engagementSelectedProvider,
        setEngagementSelectedProvider,
        engagementDateFilter,
        setEngagementDateFilter,
        engagementFromDate,
        setEngagementFromDate,
        engagementToDate,
        setEngagementToDate,
        engagementSelectedTeam,
        setEngagementSelectedTeam,
        engagementShowInActiveProviders,
        setEngagementShowInActiveProviders,
        engagementShowInActiveTeams,
        setEngagementShowInActiveTeams,

        schedulingDateFilter,
        setSchedulingDateFilter,
        schedulingFromDate,
        setSchedulingFromDate,
        schedulingToDate,
        setSchedulingToDate,
        schedulingSelectedPlan,
        setSchedulingSelectedPlan,
        schedulingSelectedPopulation,
        setSchedulingSelectedPopulation,
        schedulingSelectedProvider,
        setSchedulingSelectedProvider,
        schedulingSelectedStaff,
        setSchedulingSelectedStaff,
        schedulingSelectedTeam,
        setSchedulingSelectedTeam,
        loadedSchedulingTeam,
        setLoadedSchedulingTeam,
        schedulingShowInActiveProviders,
        setSchedulingShowInActiveProviders,
        schedulingShowInActiveTeams,
        setSchedulingShowInActiveTeams,
        schedulingShowInActiveStaffs,
        setSchedulingShowInActiveStaffs,

        requestSelectedPlan,
        setRequestSelectedPlan,
        requestSelectedProvider,
        setRequestSelectedProvider,
        requestSelectedTeam,
        setRequestSelectedTeam,
        selectedRequestType,
        setSelectedRequestType,
        loadedRequestTeam,
        setLoadedRequestTeam,
        loadedEngagementTeam,
        setLoadedEngagementTeam,
        appointmentSelectedProvider,
        setAppointmentSelectedProvider,
        appointmentSelectedTeam,
        setAppointmentSelectedTeam,
        appointmentDateFilter,
        setAppointmentDateFilter,
        appointmentFromDate,
        setAppointmentFromDate,
        appointmentToDate,
        setAppointmentToDate,
        loadedAppointmentTeam,
        setLoadedAppointmentTeam,
        appointmentShowInActiveProviders,
        setAppointmentShowInActiveProviders,
        appointmentShowInActiveTeams,
        setAppointmentShowInActiveTeams,

        teamMemberSelectedPlan,
        setTeamMemberSelectedPlan,
        teamMemberSelectedPopulation,
        setTeamMemberSelectedPopulation,
        teamMemberSelectedTeam,
        setTeamMemberSelectedTeam,
        loadedTeamMemberTeam,
        setLoadedTeamMemberTeam,
        teamShowInActiveTeams,
        setTeamShowInActiveTeams,
        teamMemberFromDate,
        setTeamMemberFromDate,
        teamMemberToDate,
        setTeamMemberToDate,

        reqSelectedProvider,
        setReqSelectedProvider,
        reqDayFilter,
        setReqDayFilter,
        reqDateFilter,
        setReqDateFilter,
        reqFromDate,
        setReqFromDate,
        reqToDate,
        setReqToDate,
        loadedReqProvider,
        setLoadedReqProvider,
        requestShowInActiveProviders,
        setRequestShowInActiveProviders,

        tcTeam,
        setTcTeam,
        tcFacility,
        setTcFacility,
        tcDateFilter,
        setTcDateFilter,
        tcFromDate,
        setTcFromDate,
        tcToDate,
        setTcToDate,
        tcShowInActiveTeams,
        setTcShowInActiveTeams,

        engagementSummaryFilter,
        setEngagementSummaryFilter,
        memberWithoutEncounterFilter,
        setMemberWithoutEncounterFilter,
        undeterminedProvider,
        setUndeterminedProvider,
        undeterminedMeansOfAttempt,
        setUndeterminedMeansOfAttempt,
        undeterminedDateFilter,
        setUndeterminedDateFilter,
        undeterminedFromDate,
        setUndeterminedFromDate,
        undeterminedToDate,
        setUndeterminedToDate,
        undeterminedShowInActiveProviders,
        setUndeterminedShowInActiveProviders,
        inProgressProvider,
        setInProgressProvider,
        inProgressSelectedDay,
        setInProgressSelectedDay,
        inProgressDateFilter,
        setInProgressDateFilter,
        inProgressFromDate,
        setInProgressFromDate,
        inProgressToDate,
        setInProgressToDate,
        inProgressShowInActiveProviders,
        setInProgressShowInActiveProviders,
        closedSelectedFilter,
        setClosedSelectedFilter,
        closedDateFilter,
        setClosedDateFilter,
        closedFromDate,
        setClosedFromDate,
        closedToDate,
        setClosedToDate,

        qualityMetricsDateFilter,
        setQualityMetricsDateFilter,
        qualityMetricsFromDate,
        setQualityMetricsFromDate,
        qualityMetricsToDate,
        setQualityMetricsToDate,
        qualityMetricsTab,
        setQualityMetricsTab,
        exceptionTab,
        setExceptionTab,

        individualEngagementDateFilter,
        setIndividualEngagementDateFilter,
        individualEngagementFromDate,
        setIndividualEngagementFromDate,
        individualEngagementToDate,
        setIndividualEngagementToDate,
        individualEngagementSelectedProvider,
        setIndividualEngagementSelectedProvider,
        individualEngagementShowInActiveProviders,
        setIndividualEngagementShowInActiveProviders,
    };

    return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

export const useWidgetContext = () => React.useContext(WidgetContext);
