import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Box, Button, Modal } from '@mui/material';

import { setSelectedMemberId, setShowModal } from '../../store/appointmentScheduler.slice';
import AppointmentScheduler from './AppointmentScheduler';

const AppointmentSchedulerButton: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const { showModal } = useSelector((state: RootState) => state.appointmentScheduler);

    useEffect(() => {
        dispatch(setSelectedMemberId(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal open={showModal} onClose={() => dispatch(setShowModal(false))}>
                <Box className="large-modal-box">
                    <AppointmentScheduler />
                </Box>
            </Modal>
            <Button
                variant="contained"
                onClick={() => {
                    dispatch(setShowModal(true));
                }}
            >
                Schedule Optimizer
            </Button>
        </>
    );
};

export default AppointmentSchedulerButton;
