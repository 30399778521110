import { AdlAnswerTypeEnum } from '../Enum/AdlAnswerTypeEnum';
import { AllergyTypeEnum } from '../Enum/AllergyTypeEnum';
import { AuditAnswerEnum } from '../Enum/AuditAnswerEnum';
import { DrinkContainingAlcoholFrequencyEnum } from '../Enum/DrinkContainingAlcoholFrequencyEnum';
import { FactorRiskAssessmentLookupTypeEnum } from '../Enum/FactorRiskAssessmentLookupTypeEnum';
import { NumberOfDrinksEnum } from '../Enum/NumberOfDrinksEnum';
import { PriorityEnum } from '../Enum/PriorityEnum';
import { RequestAssigneeEnum } from '../Enum/RequestAssigneeEnum';
import { RequestSortByEnum } from '../Enum/RequestSortByEnum';
import { RequestStatusEnum } from '../Enum/RequestStatusEnum';
import { RequestTypeEnum } from '../Enum/RequestTypeEnum';
import { SexualOrientationEnum } from '../Enum/SexualOrientationEnum';
import { TeamTypeEnum } from '../Enum/TeamTypeEnum';
import { TimeIntervalTypeEnum } from '../Enum/TimeIntervalTypeEnum';
import { VitalSignParameterTypeEnum } from '../Enum/VitalSignParameterTypeEnum';
import { YesNoEnum } from '../Enum/YesNoEnum';
import { SortingMenuListByType } from '../Models/Shared/SortingMenu.model';
import { AlcoholProblemEnum } from '../Enum/AlcoholProblemEnum';
import { HealthEvaluationResponseEnum } from '../Enum/HealthEvaluationResponseEnum';
import { AnswerOptionsEnum } from '../Enum/AnswerOptionsEnum';
import { SummaryNoteTypeEnum } from '../Enum/SummaryNoteTypeEnum';

export const assessmentStatusEnum = {
    NEW: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    ARCHIVED: 4,
};

export const roleEnum = {
    NURSE_PRACTITIONER: 'Nurse Practitioner',
    HRA: 'HRA',
    CEO: 'CEO',
    ADMIN: 'Admin',
    CARE_COORDINATOR: 'Care Coordinator',
    CLINICAL_LIAISON: 'Clinical Liaison',
    MEDICAL_LIAISON: 'Medical Liaison',
    MD: 'MD',
    BEHAVIOURAL_HEALTH_SPECIALIST: 'Behavioural Health Specialist',
    FIELD_ONBOARDING: 'Field Onboarding',
    LPC: 'LPC',
    SOCIAL_WORKER: 'Social Worker',
    MEDICAL_ASSISTANT: 'Medical Assistant',
    SCHEDULING: 'Scheduling',
    MEDICAL_RECORDS_HIE: 'Medical Records/HIE',
    PRIOR_AUTH_PHARMACEUTICAL: 'Prior Auth/Pharmaceutical management/PCP Referral',
    CENTRAL_OPERATION: 'Central Operation',
    OFFICE_ONBOARDING: 'Office Onboarding',
    COMMUNITY_HEALTH_WORKER: 'Community Health Worker',
    CODER: 'Coder',
    SUPPORT_ANALYST: 'SupportAnalyst',
    TRANSITIONAL_CARE_COORDINATOR: 'Transitional Care Coordinator',
};

export const assessmentMenuEnum = {
    VITAL_SIGNS: 'Vital Signs',
    CARE_PLANS: 'Care Plans',
    PRAPARE_SDOH: 'PRAPARE SDOH',
    PAST_PRESENT_HISTORY: 'Past Present History',
    PAST_SURGICAL_HISTORY: 'Past Surgical History',
    REVIEW_SYSTEMS: 'Review Systems',
    PHYSICAL_EXAM: 'Physical Exam',
    REMOTE_MONITORING: 'Remote Monitoring',
    PHQ9: 'PHQ9',
    TOBACCO_AND_DRUG: 'SA/Drug Use-DAST',
    SEXUAL_HEALTH: 'Sexual Health',
    ADL: "ADL's",
    FALL_RISK: 'Fall Risk',
    VISION_AND_HEARING: 'Vision and Hearing',
    ADVANCED_DIRECTIVE: 'Advanced Directive',
    PREVENTATION_HEALTH_ADVICE: 'Preventative Health Advice',
    ADDITIONAL_CHP_CRITERIA: 'Additional CHP Criteria',
    HGBA1C_ASSESSMENT: 'HgbA1C Assessment',
    DIABETES_FOOT: 'Diabetes Foot',
    URINALYSIS_REPORT: 'Urinalysis Report',
    REQUESTS_REFERRALS: 'Requests/Referrals',
    TRANSITIONAL_CARE: 'Transitional Care',
    MINI_MENTAL_STATUS_EXAM: 'Mini Mental Status Exam',
    SUBJECTIVE_ASSESSMENT: 'Subjective Assessment',
    INTERVENTIONS: 'Interventions',
    OTHER_FACTORS_AND_RISKS: 'Other Factors and Risks',
    FOLLOW_UP: 'Follow Up',
    EDUCATION: 'Education',
    HRA_EDUCATION: 'HRA Education',
    MEDICATION_RECONCILIATION: 'Medication Reconciliation',
    DEMOGRAPHICS: 'Demographics',
    HEALTH_PLAN: 'Health Plan',
    CONSENT: 'Consent',
    BUDGET_FORM: 'Budget Form',
    ALLERGIES: 'Allergies',
    PCP_SPECIALIST: 'PCP, Specialists & Pharmacy',
    CARE_CIRCLE: 'Care Circle',
    TABLETS: 'Tablets',
    SCHEDULES: 'Schedules',
    PROGRESS_NOTES: 'Progress Notes',
    SUMMARY_NOTES: 'Summary Notes',
    PROCEDURE_SIGN_OFF: 'Procedure Sign Off',
    SET_APPOINTMENTS: 'Set Appointment Info',
    LIPID_ASSESSMENT: 'Lipid Assessment',
    COUNSELING: 'Counseling',
    SCREENING_FUHFUM: 'Screening FUHFUM',
};

export const assessmentList = [
    { Id: 20, Name: 'Sdoh Assessment', Icon: 'fa fa-info-circle' },
    { Id: 21, Name: 'Budget Form', Icon: 'glyphicon glyphicon-usd' },
    { Id: 15, Name: 'Behaviour Health Interview', Icon: 'fa fa-hospital-o' },
    { Id: 9, Name: 'Allergies / Reaction', Icon: 'fa fa-leaf' },
    { Id: 18, Name: 'HgbA1C Assessment', Icon: 'fa fa-history' },
    { Id: 19, Name: 'Diabetes Foot Assessment', Icon: 'fa fa-heartbeat' },
    { Id: 2, Name: 'Past Present History', Icon: 'fa fa-history' },
    { Id: 3, Name: 'Past Surgical History', Icon: 'fa fa-hospital-o' },
    { Id: 4, Name: 'Review Systems', Icon: 'fa fa-heartbeat' },
    { Id: 12, Name: 'Procedure Sign Off', Icon: 'fa fa-check-square-o' },
    { Id: 13, Name: 'PHQ9', Icon: 'fa fa-question-circle' },
    { Id: 14, Name: 'Urinalysis Report', Icon: 'fa fa-thermometer-full' },
];

export const assessmentMenusByRole = [
    {
        role: roleEnum.ADMIN,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.PAST_PRESENT_HISTORY,
            assessmentMenuEnum.PAST_SURGICAL_HISTORY,
            assessmentMenuEnum.REVIEW_SYSTEMS,
            assessmentMenuEnum.PHYSICAL_EXAM,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.DIABETES_FOOT,
            assessmentMenuEnum.URINALYSIS_REPORT,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.MINI_MENTAL_STATUS_EXAM,
            assessmentMenuEnum.SUBJECTIVE_ASSESSMENT,
            assessmentMenuEnum.INTERVENTIONS,
            assessmentMenuEnum.OTHER_FACTORS_AND_RISKS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.TOBACCO_AND_DRUG,
            assessmentMenuEnum.FOLLOW_UP,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.COUNSELING,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.CEO,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.PAST_PRESENT_HISTORY,
            assessmentMenuEnum.PAST_SURGICAL_HISTORY,
            assessmentMenuEnum.REVIEW_SYSTEMS,
            assessmentMenuEnum.PHYSICAL_EXAM,
            assessmentMenuEnum.REMOTE_MONITORING,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.DIABETES_FOOT,
            assessmentMenuEnum.URINALYSIS_REPORT,
            assessmentMenuEnum.LIPID_ASSESSMENT,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.CARE_COORDINATOR,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.URINALYSIS_REPORT,
            assessmentMenuEnum.LIPID_ASSESSMENT,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.CLINICAL_LIAISON,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.EDUCATION,
            assessmentMenuEnum.MINI_MENTAL_STATUS_EXAM,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.URINALYSIS_REPORT,
            assessmentMenuEnum.LIPID_ASSESSMENT,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.FOLLOW_UP,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.COMMUNITY_HEALTH_WORKER,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.URINALYSIS_REPORT,
            assessmentMenuEnum.LIPID_ASSESSMENT,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.FIELD_ONBOARDING,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.DEMOGRAPHICS,
            // assessmentMenuEnum.HEALTH_PLAN,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.BUDGET_FORM,
            assessmentMenuEnum.PAST_SURGICAL_HISTORY,
            assessmentMenuEnum.PAST_PRESENT_HISTORY,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.PCP_SPECIALIST,
            assessmentMenuEnum.CARE_CIRCLE,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.TABLETS,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.NURSE_PRACTITIONER,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.CARE_PLANS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.PAST_PRESENT_HISTORY,
            assessmentMenuEnum.PAST_SURGICAL_HISTORY,
            assessmentMenuEnum.REVIEW_SYSTEMS,
            assessmentMenuEnum.PHYSICAL_EXAM,
            assessmentMenuEnum.REMOTE_MONITORING,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.TOBACCO_AND_DRUG,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.DIABETES_FOOT,
            assessmentMenuEnum.URINALYSIS_REPORT,
            assessmentMenuEnum.LIPID_ASSESSMENT,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.HRA,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.DEMOGRAPHICS,
            assessmentMenuEnum.PCP_SPECIALIST,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PAST_PRESENT_HISTORY,
            assessmentMenuEnum.PAST_SURGICAL_HISTORY,
            assessmentMenuEnum.REVIEW_SYSTEMS,
            assessmentMenuEnum.PHYSICAL_EXAM,
            assessmentMenuEnum.HGBA1C_ASSESSMENT,
            assessmentMenuEnum.LIPID_ASSESSMENT,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.TOBACCO_AND_DRUG,
            assessmentMenuEnum.SEXUAL_HEALTH,
            assessmentMenuEnum.ADL,
            assessmentMenuEnum.FALL_RISK,
            assessmentMenuEnum.VISION_AND_HEARING,
            assessmentMenuEnum.ADVANCED_DIRECTIVE,
            assessmentMenuEnum.PREVENTATION_HEALTH_ADVICE,
            assessmentMenuEnum.ADDITIONAL_CHP_CRITERIA,
            assessmentMenuEnum.HRA_EDUCATION,
            assessmentMenuEnum.FOLLOW_UP,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.TRANSITIONAL_CARE_COORDINATOR,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.DEMOGRAPHICS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.PCP_SPECIALIST,
            assessmentMenuEnum.CARE_CIRCLE,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
];

export const assessmentMenusForFuhFum = [
    {
        role: roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.MINI_MENTAL_STATUS_EXAM,
            assessmentMenuEnum.SUBJECTIVE_ASSESSMENT,
            assessmentMenuEnum.INTERVENTIONS,
            assessmentMenuEnum.OTHER_FACTORS_AND_RISKS,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.TOBACCO_AND_DRUG,
            assessmentMenuEnum.FOLLOW_UP,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.COUNSELING,
            assessmentMenuEnum.SCREENING_FUHFUM,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
    {
        role: roleEnum.NURSE_PRACTITIONER,
        list: [
            assessmentMenuEnum.CONSENT,
            assessmentMenuEnum.VITAL_SIGNS,
            assessmentMenuEnum.PRAPARE_SDOH,
            assessmentMenuEnum.PAST_PRESENT_HISTORY,
            assessmentMenuEnum.PAST_SURGICAL_HISTORY,
            assessmentMenuEnum.REVIEW_SYSTEMS,
            assessmentMenuEnum.PHYSICAL_EXAM,
            assessmentMenuEnum.TRANSITIONAL_CARE,
            assessmentMenuEnum.PHQ9,
            assessmentMenuEnum.TOBACCO_AND_DRUG,
            assessmentMenuEnum.DIABETES_FOOT,
            assessmentMenuEnum.REQUESTS_REFERRALS,
            assessmentMenuEnum.ALLERGIES,
            assessmentMenuEnum.MEDICATION_RECONCILIATION,
            assessmentMenuEnum.SCREENING_FUHFUM,
            assessmentMenuEnum.PROGRESS_NOTES,
            assessmentMenuEnum.SET_APPOINTMENTS,
            assessmentMenuEnum.SUMMARY_NOTES,
            assessmentMenuEnum.PROCEDURE_SIGN_OFF,
        ],
    },
];

export const summaryNoteType = {
    VitalSign: { value: 1, name: 'Vital Sign' },
    Goal: { value: 2, name: 'Goal' },
    SDOH: { value: 3, name: 'SDOH' },
    PastPresentHistory: { value: 4, name: 'Past Present History' },
    PastSurgicalHistory: { value: 5, name: 'Past Surgical History' },
    ReviewSystems: { value: 6, name: 'Review Systems' },
    PhysicalExam: { value: 7, name: 'Physical Exam' },
    PHQ9: { value: 8, name: 'PHQ9' },
    GeneralEncounter: { value: 9, name: 'General Encounter' },
    HgbA1CAssessment: { value: 10, name: 'HgbA1C Assessment' },
    DiabetesFootAssessment: { value: 11, name: 'Diabetes Foot Assessment' },
    UrinalysisReport: { value: 12, name: 'Urinalysis Report' },
    Plan: { value: 13, name: 'Plan' },
    Barrier: { value: 14, name: 'Barrier' },
    Metric: { value: 15, name: 'Metric' },
    NextStep: { value: 16, name: 'Next Step' },
    SchedulePlan: { value: 17, name: 'Schedule Plan' },
    Request: { value: 18, name: 'Request' },
    MiniMentalExam: { value: 19, name: 'Mini Mental Exam' },
    Strength: { value: 20, name: 'Strength' },
    Intervention: { value: 21, name: 'Intervention' },
    SubjectiveAssessment: { value: 22, name: 'Subjective Assessment' },
    Medications: { value: 25, name: 'Medications' },
    ProgressNote: { value: 26, name: 'Progress Note' },
    Custom: { value: 27, name: 'Custom' },
    DemographicInfo: { value: 28, name: 'Demographic Info' },
    HealthPlan: { value: 29, name: 'Health Plan' },
    ConsentForm: { value: 30, name: 'Consent Form' },
    BudgetForm: { value: 31, name: 'Budget Form' },
    Allergy: { value: 32, name: 'Allergy' },
    PcpSpecialist: { value: 33, name: 'Pcp & Specialist' },
    CareCircle: { value: 34, name: 'Care Circle' },
    Tablet: { value: 35, name: 'Tablet' },
    Education: { value: 36, name: 'Education' },
    TransitionalCare: { value: 37, name: 'Transitional Care' },
    LipidAssessment: { value: 38, name: 'Lipid Assessment' },
};

export const summaryNoteTypeList = [
    { value: SummaryNoteTypeEnum.VitalSign, name: 'Vital Sign' },
    { value: SummaryNoteTypeEnum.Goal, name: 'Goal' },
    { value: SummaryNoteTypeEnum.SDOH, name: 'SDOH' },
    { value: SummaryNoteTypeEnum.PastPresentHistory, name: 'Past Present History' },
    { value: SummaryNoteTypeEnum.PastSurgicalHistory, name: 'Past Surgical History' },
    { value: SummaryNoteTypeEnum.ReviewSystems, name: 'Review Systems' },
    { value: SummaryNoteTypeEnum.PhysicalExam, name: 'Physical Exam' },
    { value: SummaryNoteTypeEnum.PHQ9, name: 'PHQ9' },
    { value: SummaryNoteTypeEnum.GeneralEncounter, name: 'General Encounter' },
    { value: SummaryNoteTypeEnum.HgbA1CAssessment, name: 'HgbA1C Assessment' },
    { value: SummaryNoteTypeEnum.DiabetesFootAssessment, name: 'Diabetes Foot Assessment' },
    { value: SummaryNoteTypeEnum.UrinalysisReport, name: 'Urinalysis Report' },
    { value: SummaryNoteTypeEnum.Plan, name: 'Plan' },
    { value: SummaryNoteTypeEnum.Barrier, name: 'Barrier' },
    { value: SummaryNoteTypeEnum.Metric, name: 'Metric' },
    { value: SummaryNoteTypeEnum.NextStep, name: 'Next Step' },
    { value: SummaryNoteTypeEnum.SchedulePlan, name: 'Schedule Plan' },
    { value: SummaryNoteTypeEnum.Request, name: 'Request' },
    { value: SummaryNoteTypeEnum.MiniMentalExam, name: 'Mini Mental Exam' },
    { value: SummaryNoteTypeEnum.Strength, name: 'Strength' },
    { value: SummaryNoteTypeEnum.Intervention, name: 'Intervention' },
    { value: SummaryNoteTypeEnum.SubjectiveAssessment, name: 'Subjective Assessment' },
    { value: SummaryNoteTypeEnum.Medications, name: 'Medications' },
    { value: SummaryNoteTypeEnum.ProgressNote, name: 'Progress Note' },
    { value: SummaryNoteTypeEnum.Custom, name: 'Custom' },
    { value: SummaryNoteTypeEnum.DemographicInfo, name: 'Demographic Info' },
    { value: SummaryNoteTypeEnum.HealthPlan, name: 'Health Plan' },
    { value: SummaryNoteTypeEnum.ConsentForm, name: 'Consent Form' },
    { value: SummaryNoteTypeEnum.BudgetForm, name: 'Budget Form' },
    { value: SummaryNoteTypeEnum.Allergy, name: 'Allergy' },
    { value: SummaryNoteTypeEnum.PcpSpecialist, name: 'Pcp & Specialist' },
    { value: SummaryNoteTypeEnum.CareCircle, name: 'Care Circle' },
    { value: SummaryNoteTypeEnum.Tablet, name: 'Tablet' },
    { value: SummaryNoteTypeEnum.Education, name: 'Education' },
    { value: SummaryNoteTypeEnum.TransitionalCare, name: 'Transitional Care' },
    { value: SummaryNoteTypeEnum.LipidAssessment, name: 'Lipid Assessment' },
    { value: SummaryNoteTypeEnum.TobaccoAndDrug, name: 'SA/Drug Use-DAST' },
    { value: SummaryNoteTypeEnum.SexualHealth, name: 'Sexual Health' },
    { value: SummaryNoteTypeEnum.ADL, name: "ADL's" },
    { value: SummaryNoteTypeEnum.FallRisk, name: 'Fall Risk' },
    { value: SummaryNoteTypeEnum.VisionAndHearing, name: 'Vision and Hearing' },
    { value: SummaryNoteTypeEnum.PreventativeHealthAdvice, name: 'Preventative Health Advice' },
    { value: SummaryNoteTypeEnum.AdvancedDirective, name: 'Advanced Directive' },
    { value: SummaryNoteTypeEnum.AdditionalCHPCriteria, name: 'Additional CHP Criteria' },
    { value: SummaryNoteTypeEnum.ScreeningFuhFum, name: 'Screening FUHFUM' },
];

export const phoneOptions = [{ Name: 'No Answer' }, { Name: 'Left a Voicemail' }, { Name: 'Left a Message with 3rd Party' }, { Name: 'Spoke with Member' }];

export const TelemedicineDetailsOptions = [
    { Name: 'Connected', IsSelected: false },
    { Name: 'Unable to See Member', IsSelected: false },
    { Name: 'Unable to Hear Member', IsSelected: false },
    { Name: 'Aborted Telemedicine Engagement', IsSelected: false },
];

export const AllergyTypes = [
    { Id: AllergyTypeEnum.Medication, Name: 'Medication' },
    { Id: AllergyTypeEnum.Class, Name: 'Class' },
];

export const FactorRiskAssessmentTypes = [
    { Id: FactorRiskAssessmentLookupTypeEnum.Barrier, Name: 'Barrier' },
    { Id: FactorRiskAssessmentLookupTypeEnum.Strength, Name: 'Strength' },
];

export const TestTimes = ['After Meal', 'Before Meal', 'Fasting'];
export const TestingMachines = ['A1CNow Glycated Hemoglobin A1C'];

export const IntervalTypes = [
    { Id: TimeIntervalTypeEnum.Day, Name: TimeIntervalTypeEnum.DayDescription },
    { Id: TimeIntervalTypeEnum.Week, Name: TimeIntervalTypeEnum.WeekDescription },
    { Id: TimeIntervalTypeEnum.Month, Name: TimeIntervalTypeEnum.MonthDescription },
];

export const TeamTypes = [
    { Id: TeamTypeEnum.CareCoordinator, Name: 'Care Coordinator' },
    { Id: TeamTypeEnum.NursePractitioner, Name: 'Nurse Practitioner' },
    { Id: TeamTypeEnum.BehavioralHealth, Name: 'Behavioral Health' },
    { Id: TeamTypeEnum.ClinicalLiaison, Name: 'Clinical Liaison' },
    { Id: TeamTypeEnum.CommunityHealthWorker, Name: 'Community Health Worker' },
];

export const RequestSortingMenuType = {
    Date: 'Date',
    Priority: 'Priority',
    Assignee: 'Assignee',
    Status: 'Status',
};

export const RequestSortingMenusByType = [
    {
        Type: RequestSortingMenuType.Date,
        PropName: 'sortBy',
        MenuList: [
            {
                Id: RequestSortByEnum.CreatedOnByAscend,
                Name: 'Ascending',
                Selected: true,
            },
            {
                Id: RequestSortByEnum.CreatedOnByDescend,
                Name: 'Descending',
                Selected: false,
            },
        ],
    },
    {
        Type: RequestSortingMenuType.Priority,
        PropName: 'priority',
        MenuList: [
            {
                Id: PriorityEnum.All,
                Name: 'All',
                Selected: true,
            },
            {
                Id: PriorityEnum.Low,
                Name: 'Low',
                Selected: false,
            },
            {
                Id: PriorityEnum.Medium,
                Name: 'Medium',
                Selected: false,
            },
            {
                Id: PriorityEnum.High,
                Name: 'High',
                Selected: false,
            },
        ],
    },
    {
        Type: RequestSortingMenuType.Assignee,
        PropName: 'assignee',
        MenuList: [
            {
                Id: RequestAssigneeEnum.All,
                Name: 'All',
                Selected: true,
            },
            {
                Id: RequestAssigneeEnum.MyRequests,
                Name: 'My Requests',
                Selected: false,
            },
            {
                Id: RequestAssigneeEnum.AssignedToMe,
                Name: 'Assigned To Me',
                Selected: false,
            },
            {
                Id: RequestAssigneeEnum.AssignedByMe,
                Name: 'Assigned By Me',
                Selected: false,
            },
            {
                Id: RequestAssigneeEnum.FowardByMe,
                Name: 'Foward By Me',
                Selected: false,
            },
            {
                Id: RequestAssigneeEnum.OthersRequests,
                Name: 'Others Requests',
                Selected: false,
            },
        ],
    },
    {
        Type: RequestSortingMenuType.Status,
        PropName: 'status',
        MenuList: [
            {
                Id: RequestStatusEnum.All,
                Name: 'All',
                Selected: false,
            },
            {
                Id: RequestStatusEnum.NewOrInProgres,
                Name: 'New, In-Progres',
                Selected: false,
            },
            {
                Id: RequestStatusEnum.NewOrInProgressOrCompleted,
                Name: 'New, In-Progress, Completed',
                Selected: true,
            },
            {
                Id: RequestStatusEnum.Archived,
                Name: 'Archived',
                Selected: false,
            },
        ],
    },
] as SortingMenuListByType[];

export const RequestTypes = [
    { Id: RequestTypeEnum.All, Name: 'All', AliasName: 'All' },
    { Id: RequestTypeEnum.Medications, Name: 'Medications', AliasName: 'Medications' },
    { Id: RequestTypeEnum.Transportation, Name: 'Transportation', AliasName: 'Transportation' },
    { Id: RequestTypeEnum.FollowUpAppointment, Name: 'Follow Up Appointment', AliasName: 'FollowUpAppointment' },
    { Id: RequestTypeEnum.DME, Name: 'DME', AliasName: 'DME' },
    { Id: RequestTypeEnum.Housing, Name: 'Housing', AliasName: 'Housing' },
    { Id: RequestTypeEnum.MedicalRecords, Name: 'Medical Records', AliasName: 'MedicalRecords' },
    { Id: RequestTypeEnum.FoodAssistance, Name: 'Food Assistance', AliasName: 'FoodAssistance' },
    { Id: RequestTypeEnum.Referrals, Name: 'Referrals', AliasName: 'Referrals' },
    { Id: RequestTypeEnum.RentalAssistance, Name: 'Rental Assistance', AliasName: 'RentalAssistance' },
    { Id: RequestTypeEnum.UtilityAssistance, Name: 'Utility Assistance', AliasName: 'UtilityAssistance' },
    { Id: RequestTypeEnum.ClothingOrHouseholdItems, Name: 'Clothing/ Household Items', AliasName: 'ClothingOrHouseholdItems' },
    { Id: RequestTypeEnum.Dental, Name: 'Dental', AliasName: 'Dental' },
    { Id: RequestTypeEnum.TeleMonitoringEquipment, Name: 'Tele-Monitoring Equipment', AliasName: 'TeleMonitoringEquipment' },
    { Id: RequestTypeEnum.Others, Name: 'Others', AliasName: 'Others' },
    { Id: RequestTypeEnum.PHQ9, Name: 'PHQ9', AliasName: 'PHQ9' },
    { Id: RequestTypeEnum.SDOH, Name: 'SDOH', AliasName: 'SDOH' },
    { Id: RequestTypeEnum.HraFollowUp, Name: 'HRA Follow Up', AliasName: 'HraFollowUp' },
    { Id: RequestTypeEnum.PreventativeHealthAdvice, Name: 'Preventative Health Advice', AliasName: 'PreventativeHealthAdvice' },
    { Id: RequestTypeEnum.FuhFumFollowUp, Name: 'FUHFUM Follow Up', AliasName: 'FuhFumFollowUp' },
    { Id: RequestTypeEnum.DemographicResearch, Name: 'Demographic Research', AliasName: 'DemographicResearch' },
    { Id: RequestTypeEnum.Hedis, Name: 'Hedis', AliasName: 'Hedis' },
    { Id: RequestTypeEnum.HRAReferralToCHPFullProgram, Name: 'HRA-Referral to CHP Full Program', AliasName: 'HRAReferralToCHPFullProgram' },
    { Id: RequestTypeEnum.HRASchedulePCPVisit, Name: 'HRA-Schedule PCP Visit', AliasName: 'HRASchedulePCPVisit' },
    { Id: RequestTypeEnum.HRAScheduleBHFollowUp, Name: 'HRA-Schedule BH Follow-up', AliasName: 'HRAScheduleBHFollowUp' },
];

export const MedicationStatusEnum = {
    Active: 'Active',
    AllInactive: 'All Inactive',
    Completed: 'Completed',
    DC: 'Discontinued',
    EIE: 'Entered In Error',
    Merged: 'Merged',
};

export const MedicationStatuses = [
    { Id: MedicationStatusEnum.Active, Name: MedicationStatusEnum.Active },
    { Id: MedicationStatusEnum.AllInactive, Name: MedicationStatusEnum.AllInactive },
    { Id: MedicationStatusEnum.Completed, Name: MedicationStatusEnum.Completed },
    { Id: MedicationStatusEnum.DC, Name: MedicationStatusEnum.DC },
    { Id: MedicationStatusEnum.EIE, Name: MedicationStatusEnum.EIE },
    { Id: MedicationStatusEnum.Merged, Name: MedicationStatusEnum.Merged },
];

export const MedicationActionEnum = {
    AssignPeriodsDue: 'Assign Periods Due',
    Discontinue: 'Discontinue',
    DownloadPDF: 'Download PDF',
    EPrescribe: 'e-Prescribe',
    MarkReconciled: 'Mark Reconciled',
    MergeSelectedMeds: 'Merge Selected Meds',
    NewMedication: 'New Medication',
};

export const MedicationActions = [
    { Id: MedicationActionEnum.AssignPeriodsDue, Name: MedicationActionEnum.AssignPeriodsDue },
    { Id: MedicationActionEnum.Discontinue, Name: MedicationActionEnum.Discontinue },
    { Id: MedicationActionEnum.DownloadPDF, Name: MedicationActionEnum.DownloadPDF },
    { Id: MedicationActionEnum.EPrescribe, Name: MedicationActionEnum.EPrescribe },
    { Id: MedicationActionEnum.MarkReconciled, Name: MedicationActionEnum.MarkReconciled },
    { Id: MedicationActionEnum.MergeSelectedMeds, Name: MedicationActionEnum.MergeSelectedMeds },
    { Id: MedicationActionEnum.NewMedication, Name: MedicationActionEnum.NewMedication },
];

export const MedicationTypeEnum = {
    Medication: 'Medication',
    SlidingScale: 'Sliding Scale',
    TaperedMedication: 'Tapered Medication',
};

export const MedicationTypes = [
    { Id: MedicationTypeEnum.Medication, Name: MedicationTypeEnum.Medication },
    { Id: MedicationTypeEnum.SlidingScale, Name: MedicationTypeEnum.SlidingScale },
    { Id: MedicationTypeEnum.TaperedMedication, Name: MedicationTypeEnum.TaperedMedication },
];

export const DiscontinueReasonEnum = {
    Allergy: 'Allergy',
    ChangedToAnotherMedication: 'Changed to another Medication',
    DosageFrequencyChanged: 'Dosage/frequency changed',
    NoLongerNeeded: 'No longer needed',
    RefusesDeclines: 'Refuses/declines',
    Pregnancy: 'Pregnancy',
};

export const DiscontinueReasons = [
    { Id: DiscontinueReasonEnum.Allergy, Name: DiscontinueReasonEnum.Allergy },
    { Id: DiscontinueReasonEnum.ChangedToAnotherMedication, Name: DiscontinueReasonEnum.ChangedToAnotherMedication },
    { Id: DiscontinueReasonEnum.DosageFrequencyChanged, Name: DiscontinueReasonEnum.DosageFrequencyChanged },
    { Id: DiscontinueReasonEnum.NoLongerNeeded, Name: DiscontinueReasonEnum.NoLongerNeeded },
    { Id: DiscontinueReasonEnum.RefusesDeclines, Name: DiscontinueReasonEnum.RefusesDeclines },
    { Id: DiscontinueReasonEnum.Pregnancy, Name: DiscontinueReasonEnum.Pregnancy },
];

export const VitalSignParameterTypes = [
    { Id: VitalSignParameterTypeEnum.SystolicBp, Name: 'Systolic BP', Min: 0, Max: 200 },
    { Id: VitalSignParameterTypeEnum.DiastolicBP, Name: 'Diastolic BP', Min: 40, Max: 140 },
    { Id: VitalSignParameterTypeEnum.HeartRate, Name: 'Heart Rate', Min: 10, Max: 200 },
    { Id: VitalSignParameterTypeEnum.Oximeter, Name: 'Oximeter', Min: 0, Max: 100 },
    { Id: VitalSignParameterTypeEnum.Scale, Name: 'Scale', Min: 0, Max: 10 },
    { Id: VitalSignParameterTypeEnum.Glucose, Name: 'Glucose', Min: 50, Max: 350 },
];

export const AdlAnswerTypes = [
    { Id: AdlAnswerTypeEnum.Yes, Name: 'Yes, consistently needs help' },
    { Id: AdlAnswerTypeEnum.Occasionally, Name: 'Occasionally needs help' },
    { Id: AdlAnswerTypeEnum.No, Name: 'No help needed' },
];

export const YesNoAnswers = [
    { Id: 'true', Name: YesNoEnum.Yes, Value: AnswerOptionsEnum.Yes },
    { Id: 'false', Name: YesNoEnum.No, Value: AnswerOptionsEnum.No },
];

export const SexualOrientations = [
    { Id: SexualOrientationEnum.Men, Name: 'Men' },
    { Id: SexualOrientationEnum.Women, Name: 'Women' },
    { Id: SexualOrientationEnum.Both, Name: 'Both' },
];

export const NumberOfDrinks = [
    { Id: NumberOfDrinksEnum.ZeroToTwo, Name: NumberOfDrinksEnum.ZeroToTwoDescription },
    { Id: NumberOfDrinksEnum.ThreeToFour, Name: NumberOfDrinksEnum.ThreeToFourDescription },
    { Id: NumberOfDrinksEnum.FiveToSix, Name: NumberOfDrinksEnum.FiveToSixDescription },
    { Id: NumberOfDrinksEnum.SevenToNine, Name: NumberOfDrinksEnum.SevenToNineDescription },
    { Id: NumberOfDrinksEnum.TenOrMore, Name: NumberOfDrinksEnum.TenOrMoreDescription },
];

export const DrinkContainingAlcoholFrequencies = [
    { Id: DrinkContainingAlcoholFrequencyEnum.Never, Name: DrinkContainingAlcoholFrequencyEnum.NeverDescription },
    { Id: DrinkContainingAlcoholFrequencyEnum.MonthlyOrLess, Name: DrinkContainingAlcoholFrequencyEnum.MonthlyOrLessDescription },
    { Id: DrinkContainingAlcoholFrequencyEnum.TwoOrFourXMonth, Name: DrinkContainingAlcoholFrequencyEnum.TwoOrFourXMonthDescription },
    { Id: DrinkContainingAlcoholFrequencyEnum.TwoOrThreeXWeek, Name: DrinkContainingAlcoholFrequencyEnum.TwoOrThreeXWeekDescription },
    { Id: DrinkContainingAlcoholFrequencyEnum.FourPlusTimesWeek, Name: DrinkContainingAlcoholFrequencyEnum.FourPlusTimesWeekDescription },
];

export const AuditAnswers = [
    { Id: AuditAnswerEnum.Never, Name: AuditAnswerEnum.NeverDescription },
    { Id: AuditAnswerEnum.LessThanMonthly, Name: AuditAnswerEnum.LessThanMonthlyDescription },
    { Id: AuditAnswerEnum.Monthly, Name: AuditAnswerEnum.MonthlyDescription },
    { Id: AuditAnswerEnum.Weekly, Name: AuditAnswerEnum.WeeklyDescription },
    { Id: AuditAnswerEnum.DailyOrAlmostDaily, Name: AuditAnswerEnum.DailyOrAlmostDailyDescription },
];

export const AlcoholProblems = [
    { Id: AlcoholProblemEnum.Never, Name: AlcoholProblemEnum.NeverDescription },
    { Id: AlcoholProblemEnum.WithinThePastYear, Name: AlcoholProblemEnum.WithinThePastYearDescription },
    { Id: AlcoholProblemEnum.OverYearPlusAgo, Name: AlcoholProblemEnum.OverYearPlusAgoDescription },
];

export const HealthEvaluationResponses = [
    { Id: HealthEvaluationResponseEnum.Worse, Name: HealthEvaluationResponseEnum.WorseDescription },
    { Id: HealthEvaluationResponseEnum.SomewhatWorse, Name: HealthEvaluationResponseEnum.SomewhatWorseDescription },
    { Id: HealthEvaluationResponseEnum.Same, Name: HealthEvaluationResponseEnum.SameDescription },
    { Id: HealthEvaluationResponseEnum.SlightyBetter, Name: HealthEvaluationResponseEnum.SlightyBetterDescription },
    { Id: HealthEvaluationResponseEnum.Better, Name: HealthEvaluationResponseEnum.BetterDescription },
];

export const PeriodsHours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const PeriodsMinutes = ['00', '15', '30', '45'];
export const TimeMeridiems = ['AM', 'PM'];
