import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { parentUrl } from '../../../../utils/constants';
import { localDateAndTimeFormat, localDateFormat } from '../../../../utils/timeFormat';
import { apiString as API_URL } from '../../../../utils/constants';
import phoneFormat from '../../../../utils/phoneFormat';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { isDefaultDate } from '../../../../utils/common';

const MembersNotScheduledWithBHorNP = (props) => {
    const timePeriods = [
        { label: 'Next 30 Days', value: '1' },
        { label: 'Next 60 Days', value: '2' },
        { label: 'Next 90 Days', value: '3' },
    ];
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [filterBy, setFilterBy] = useState('1');
    const [membersNotScheduled, setMembersNotScheduled] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/scheduling`, '_blank');
    };

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/scheduling/getmembersnotscheduledinnextdayswithbhornp?timeFilter=${filterBy}&teamId=${selectedTeam}&includeInActiveTeams=${showInActiveTeams}`
                );
                setMembersNotScheduled(response.data);

                setIsLoading(false);
            } catch (e) {
                setMembersNotScheduled([]);
                setIsLoading(false);
                console.log(e);
            }
        };
        changeFilter();
    }, [filterBy, selectedTeam, showInActiveTeams]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                            Members Not Scheduled With BH or NP
                        </Typography>
                        <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                            &#40;{membersNotScheduled.length}&#41;
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Active members who doesn't have a appointment scheduled with NP or BH within selected days
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {props.showProviders && (
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={selectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setSelectedTeam(e.target.value)}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <ToggleButtonGroup
                                value={filterBy}
                                exclusive
                                size="small"
                                onChange={(event: React.MouseEvent<HTMLElement>, newPeriod: string | null) => {
                                    setFilterBy(newPeriod);
                                }}
                            >
                                {timePeriods.map((period) => {
                                    return (
                                        <ToggleButton color="primary" key={period.value} value={period.value}>
                                            {period.label}
                                        </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2} height="52vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                    {isLoading && <WidgetLoading />}
                    {membersNotScheduled.length > 0 ? (
                        membersNotScheduled.map((member) => {
                            return (
                                <Grid key={member.Id} item xs={6}>
                                    <Card
                                        sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                                boxShadow: 10,
                                                border: 0,
                                            },
                                            boxShadow: 0,
                                        }}
                                        onClick={() => openDocumentHandler(member.Id)}
                                        variant="outlined"
                                    >
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-evenly' }}>
                                            <Grid position={'relative'} container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Member
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {`${member.FirstName} ${member.LastName}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Phone Number
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {phoneFormat(member.MobilePhone)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Health Plan
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {member.HealthPlan}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Last NP Visit
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {isDefaultDate(member.LastNPVisit) ? '-' : localDateAndTimeFormat(new Date(member.LastNPVisit))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Last CHP Visit
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {isDefaultDate(member.LastCHPVisit) ? '-' : localDateFormat(new Date(member.LastCHPVisit))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Last Telemedicine Visit
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {isDefaultDate(member.LastTelemedicineVisit)
                                                            ? '-'
                                                            : localDateFormat(new Date(member.LastTelemedicineVisit))}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6">No Matching Records</Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default MembersNotScheduledWithBHorNP;
