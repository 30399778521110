import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { OnboardingZipCodeGroup } from '../Models/OfficeOnboarding/OnboardingZipCodeGroup';
import axios from 'axios';
import { apiString as API_URL } from '../utils/constants';
import Population from '../Models/Population/Population.model';
import OnboardingReportRow from '../Models/AdminOnboarding/OnboardingReportRow.model';
import b64toBlob from 'b64-to-blob';
import CallItem from '../Models/OnboardingWorkflow/CallItem.model';

const adminOnboardingSlice = createSlice({
    name: 'adminOnboardingSlice',
    initialState: {
        callItem: new CallItem(),
        callItemDialogOpen: false,
        error: false,
        fetchingReports: false,
        isExporting: false,
        isTableFetching: false,
        openSelectedGroup: false,
        populations: [],
        populationIds: [],
        reportRows: [],
        selectedGroup: new OnboardingZipCodeGroup(),
        selectedPopulation: new Population(),
        showConfirmDelete: false,
        showEditPopulation: false,
        showInactivePopulations: false,
        zipCodeGroups: [],
    },
    reducers: {
        _setCallItem(state, action) {
            state.callItem = action.payload;
        },
        _setCallItemDialogOpen(state, action) {
            state.callItemDialogOpen = action.payload;
        },
        _setError(state, action) {
            state.error = action.payload;
        },
        _setFetchingReports(state, action) {
            state.fetchingReports = action.payload;
        },
        _setIsExporting(state, action) {
            state.isExporting = action.payload;
        },
        _setIsTableFetching(state, action) {
            state.isTableFetching = action.payload;
        },
        _setOpenSelectionGroup(state, action) {
            state.openSelectedGroup = action.payload;
        },
        _setPopulations(state, action) {
            state.populations = action.payload;
        },
        _setPopulationIds(state, action) {
            state.populationIds = action.payload;
        },
        _setReportRows(state, action) {
            state.reportRows = action.payload;
        },
        _setSelectedGroup(state, action) {
            state.selectedGroup = action.payload;
        },
        _setSelectedPopulation(state, action) {
            state.selectedPopulation = action.payload;
        },
        _setShowConfirmDelete(state, action) {
            state.showConfirmDelete = action.payload;
        },
        _setShowEditPopulation(state, action) {
            state.showEditPopulation = action.payload;
        },
        _setShowInactivePopulations(state, action) {
            state.showInactivePopulations = action.payload;
        },
        _setZipCodeGroups(state, action) {
            state.zipCodeGroups = action.payload;
        },
    },
});

const {
    _setCallItem,
    _setCallItemDialogOpen,
    _setError,
    _setFetchingReports,
    _setIsExporting,
    _setIsTableFetching,
    _setOpenSelectionGroup,
    _setPopulations,
    _setPopulationIds,
    _setReportRows,
    _setSelectedGroup,
    _setSelectedPopulation,
    _setShowConfirmDelete,
    _setShowEditPopulation,
    _setShowInactivePopulations,
    _setZipCodeGroups,
} = adminOnboardingSlice.actions;

export const setCallItemDialogOpen =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setCallItemDialogOpen(value));
    };

export const setError =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setError(value));
    };

export const setFetchingReports =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setFetchingReports(value));
    };

export const setIsExporting =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsExporting(value));
    };

export const setIsTableFetching =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsTableFetching(value));
    };

export const setOpenSelectedGroup =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenSelectionGroup(value));
    };

export const setPopulations =
    (value: Population[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setPopulations(value));
    };

export const setPopulationIds =
    (populationIds: string[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setPopulationIds(populationIds));
    };

export const setReportRows =
    (value: OnboardingReportRow[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setReportRows(value));
    };

export const setSelectedGroup =
    (value: OnboardingZipCodeGroup): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedGroup(value));
    };

export const setSelectedPopulation =
    (value: Population): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedPopulation(value));
    };

export const setShowConfirmDelete =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowConfirmDelete(value));
    };

export const setShowEditPopulation =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowEditPopulation(value));
    };

export const setShowInactivePopulations =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowInactivePopulations(value));
    };

export const setZipCodeGroups =
    (value: OnboardingZipCodeGroup[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setZipCodeGroups(value));
    };

export const fetchPopulations = (): AppThunk => async (dispatch, getState) => {
    dispatch(setFetchingReports(true));
    const response = await axios.get(`${API_URL}/population/GetPopulations`);
    dispatch(setPopulations(response.data));
    dispatch(setFetchingReports(false));
};

export const fetchZipCodeGroups = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/onboarding/getonboardingzipcodegroups`);
    dispatch(setZipCodeGroups(response.data));
};

export const insertOrUpdateZipCodeGroup =
    (group: OnboardingZipCodeGroup): AppThunk =>
    async (dispatch, getState) => {
        await axios.post(`${API_URL}/onboarding/insertorupdatezipcodegroup`, group);
        dispatch(fetchZipCodeGroups());
    };

export const deleteZipCodeGroup =
    (groupId: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.delete(`${API_URL}/onboarding/deletezipcodegroup?groupId=${groupId}`);
        dispatch(setShowConfirmDelete(false));
        dispatch(fetchZipCodeGroups());
    };

export const updatePopulations =
    (populations: Population[]): AppThunk =>
    async (dispatch, getState) => {
        try {
            await axios.post(`${API_URL}/population/updatepopulations`, populations);
        } catch (error) {
            console.log(error);
        }
    };

export const fetchOnboardingReportTable =
    (populationIds: string[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsTableFetching(true));
        const response = await axios.post(`${API_URL}/onboarding/GetOnboardingReportTable`, populationIds);
        dispatch(setReportRows(response.data));
        dispatch(setIsTableFetching(false));
    };

export const fetchOnboardingExcelReport =
    (populationIds: string[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsExporting(true));
        const response = await axios.post(`${API_URL}/onboarding/GetOnboardingReport`, populationIds);
        if (response.status === 200) {
            const data = await response.data;
            const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const today = new Date();
            const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
            const fileName =
                (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
                '.' +
                today.getDate() +
                '.' +
                today.getFullYear() +
                '-' +
                randomNumber;
            document.body.appendChild(a);
            a.href = blobUrl;
            a.download = fileName + '.xlsx';
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            dispatch(setIsExporting(false));
        }
    };

export const getCallerHistoryCallItem =
    (callItemId: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/onboarding/GetCallerHistoryCallItem?callItemId=${callItemId}`);
        dispatch(_setCallItem(response.data));
        dispatch(setCallItemDialogOpen(true));
    };

export default adminOnboardingSlice.reducer;
