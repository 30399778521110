import { useEffect } from 'react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Link,
    Button,
    Grid,
    TextField,
    IconButton,
    Box,
    CircularProgress,
    Typography,
    Collapse,
    Stack,
    Select,
    MenuItem,
    Chip,
    Card,
    Switch,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import { ConsentForm } from '../../../Models/Consent/ConsentForm.model';
import { getConsentFormDocumentByMember, getMemberConsentForm, saveMemberConsentForm } from '../../../store/assessment.slice';
import { getMemberBasicInfo } from '../../../store/memberDetail.slice';
import { RootState } from '../../../reducers';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { roleEnum } from '../../../utils/assessments';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { prepareTextFieldValue } from '../../../utils/common';
import { ConsentDocumentTypeEnum } from '../../../Enum/ConsentDocumentTypeEnum';
import { copyObject } from '../../../utils/common';

const classes = {
    form: {
        '& .MuiFormControl-root': {
            marginBottom: '15px',
        },
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    rowFormControl: {
        minHeight: '40px',
        marginBottom: '20px',
        paddingTop: '10px',

        '& .MuiFormLabel-root': {
            marginBottom: 0,
        },
    },
    card: {
        p: 2,
        width: { xs: '100%', sm: 'auto' },
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
        flex: 1,
    },
    activeCard: {
        p: 2,
        width: { xs: '100%', sm: 'auto' },
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
        flex: 1,
        border: '1px solid #66B2FF !important',
        backgroundColor: 'rgba(240, 247, 255, 0.1) !important',
        boxShadow: '2px 2px 6px #99CCF3, inset 0px 2px 4px rgba(194, 224, 255, 0.5) !important',
    },
};

const concentFormSchema = Yup.object().shape({
    MemberId: Yup.string(),
    ConsentToService: Yup.boolean(),
    NewPartOneConsent: Yup.object().shape({
        Id: Yup.string(),
        File: Yup.mixed(),
        DocumentSignedOn: Yup.string().when('File', {
            is: (File: any) => File != null,
            then: Yup.string().required('SignedOn is required'),
        }),
        Name: Yup.string().when('File', {
            is: (File: any) => File != null,
            then: Yup.string().required('Name is required'),
        }),
        FileName: Yup.string(),
        DocumentCategory: Yup.string(),
    }),
    NewPartTwoConsent: Yup.object().shape({
        Id: Yup.string(),
        File: Yup.mixed(),
        DocumentSignedOn: Yup.string().when('File', {
            is: (File: any) => File != null,
            then: Yup.string().required('SignedOn is required'),
        }),
        Name: Yup.string().when('File', {
            is: (File: any) => File != null,
            then: Yup.string().required('SignedOn is required'),
        }),
        FileName: Yup.string(),
        DocumentCategory: Yup.string(),
    }),
    NewHipaaConsent: Yup.object()
        .nullable()
        .shape({
            Id: Yup.string(),
            File: Yup.mixed(),
            DocumentSignedOn: Yup.string().when('File', {
                is: (File: any) => File != null,
                then: Yup.string().required('SignedOn is required'),
            }),
            Name: Yup.string().when('File', {
                is: (File: any) => File != null,
                then: Yup.string().required('SignedOn is required'),
            }),
            FileName: Yup.string(),
            DocumentCategory: Yup.string(),
        }),
});

const Consent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { consentForm, consentDocumentValid, fetchingConsentForm, selectedRole, isSaving } = useSelector((state: RootState) => state.assessment);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [dateOfOnboard, setDateOfOnboard] = useState<any>(null);
    const [showMemberStatusAlert, setShowMemberStatusAlert] = useState<boolean>(false);
    const [changedMemberStatus, setChangedMemberStatus] = useState(null);
    const [showNoServiceAlert, setShowNoServiceAlert] = useState<boolean>(false);
    const [partOneDocument, setPartOneDocument] = useState(null);
    const [partTwoDocument, setPartTwoDocument] = useState(null);
    const [isNewPartOne, setIsNewPartOne] = useState<boolean>(false);
    const [isNewPartTwo, setIsNewPartTwo] = useState<boolean>(false);
    const [isShow, setIsShow] = useState<boolean>(false);

    const dispatch = useDispatch();
    const [progressNotes, setProgressNotes] = useState<string>(engagementDraft.ProgressNotes || '');

    const isInHra = selectedRole === roleEnum.HRA;
    const isInFuhFum = memberBasicInfo.IsInFuhFum;
    const [tabIndex, setTabIndex] = useState<number>(1);

    const [initialInfo, setInitialInfo] = useState<ConsentForm>({
        ...new ConsentForm(),
        Id: engagementDraft?.ConsentFormId || '',
    });

    const memberStatuses = [
        {
            Id: isInFuhFum ? MemberStatusEnum.FUHUnd : MemberStatusEnum.Undetermined,
            Name: isInFuhFum ? MemberStatusEnum.FUHUndDescription : MemberStatusEnum.UndeterminedDescription,
        },
        {
            Id: MemberStatusEnum.Deceased,
            Name: MemberStatusEnum.DeceasedDescription,
        },
        {
            Id: MemberStatusEnum.RefusedToEnroll,
            Name: MemberStatusEnum.RefusedToEnrollDescription,
        },
    ];

    useEffect(() => {
        dispatch(getMemberBasicInfo(memberId));
    }, [dispatch, memberId]);

    useEffect(() => {
        dispatch(getConsentFormDocumentByMember(memberId));
        if (engagementDraft?.ConsentFormId) {
            dispatch(getMemberConsentForm(engagementDraft.ConsentFormId));
        }
    }, [dispatch, memberId, engagementDraft]);

    useEffect(() => {
        let par1Document = consentDocumentValid?.find((d) => d.ConsentDocumentType === ConsentDocumentTypeEnum.PartOne);
        let part2Document = consentDocumentValid?.find((d) => d.ConsentDocumentType === ConsentDocumentTypeEnum.PartTwo);

        setPartOneDocument(par1Document);
        setPartTwoDocument(part2Document);

        if (!Boolean(engagementDraft?.ConsentFormId)) {
            let newConsent = new ConsentForm();
            newConsent.PartOneConsent = par1Document?.ConsentFormDocument;
            newConsent.PartTwoConsent = part2Document?.ConsentFormDocument;
            setInitialInfo(newConsent);
            setIsShow(true);
        } else if (consentForm && consentForm.Id) {
            let newConsent = copyObject(consentForm);
            if ((!Boolean(consentForm.PartOneConsent) || !Boolean(consentForm.PartOneConsent.Id)) && Boolean(par1Document)) {
                newConsent.PartOneConsent = par1Document?.ConsentFormDocument;
            }
            if ((!Boolean(consentForm.PartTwoConsent) || !Boolean(consentForm.PartTwoConsent.Id)) && Boolean(part2Document)) {
                newConsent.PartTwoConsent = part2Document?.ConsentFormDocument;
            }
            setInitialInfo(newConsent);
            setIsShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentDocumentValid]);

    useEffect(() => {
        if (consentForm && consentForm.Id) {
            setInitialInfo(consentForm);
        }
    }, [consentForm]);

    const handleSubmit = (values: ConsentForm) => {
        if (values.ConsentToService) {
            setShowSaveAlert(true);
        } else {
            setShowNoServiceAlert(true);
        }
    };

    const handleOnFileSelected = (target: HTMLInputElement, setFieldValue: Function, parentName: string, validateForm: Function) => {
        const file = (target as HTMLInputElement).files[0];
        setFieldValue(`${parentName}.File`, file);
        setFieldValue(`${parentName}.FileName`, file.name);
        setFieldValue(`${parentName}.Name`, file.name.split('.').slice(0, -1).join('.'));
        setTimeout(() => {
            validateForm();
        }, 0);
    };

    const handleOnConfirmed = (values: ConsentForm, isConfirmMemberStatus = false, isSkip = false) => {
        if (
            (memberBasicInfo.Status === MemberStatusEnum.UndeterminedDescription ||
                memberBasicInfo.Status === MemberStatusEnum.ScheduledDescription ||
                memberBasicInfo.Status === MemberStatusEnum.FUHUndDescription) &&
            !isConfirmMemberStatus &&
            !isSkip &&
            values.ConsentToService
        ) {
            setShowSaveAlert(false);
            setShowMemberStatusAlert(true);
            setDateOfOnboard(new Date());
        } else if (isConfirmMemberStatus && moment(dateOfOnboard).isValid()) {
            dispatch(
                saveMemberConsentForm(
                    {
                        ...values,
                        MemberId: memberId,
                        PartOneConsent: values.NewPartOneConsent?.File ? values.NewPartOneConsent : values.PartOneConsent,
                        PartTwoConsent: values.NewPartTwoConsent?.File ? values.NewPartTwoConsent : values.PartTwoConsent,
                    },
                    {
                        DateOfOnboard: isInHra || isInFuhFum ? '' : moment(dateOfOnboard).format('MM/DD/YYYY'),
                    }
                )
            );
        } else if (isConfirmMemberStatus) {
            dispatch(
                saveMemberConsentForm(
                    {
                        ...values,
                        MemberId: memberId,
                        PartOneConsent: values.NewPartOneConsent?.File ? values.NewPartOneConsent : values.PartOneConsent,
                        PartTwoConsent: values.NewPartTwoConsent?.File ? values.NewPartTwoConsent : values.PartTwoConsent,
                    },
                    {
                        MemberStatus: changedMemberStatus === null ? memberStatuses.find((m) => m.Name === memberBasicInfo.Status)?.Id : changedMemberStatus,
                        ProgressNotes: progressNotes,
                    }
                )
            );
        } else {
            dispatch(
                saveMemberConsentForm({
                    ...values,
                    MemberId: memberId,
                    PartOneConsent: values.NewPartOneConsent?.File ? values.NewPartOneConsent : values.PartOneConsent,
                    PartTwoConsent: values.NewPartTwoConsent?.File ? values.NewPartTwoConsent : values.PartTwoConsent,
                })
            );
        }
    };

    const onToggleChange = (type: ConsentDocumentTypeEnum, isChecked: boolean) => {
        switch (type) {
            case 1:
                if (isChecked) {
                    setIsNewPartOne(true);
                } else {
                    setIsNewPartOne(false);
                }
                break;
            case 2:
                if (isChecked) {
                    setIsNewPartTwo(true);
                } else {
                    setIsNewPartTwo(false);
                }
                break;
        }
    };

    return (
        <Formik initialValues={initialInfo} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={concentFormSchema}>
            {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<ConsentForm>) => (
                <Form>
                    <Grid container sx={classes.form} direction="column" spacing={2} className="content-panel">
                        <Grid item lg={12} md={12} sm={12}>
                            <Typography variant="h6" component="label">
                                {isInHra ? 'Health Risk Assessment Consent' : 'Consent Form'}
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item className="content-form consent-form">
                            {fetchingConsentForm ? (
                                <Box display="flex" justifyContent="center" className="loader-container">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    <FormControl fullWidth={true} className="d-row-center">
                                        <FormLabel id="service-buttons-group-label">Did member consent to service?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="service-buttons-group-label"
                                            name="service-buttons-group"
                                            onChange={(event) => {
                                                setFieldValue('ConsentToService', event.currentTarget.value === 'true');
                                                setIsShow(event.currentTarget.value === 'true' ? true : false);
                                            }}
                                            value={String(values.ConsentToService)}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    {values.ConsentToService}
                                    <Collapse in={values.ConsentToService === false} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                                        <Typography variant="subtitle1" component="label" color="red" sx={{ marginRight: 4 }}>
                                            *Must get a consent signed prior to providing services today.
                                        </Typography>
                                    </Collapse>
                                    <Collapse in={isShow && values.ConsentToService !== false} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                                        <Grid container direction="row" spacing={2} className="content-panel" sx={{ marginBottom: 5 }}>
                                            <Grid item lg={6} md={6} sm={6} display="flex">
                                                <Card variant="outlined" onClick={() => setTabIndex(1)} sx={tabIndex === 1 ? classes.activeCard : classes.card}>
                                                    <Stack direction="column" spacing={2} alignItems="center" style={{ width: '100%' }}>
                                                        <Stack direction="column" spacing={0.2} alignItems="center" style={{ width: '100%' }}>
                                                            <Typography
                                                                color={partOneDocument?.IsExpired ? 'red' : 'text.primary'}
                                                                fontWeight="medium"
                                                                fontSize={15}
                                                            >
                                                                Part One Consent
                                                                {partOneDocument?.IsExpired &&
                                                                Boolean(partOneDocument?.ConsentFormDocument?.DocumentSignedOn) &&
                                                                moment(partOneDocument?.ConsentFormDocument?.DocumentSignedOn).isValid() ? (
                                                                    <Chip label="Expired" size="small" color="error" style={{ marginLeft: '5px' }} />
                                                                ) : null}
                                                            </Typography>
                                                            <React.Fragment>
                                                                <Typography component="div" variant="caption" color="text.secondary" fontWeight="regular">
                                                                    <div>
                                                                        <span>
                                                                            {Boolean(partOneDocument?.ConsentFormDocument?.DocumentSignedOn) &&
                                                                            moment(partOneDocument?.ConsentFormDocument?.DocumentSignedOn).isValid()
                                                                                ? `Document Signed On: ${moment(
                                                                                      partOneDocument?.ConsentFormDocument?.DocumentSignedOn
                                                                                  ).format('MM/DD/YYYY')}`
                                                                                : 'Not Uploaded Yet'}
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                                {Boolean(partOneDocument?.ConsentFormDocument?.AzureDocumentUri) ? (
                                                                    <Link
                                                                        href={partOneDocument?.ConsentFormDocument?.AzureDocumentUri}
                                                                        underline="hover"
                                                                        target="_blank"
                                                                        download={true}
                                                                    >
                                                                        <IconButton aria-label="pdf">
                                                                            <SimCardDownloadIcon color="primary" fontSize="large" />
                                                                        </IconButton>
                                                                    </Link>
                                                                ) : null}
                                                            </React.Fragment>
                                                        </Stack>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} display="flex">
                                                <Card variant="outlined" onClick={() => setTabIndex(2)} sx={tabIndex === 2 ? classes.activeCard : classes.card}>
                                                    <Stack direction="column" spacing={2} alignItems="center" style={{ width: '100%' }}>
                                                        <Stack direction="column" spacing={0.2} alignItems="center" style={{ width: '100%' }}>
                                                            <Typography
                                                                color={partTwoDocument?.IsExpired ? 'red' : 'text.primary'}
                                                                fontWeight="medium"
                                                                fontSize={15}
                                                            >
                                                                Part Two Consent
                                                                {partTwoDocument?.IsExpired &&
                                                                Boolean(partTwoDocument?.ConsentFormDocument?.DocumentSignedOn) &&
                                                                moment(partTwoDocument?.ConsentFormDocument?.DocumentSignedOn).isValid() ? (
                                                                    <Chip label="Expired" size="small" color="error" style={{ marginLeft: '5px' }} />
                                                                ) : null}
                                                            </Typography>
                                                            <React.Fragment>
                                                                <Typography component="div" variant="caption" color="text.secondary" fontWeight="regular">
                                                                    <div>
                                                                        <span>
                                                                            {Boolean(partTwoDocument?.ConsentFormDocument?.DocumentSignedOn) &&
                                                                            moment(partTwoDocument?.ConsentFormDocument?.DocumentSignedOn).isValid()
                                                                                ? `Document Signed On: ${moment(
                                                                                      partTwoDocument?.ConsentFormDocument?.DocumentSignedOn
                                                                                  ).format('MM/DD/YYYY')}`
                                                                                : 'Not Uploaded Yet'}
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                                {Boolean(partTwoDocument?.ConsentFormDocument?.AzureDocumentUri) ? (
                                                                    <Link
                                                                        href={partTwoDocument?.ConsentFormDocument?.AzureDocumentUri}
                                                                        underline="hover"
                                                                        target="_blank"
                                                                        download={true}
                                                                    >
                                                                        <IconButton aria-label="pdf">
                                                                            <SimCardDownloadIcon fontSize="large" color="primary" />
                                                                        </IconButton>
                                                                    </Link>
                                                                ) : null}
                                                            </React.Fragment>
                                                        </Stack>
                                                    </Stack>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        {tabIndex === 1 ? (
                                            <React.Fragment>
                                                {values.PartOneConsent?.FileName ? (
                                                    <React.Fragment>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Need to re-upload part one consent?</FormLabel>
                                                            <Switch
                                                                checked={isNewPartOne}
                                                                onChange={(e) => onToggleChange(ConsentDocumentTypeEnum.PartOne, e.target.checked)}
                                                            />
                                                        </FormControl>
                                                    </React.Fragment>
                                                ) : null}
                                                {!Boolean(values.PartOneConsent?.FileName) || isNewPartOne ? (
                                                    <React.Fragment>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Upload Part 1 Consent</FormLabel>
                                                            <Button variant="contained" component="label" className="upload-btn">
                                                                Choose file
                                                                <input
                                                                    hidden
                                                                    accept="application/pdf"
                                                                    type="file"
                                                                    onChange={(e) =>
                                                                        handleOnFileSelected(
                                                                            e.currentTarget as HTMLInputElement,
                                                                            setFieldValue,
                                                                            'NewPartOneConsent',
                                                                            validateForm
                                                                        )
                                                                    }
                                                                />
                                                            </Button>
                                                            <span className="upload-btn-file-name ml-20">{values.NewPartOneConsent?.FileName}</span>
                                                        </FormControl>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Part 1 Consent Document Name</FormLabel>
                                                            <TextField
                                                                label="Name"
                                                                variant="outlined"
                                                                size="small"
                                                                name="NewPartOneConsent.Name"
                                                                value={prepareTextFieldValue(values.NewPartOneConsent?.Name)}
                                                                error={Boolean(errors.NewPartOneConsent?.Name)}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Part 1 Consent Form Signed Date</FormLabel>
                                                            <div
                                                                style={{
                                                                    maxWidth: 195,
                                                                }}
                                                            >
                                                                <DesktopDatePicker
                                                                    className="hide-textinput-label"
                                                                    value={
                                                                        Boolean(values?.NewPartOneConsent?.DocumentSignedOn)
                                                                            ? new Date(values.NewPartOneConsent.DocumentSignedOn)
                                                                            : null
                                                                    }
                                                                    inputFormat="MM/dd/yyyy"
                                                                    minDate={new Date('2017-01-01')}
                                                                    onChange={(date) => {
                                                                        if (moment(date).isValid() || !Boolean(date)) {
                                                                            setFieldValue('NewPartOneConsent.DocumentSignedOn', date.toISOString());
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            {...params}
                                                                            error={Boolean(errors?.NewPartOneConsent?.DocumentSignedOn) && Boolean(submitCount)}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </FormControl>
                                                    </React.Fragment>
                                                ) : null}
                                            </React.Fragment>
                                        ) : null}
                                        {tabIndex === 2 ? (
                                            <React.Fragment>
                                                {values.PartTwoConsent?.FileName ? (
                                                    <React.Fragment>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Need to re-upload part two consent?</FormLabel>
                                                            <Switch
                                                                checked={isNewPartTwo}
                                                                onChange={(e) => onToggleChange(ConsentDocumentTypeEnum.PartTwo, e.target.checked)}
                                                            />
                                                        </FormControl>
                                                    </React.Fragment>
                                                ) : null}
                                                {!Boolean(values.PartTwoConsent?.FileName) || isNewPartTwo ? (
                                                    <React.Fragment>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Upload Part 2 Consent</FormLabel>
                                                            <Button variant="contained" component="label" className="upload-btn">
                                                                Choose file
                                                                <input
                                                                    hidden
                                                                    accept="application/pdf"
                                                                    type="file"
                                                                    onChange={(e) =>
                                                                        handleOnFileSelected(
                                                                            e.currentTarget as HTMLInputElement,
                                                                            setFieldValue,
                                                                            'NewPartTwoConsent',
                                                                            validateForm
                                                                        )
                                                                    }
                                                                />
                                                            </Button>
                                                            <span className="upload-btn-file-name ml-20">{values.NewPartTwoConsent?.FileName}</span>
                                                        </FormControl>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Part 2 Consent Document Name</FormLabel>
                                                            <TextField
                                                                label="Name"
                                                                variant="outlined"
                                                                size="small"
                                                                name="NewPartTwoConsent.Name"
                                                                value={prepareTextFieldValue(values.NewPartTwoConsent?.Name)}
                                                                error={Boolean(errors.NewPartTwoConsent?.Name)}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth={true} className="d-row-center">
                                                            <FormLabel>Part 2 Consent Form Signed Date</FormLabel>
                                                            <div
                                                                style={{
                                                                    maxWidth: 195,
                                                                }}
                                                            >
                                                                <DesktopDatePicker
                                                                    className="hide-textinput-label"
                                                                    value={
                                                                        Boolean(values?.NewPartTwoConsent?.DocumentSignedOn)
                                                                            ? new Date(values.NewPartTwoConsent.DocumentSignedOn)
                                                                            : null
                                                                    }
                                                                    inputFormat="MM/dd/yyyy"
                                                                    minDate={new Date('2017-01-01')}
                                                                    onChange={(date) => {
                                                                        if (moment(date).isValid() || !Boolean(date)) {
                                                                            setFieldValue('NewPartTwoConsent.DocumentSignedOn', date.toISOString());
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            {...params}
                                                                            error={Boolean(errors?.NewPartTwoConsent?.DocumentSignedOn) && Boolean(submitCount)}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </FormControl>
                                                    </React.Fragment>
                                                ) : null}
                                            </React.Fragment>
                                        ) : null}
                                    </Collapse>
                                </>
                            )}
                        </Grid>
                        {fetchingConsentForm ? null : (
                            <Grid item className="content-footer">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={values.ConsentToService === null || isSaving}
                                    endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                                >
                                    Next
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {values.ConsentToService &&
                    consentDocumentValid.some((c) => c.IsExpired) &&
                    !Boolean(values.PartOneConsent?.File || values.NewPartOneConsent?.File) &&
                    !Boolean(values.PartOneConsent?.AzureDocumentUri) ? (
                        <AlertDialog
                            open={showSaveAlert}
                            title="Consent Form"
                            okText="Upload"
                            isLoading={isSaving}
                            hideCancelButton
                            onSkip={() => handleOnConfirmed(values)}
                            onConfirm={() => {
                                setShowSaveAlert(false);
                                setTabIndex(1);
                            }}
                            message="Part one consent form document is not uploaded or expired. Do you want to upload now?"
                        />
                    ) : (
                        <AlertDialog
                            open={showSaveAlert}
                            title="Consent Form"
                            cancelText="Go Back"
                            okText="Confirm"
                            isLoading={isSaving}
                            onConfirm={() => handleOnConfirmed(values)}
                            onClose={() => setShowSaveAlert(false)}
                            message="I confirm and acknowledge that Consent Form is completed."
                        />
                    )}
                    <AlertDialog
                        open={showMemberStatusAlert}
                        title="Consent Form"
                        cancelText="Go Back"
                        okText="Confirm"
                        isLoading={isSaving}
                        onConfirm={() => handleOnConfirmed(values, true)}
                        onClose={() => {
                            setDateOfOnboard(null);
                            setShowMemberStatusAlert(false);
                        }}
                        renderBody={() => (
                            <Stack>
                                <Typography variant="subtitle1" component="label">
                                    I confirm and acknowledged that consent form is completed.
                                </Typography>
                                <Typography variant="subtitle1" component="label">
                                    Do you want to change member status to {isInHra ? 'HRA in Review' : isInFuhFum ? 'FUHFUM In-Progress' : ' active'}?
                                </Typography>

                                {isInHra || isInFuhFum ? null : (
                                    <div style={{ maxWidth: 195, marginTop: 4 }}>
                                        <DesktopDatePicker
                                            label="Date Of Onboard"
                                            value={dateOfOnboard}
                                            inputFormat="MM/dd/yyyy"
                                            minDate={new Date('2017-01-01')}
                                            onChange={(date) => {
                                                if (moment(date).isValid() || !Boolean(date)) {
                                                    setDateOfOnboard(date.toISOString());
                                                }
                                            }}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </div>
                                )}
                            </Stack>
                        )}
                    />
                    <AlertDialog
                        open={showNoServiceAlert}
                        title="Consent Form"
                        cancelText="Go Back"
                        okText="Submit"
                        isLoading={isSaving}
                        onConfirm={() => handleOnConfirmed(values, true)}
                        onClose={() => {
                            setChangedMemberStatus(null);
                            setProgressNotes('');
                            setShowNoServiceAlert(false);
                        }}
                        disabledSubmit={!Boolean(progressNotes)}
                        renderBody={() => (
                            <Stack>
                                <FormControl fullWidth={true}>
                                    <FormLabel>Do you want to change member status to?</FormLabel>
                                    <Select
                                        style={{ width: 195 }}
                                        value={changedMemberStatus}
                                        label="Status"
                                        className="hide-textinput-label"
                                        onChange={(e) => setChangedMemberStatus(e.target.value)}
                                        displayEmpty
                                    >
                                        <MenuItem value={null}>Select</MenuItem>
                                        {memberStatuses.map((item, index) => (
                                            <MenuItem key={`${item.Id}-${index}`} value={item.Id}>
                                                {item.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth={true} sx={{ marginTop: 4 }}>
                                    <FormLabel required>Please document the details of why member doesn't consent to services</FormLabel>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        name="PartTwoConsent.Name"
                                        className="hide-textinput-label"
                                        value={progressNotes}
                                        multiline
                                        rows={5}
                                        onChange={(e) => setProgressNotes(e.target.value)}
                                    />
                                </FormControl>
                            </Stack>
                        )}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default Consent;
