export enum TimelineTypeEnum {
    None = 0,
    Engagement = 1,
    EngagementDescription = 'Engagement',
    Appointment = 2,
    AppointmentDescription = 'Appointment',
    IntegratedCarePlan = 3,
    IntegratedCarePlanDescription = 'Integrated Care Plan',
    Assessments = 4,
    AssessmentsDescription = 'Assessment',
    Documents = 5,
    DocumentsDescription = 'Document',
    DemographicInfo = 6,
    DemographicInfoDescription = 'Demographic Info',
    CareGivers = 7,
    CareGiversDescription = 'Care Giver',
    ClinicalData = 8,
    ClinicalDataDescription = 'Clinical Data',
    MemberStatusChange = 9,
    MemberStatusChangeDescription = 'Member Status Change',
    Request = 10,
    RequestDescription = 'Request',
    PartTwoDataDownload = 11,
    PartTwoDataDownloadDescription = 'Part 2 Data Download',
    ClonedMemberDescription = 'Cloned Member',
    ClonedMember = 12,
    ClosedMemberAccessedDescription = 'Closed Member Accessed',
    ClosedMemberAccessed = 13,
    OverrideCloseTransitionalCareDescription = 'Override Close Transitional Care',
    OverrideCloseTransitionalCare = 14,
    TransitionalCareDescription = 'Transitional Care',
    TransitionalCare = 15,
    BackgroundJobDescription = 'Background Job',
    BackgroundJob = 16,
}
