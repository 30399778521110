import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading, setShowMessage } from './shared.slice';
import { Provider } from '../Models/Provider/Provider.model';
import { TeamTypes } from '../utils/assessments';
import { TeamTypeEnum } from '../Enum/TeamTypeEnum';
import { ProviderPreview } from '../Models/CarePlans/SchedulePlan.model';

const providerSlice = createSlice({
    name: 'provider',
    initialState: {
        activeProviders: [] as Provider[],
        npProviders: [] as Provider[],
        tccProviders: [] as Provider[],
        licensedProviders: [] as Provider[],
        providerPreviews: [] as ProviderPreview[],
        isFetchedTCCProvider: false,
        isFetchedLicensedProvider: false,
    },
    reducers: {
        _setActiveProviders(state, action) {
            state.activeProviders = action.payload;
            state.npProviders = action.payload.filter((p) => p.Role && p.Role.RoleName === TeamTypes.find((t) => t.Id === TeamTypeEnum.NursePractitioner).Name);
        },
        _setProviderPreviews(state, action) {
            state.providerPreviews = action.payload;
        },
        _setTccProviders(state, action) {
            state.tccProviders = action.payload;
            state.isFetchedTCCProvider = true;
        },
        _setLicensedProviders(state, action) {
            state.licensedProviders = action.payload;
            state.isFetchedLicensedProvider = true;
        },
    },
});

const { _setActiveProviders, _setProviderPreviews, _setTccProviders, _setLicensedProviders } = providerSlice.actions;

export const getActiveProviders =
    (includeInActiveProviders: boolean): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/provider/getactiveproviders?includeInActiveProviders=${includeInActiveProviders}`);
        dispatch(_setActiveProviders(response.data));
    };

export const getProviderPreview = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/provider/getproviderpreview`);
    dispatch(_setProviderPreviews(response.data));
    dispatch(setIsLoading(false));
};

export const getTCCRoleProviders =
    (includeInActiveProviders: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/provider/gettccroleproviders?includeInActiveProviders=${includeInActiveProviders}`);
        dispatch(_setTccProviders(response.data));
        dispatch(setIsLoading(false));
    };

export const getLicensedRoleProviders =
    (includeInActiveProviders: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/provider/getlicensedproviders?includeInActiveProviders=${includeInActiveProviders}`);
        dispatch(_setLicensedProviders(response.data));
        dispatch(setIsLoading(false));
    };

export const getForgottenPassword =
    (userEmail: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.post(`${API_URL}/account/forgotpassword?userEmail=${userEmail}`);
        dispatch(setShowMessage(true, 'A new temporary password has been sent to your email', 'success'));
    };

export default providerSlice.reducer;
