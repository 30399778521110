import { CarePlanEnum } from '../../Enum/CarePlanEnum';
import BaseEntity from '../BaseEntity.model';
import { CreatedBy } from '../Shared/CreatedBy.model';
import { DeletedBy } from '../Shared/DeletedBy.model';
import { Progress } from './Progress.model';

export default interface IPlan extends BaseEntity {
    Name: string;
    Status: CarePlanEnum;
    Progress: Progress[];
    CreatedAt: string;
    CreatedBy: CreatedBy | null;
    AchievedOn: string;
    DeletedDate: string;
    ReasonForDelete: string;
    DeletedBy: DeletedBy | null;
}

export class Plan implements IPlan {
    Id: string | null;
    Name: string;
    Status: CarePlanEnum;
    Progress: Progress[];
    CreatedAt: string;
    CreatedBy: CreatedBy | null;
    AchievedOn: string;
    DeletedDate: string;
    ReasonForDelete: string;
    DeletedBy: DeletedBy | null;

    SelectedForDelete: boolean;
    IsSelected: boolean;
    IsOpenProgress: boolean;
    NewProgressNote: string;

    constructor() {
        this.Id = null;
        this.Name = '';
        this.Status = 0;
        this.Progress = [] as Progress[];
        this.CreatedBy = null;
        this.CreatedAt = '';
        this.AchievedOn = '';
        this.DeletedDate = '';
        this.ReasonForDelete = '';
        this.DeletedBy = null;

        this.SelectedForDelete = false;
        this.IsSelected = false;
        this.IsOpenProgress = false;
        this.NewProgressNote = '';
    }
}