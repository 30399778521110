import { TimeIntervalTypeEnum } from '../../Enum/TimeIntervalTypeEnum';
import BaseEntity from '../BaseEntity.model';

export default interface ISchedulePlan extends BaseEntity {
    Provider: ProviderPreview;
    TimeIntervalIndays: number;
    TimeIntervalType: TimeIntervalTypeEnum;
    SelectedForDelete: boolean;
}

export class ProviderPreview {
    Id: string;
    Name: string;
    Role: string;
}

export class SchedulePlan implements ISchedulePlan {
    Id: string | null;
    Provider: ProviderPreview;
    TimeIntervalIndays: number;
    TimeIntervalType: TimeIntervalTypeEnum;
    SelectedForDelete: boolean;
    IsSelected: boolean;

    constructor() {
        this.Id = null;
        this.SelectedForDelete = false;
        this.IsSelected = false;
    }
}
