import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    Checkbox,
    IconButton,
    TextField,
    FormControlLabel,
    Button,
    FormControl,
    InputLabel,
    FormHelperText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Typography,
    Modal,
    Box,
    Select,
    MenuItem,
    Tooltip,
    Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DoneIcon from '@mui/icons-material/Done';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../reducers';
import moment from 'moment';
import classes from './Styles';
import { Goal } from '../../Models/CarePlans/Goal.model';
import { addGoal, editGoals, addGoalProgress, setIntegratedCarePlan, setSelectedGoal, toggleShowGoalDeleteAlert } from '../../store/carePlan.slice';
import { copyObject } from '../../utils/common';
import { CarePlanEnum } from '../../Enum/CarePlanEnum';
import { CarePlanStatus } from '../../utils/mappings';

const goalDeleteFormSchema = Yup.object().shape({
    ReasonForDelete: Yup.string().nullable().required('Select reason.'),
});

const goalFormSchema = Yup.object().shape({
    Name: Yup.string().nullable().required('Name is required.'),
    DueDate: Yup.string().nullable().required('Date is required.'),
});

const Goals: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [showDeletedGoals, setShowDeletedGoals] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { integratedCarePlan, selectedGoal, reasonsForDelete, showGoalDeleteAlert, isSavingGoal, isSavingGoalProgress } = useSelector(
        (state: RootState) => state.carePlan
    );
    const goals = integratedCarePlan.Goals?.filter((g) => showDeletedGoals || !Boolean(g.DeletedDate));

    const handleOnCheck = (isShow: boolean) => {
        setShowDeletedGoals(isShow);
    };

    const handleOnOpenProgress = (id: string) => {
        let data = copyObject(integratedCarePlan);
        data.Goals.forEach((goal) => {
            if (goal.Id === id) {
                goal.IsOpenProgress = !goal.IsOpenProgress;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnChangeNewProgress = (id: string, note: string) => {
        let data = copyObject(integratedCarePlan);
        data.Goals.forEach((goal) => {
            if (goal.Id === id) {
                goal.NewProgressNote = note;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnStatusChange = (goal: Goal, status: CarePlanEnum) => {
        let data = copyObject(goal);
        data.Status = status;
        dispatch(editGoals(memberId, [data]));
    };

    const addNewProgress = (id: string) => {
        const selectedGoal = integratedCarePlan.Goals.find((g) => g.Id === id);
        dispatch(addGoalProgress(memberId, selectedGoal.Id, selectedGoal.NewProgressNote));
    };

    const handleOnClickSave = (values: Goal) => {
        dispatch(setSelectedGoal(values));
        if (Boolean(values.Id)) {
            dispatch(editGoals(memberId, [values]));
        } else {
            dispatch(addGoal(memberId, values.Name, values.DueDate));
        }
    };

    const handleOnClickDelete = (goal: Goal) => {
        dispatch(setSelectedGoal({ ...goal, SelectedForDelete: true }));
        dispatch(toggleShowGoalDeleteAlert(true));
    };

    const handleOnDeleteSubmit = (values) => {
        values.Status = CarePlanEnum.Deleted;
        values.DeletedDate = new Date().toISOString();
        dispatch(editGoals(memberId, [values]));
    };

    const toggleOnNewGoal = () => {
        dispatch(setSelectedGoal(new Goal()));
        setOpenNew(!openNew);
    };

    const toggleOnCancelGoal = () => {
        dispatch(setSelectedGoal(new Goal()));
        setOpenNew(false);
    };

    const handleCloseAlert = () => dispatch(toggleShowGoalDeleteAlert(false));

    const renderDeleteAlert = () => {
        return (
            <Modal open={showGoalDeleteAlert} onClose={handleCloseAlert}>
                <Box sx={classes.modalPopupForm}>
                    <div className="mui-modal-header">
                        <Typography variant="h6" component="h6">
                            Delete Goal
                        </Typography>
                    </div>
                    <Formik initialValues={selectedGoal} onSubmit={handleOnDeleteSubmit} validationSchema={goalDeleteFormSchema}>
                        {({ values, errors, handleChange }) => (
                            <Form>
                                <div className="mui-modal-body">
                                    <FormControl fullWidth={true} error={Boolean(errors?.ReasonForDelete)} size="small">
                                        <InputLabel required>Reason for Deleting</InputLabel>
                                        <Select
                                            size="small"
                                            value={Boolean(values.ReasonForDelete) ? values.ReasonForDelete : ''}
                                            label="Reason for Deleting*"
                                            name="ReasonForDelete"
                                            onChange={handleChange}
                                        >
                                            {reasonsForDelete.map((reason, index) => (
                                                <MenuItem key={`delete_reason_${index}`} value={reason.Name}>
                                                    {reason.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors?.ReasonForDelete}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="mui-modal-footer">
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        disabled={isSavingGoal}
                                        endIcon={isSavingGoal ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        Delete
                                    </Button>
                                    <Button size="small" type="button" onClick={handleCloseAlert} variant="outlined" disabled={isSavingGoal}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        );
    };

    const renderGoal = (goal: Goal, index: number) => {
        const isEdit = !selectedGoal.SelectedForDelete && selectedGoal.Id === goal.Id;
        return (
            <>
                <TableRow>
                    <TableCell colSpan={7}>
                        <Table sx={classes.editTableContainer}>
                            {isEdit ? (
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <Formik initialValues={selectedGoal} onSubmit={handleOnClickSave} validationSchema={goalFormSchema} enableReinitialize>
                                            {({ values, errors, handleChange, setFieldValue }) => (
                                                <Form noValidate>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell scope="row">
                                                                    <FormControl fullWidth={true} error={Boolean(errors?.Name)} size="small">
                                                                        <TextField
                                                                            required
                                                                            variant="outlined"
                                                                            size="small"
                                                                            sx={classes.textInput}
                                                                            fullWidth
                                                                            name="Name"
                                                                            autoFocus
                                                                            placeholder="Enter new goal here"
                                                                            value={values.Name}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <FormHelperText>{errors?.Name}</FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell width="230px" colSpan={2}>
                                                                    <FormControl
                                                                        fullWidth={true}
                                                                        error={Boolean(errors?.DueDate)}
                                                                        size="small"
                                                                        style={{ paddingLeft: '32px' }}
                                                                    >
                                                                        <DesktopDatePicker
                                                                            disabled={goal.IsAddedBySystem}
                                                                            value={Boolean(values.DueDate) ? new Date(values.DueDate) : null}
                                                                            inputFormat="dd-MMM-yyyy"
                                                                            minDate={new Date()}
                                                                            onChange={(val) => {
                                                                                setFieldValue('DueDate', val?.toISOString());
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField sx={classes.textInput} fullWidth size="small" {...params} />
                                                                            )}
                                                                        />
                                                                        <FormHelperText>{errors?.DueDate}</FormHelperText>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell width="140px">
                                                                    <Box sx={classes.actionButtons}>
                                                                        <IconButton type="submit">
                                                                            {isSavingGoal ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                                                        </IconButton>
                                                                        <IconButton onClick={toggleOnCancelGoal}>
                                                                            <HighlightOffSharpIcon color="error" />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Form>
                                            )}
                                        </Formik>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow
                                    key={goal.Id}
                                    sx={
                                        goal.Status === CarePlanEnum.Achieved
                                            ? classes.achievedText
                                            : goal.Status === CarePlanEnum.NotMet
                                            ? classes.notMetText
                                            : goal.Status === CarePlanEnum.Deleted
                                            ? classes.deletedText
                                            : classes.pointer
                                    }
                                >
                                    <TableCell scope="row" onClick={() => handleOnOpenProgress(goal.Id)}>
                                        <div className="d-flex-row">
                                            <span className="count">{index + 1}</span>
                                            {goal.Name}
                                        </div>
                                    </TableCell>
                                    <TableCell width="200px" onClick={() => handleOnOpenProgress(goal.Id)}>
                                        {goal.CreatedBy?.Name}
                                    </TableCell>
                                    <TableCell width="180px" onClick={() => handleOnOpenProgress(goal.Id)}>
                                        {moment(goal.CreatedAt).isValid() ? moment(goal.CreatedAt).format('MM-DD-yyyy hh:mm a') : '-'}
                                    </TableCell>
                                    <TableCell width="100px" onClick={() => handleOnOpenProgress(goal.Id)}>
                                        {goal.Status === CarePlanEnum.Deleted
                                            ? moment(goal.DeletedDate).isValid()
                                                ? `Deleted On ${moment(goal.DeletedDate).format('MM-DD-yyyy')}`
                                                : '-'
                                            : moment(goal.DueDate).isValid()
                                            ? moment(goal.DueDate).format('MM-DD-yyyy')
                                            : '-'}
                                    </TableCell>
                                    <TableCell width="120px" onClick={() => handleOnOpenProgress(goal.Id)}>
                                        {CarePlanStatus.find((c) => c.Id === goal.Status)?.Name}
                                    </TableCell>
                                    <TableCell width="140px" className="hover-visble">
                                        <Box sx={classes.actionButtons}>
                                            {goal.Status !== CarePlanEnum.Achieved ? (
                                                <>
                                                    <Tooltip title="Edit" placement="top">
                                                        <IconButton
                                                            onClick={() => {
                                                                dispatch(setSelectedGoal(goal));
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Achieved" placement="top">
                                                        <IconButton onClick={() => handleOnStatusChange(goal, CarePlanEnum.Achieved)}>
                                                            <DoneIcon color="success" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Not Met" placement="top">
                                                        <IconButton onClick={() => handleOnStatusChange(goal, CarePlanEnum.NotMet)}>
                                                            <DoNotDisturbIcon color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                        <IconButton onClick={() => handleOnClickDelete(goal)}>
                                                            {false ? <CircularProgress size={18} /> : <DeleteIcon color="warning" />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            ) : null}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: 'transparent' }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={goal.IsOpenProgress} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1, p: 0 }}>
                                <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                                    <TableBody>
                                        {goal.Progress?.length
                                            ? goal.Progress.map((progress) => (
                                                  <TableRow key={progress.Id}>
                                                      <TableCell>
                                                          <IconButton>
                                                              <ThumbUpOffAltIcon />
                                                          </IconButton>
                                                          {progress.Name}
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                            : null}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    sx={classes.textInput}
                                                    fullWidth
                                                    name="Name"
                                                    placeholder="Add Progress Entry"
                                                    value={goal.NewProgressNote}
                                                    autoFocus
                                                    onChange={(e) => handleOnChangeNewProgress(goal.Id, e.target.value)}
                                                    onKeyUp={(e) => e.key === 'Enter' && addNewProgress(goal.Id)}
                                                    InputProps={{
                                                        endAdornment: isSavingGoalProgress ? <CircularProgress size={15} /> : null,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    };

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction="row" columnGap={2}>
                                    <Typography sx={classes.planTitle}>Goals</Typography>
                                    <FormControlLabel
                                        sx={{ m: 0 }}
                                        control={
                                            <Checkbox
                                                checked={showDeletedGoals}
                                                size="small"
                                                name="Show Deleted Goals"
                                                onChange={(e) => handleOnCheck(e.target.checked)}
                                            />
                                        }
                                        label="Show Deleted Goals"
                                    />
                                </Stack>
                            </TableCell>
                            <TableCell width="200px">Provider</TableCell>
                            <TableCell width="180px">Created Date</TableCell>
                            <TableCell width="100px">Due Date</TableCell>
                            <TableCell width="150px">Status</TableCell>
                            <TableCell width="140px" align="right">
                                <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={toggleOnNewGoal}>
                                    {openNew ? <KeyboardArrowUpIcon /> : <AddIcon />}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {goals?.length ? (
                    <Table size="small">
                        <TableBody>{goals.map(renderGoal)}</TableBody>
                    </Table>
                ) : !openNew ? (
                    <div className="no-data-container">No goals to display</div>
                ) : null}
                <Collapse in={openNew} timeout="auto" unmountOnExit>
                    <Formik initialValues={selectedGoal} onSubmit={handleOnClickSave} validationSchema={goalFormSchema} enableReinitialize>
                        {({ values, errors, handleChange, setFieldValue }) => (
                            <Form noValidate>
                                <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <FormControl fullWidth={true} error={Boolean(errors?.Name)} size="small">
                                                    <TextField
                                                        required
                                                        variant="outlined"
                                                        size="small"
                                                        sx={classes.textInput}
                                                        fullWidth
                                                        name="Name"
                                                        autoFocus
                                                        placeholder="Enter new goal here"
                                                        value={values.Name}
                                                        onChange={handleChange}
                                                    />
                                                    <FormHelperText>{errors?.Name}</FormHelperText>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell width="230px" colSpan={2}>
                                                <FormControl fullWidth={true} error={Boolean(errors?.DueDate)} size="small">
                                                    <DesktopDatePicker
                                                        value={Boolean(values.DueDate) ? new Date(values.DueDate) : null}
                                                        inputFormat="dd-MMM-yyyy"
                                                        minDate={new Date()}
                                                        onChange={(val) => {
                                                            setFieldValue('DueDate', val?.toISOString());
                                                        }}
                                                        renderInput={(params) => <TextField sx={classes.textInput} fullWidth size="small" {...params} />}
                                                    />
                                                    <FormHelperText>{errors?.DueDate}</FormHelperText>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell width="140px">
                                                <Box sx={classes.actionButtons}>
                                                    <IconButton type="submit">
                                                        {isSavingGoal ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                                    </IconButton>
                                                    <IconButton onClick={toggleOnNewGoal}>
                                                        <HighlightOffSharpIcon color="error" />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Form>
                        )}
                    </Formik>
                </Collapse>
            </TableContainer>
            {renderDeleteAlert()}
        </>
    );
};

export default Goals;
