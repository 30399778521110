import React, { useEffect, useState } from 'react';
import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    Fab,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
    Card,
    CardActionArea,
    Collapse,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineConnector, TimelineSeparator, TimelineContent, TimelineDot } from '@mui/lab';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdjustIcon from '@mui/icons-material/Adjust';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { KeyboardArrowUp } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import moment from 'moment';

import { RootState } from '../../reducers';
import { TimelineTypeEnum } from '../../Enum/TimelineTypeEnum';
import { getDocumentEngagementById, getTimelineByType } from '../../store/timeline.slice';
import { TimelineTypes } from '../../utils/mappings';
import styles from './Styles';
import { meansOfEngagementEnums } from '../../utils/constants';
import { useParams } from 'react-router-dom';

moment.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: 'DD dddd MMM yyyy',
    },
});

const Timelines = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const [type, setType] = useState(TimelineTypeEnum.None);
    const [showScrollerButton, setShowScrollerButton] = useState(false);
    const { groupedTimelines, isFetching } = useSelector((state: RootState) => state.timeline);
    const classes = styles;

    useEffect(() => {
        dispatch(getTimelineByType(memberId, type));
    }, [dispatch, memberId, type]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const scrolled = document.documentElement.scrollTop;
            setShowScrollerButton(scrolled > 300);
        });
    }, []);

    const handleOnClickTimelineDetail = (detailId: string, index: number) => {
        dispatch(getDocumentEngagementById(detailId, index));
    };

    const renderTimelineIcon = (type: TimelineTypeEnum) => {
        let color = 'primary';
        let icon = <FileCopyIcon />;
        switch (type) {
            case TimelineTypeEnum.Appointment:
                color = 'secondary';
                icon = <EventAvailableIcon />;
                break;
            case TimelineTypeEnum.IntegratedCarePlan:
                color = 'info';
                icon = <ManageAccountsIcon />;
                break;
            case TimelineTypeEnum.Assessments:
                color = 'success';
                icon = <MedicalInformationIcon />;
                break;
            case TimelineTypeEnum.DemographicInfo:
                color = 'warning';
                icon = <GroupsOutlinedIcon />;
                break;
            case TimelineTypeEnum.CareGivers:
                color = 'success';
                icon = <MedicalInformationIcon />;
                break;
            case TimelineTypeEnum.ClinicalData:
                color = 'warning';
                icon = <AdjustIcon />;
                break;
            case TimelineTypeEnum.MemberStatusChange:
                color = 'success';
                icon = <SensorOccupiedIcon />;
                break;
            case TimelineTypeEnum.Request:
                color = 'warning';
                icon = <SendToMobileIcon />;
                break;
            case TimelineTypeEnum.PartTwoDataDownload:
                color = 'success';
                icon = <SummarizeIcon />;
                break;
            case TimelineTypeEnum.ClonedMember:
                color = 'success';
                icon = <FileCopyIcon />;
                break;
            case TimelineTypeEnum.ClosedMemberAccessed:
                color = 'error';
                icon = <VerifiedUserIcon />;
                break;
            case TimelineTypeEnum.OverrideCloseTransitionalCare:
                color = 'error';
                break;
            case TimelineTypeEnum.TransitionalCare:
                color = 'warning';
                break;
            case TimelineTypeEnum.BackgroundJob:
                color = 'info';
                icon = <MedicalInformationIcon />;
                break;
        }

        return <TimelineDot color={color as any}>{icon}</TimelineDot>;
    };

    const renderDateLabel = (date: any) => {
        const isToday = moment(date).isSame(new Date(), 'day');
        const isYesterday = moment(date).isSame(moment().add(-1, 'day'), 'day');

        return (
            <React.Fragment>
                <Divider sx={classes.mainDivider} style={{ top: isToday || isYesterday ? '16px' : '22px' }} />
                <Chip
                    sx={classes.dateChip}
                    label={
                        isToday ? (
                            <Typography fontWeight="bold">Today</Typography>
                        ) : isYesterday ? (
                            <Typography fontWeight="bold">Yesterday</Typography>
                        ) : (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography fontSize="30px" fontWeight="bold" component="span">
                                    {moment(date).format('DD')}
                                </Typography>
                                <Stack>
                                    <Typography fontSize="15px" component="span">
                                        {moment(date).format('ddd')}
                                    </Typography>
                                    <Typography fontSize="15px" component="span">
                                        {moment(date).format('MMM yyyy')}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )
                    }
                    color="primary"
                    variant={isToday ? 'filled' : 'outlined'}
                />
            </React.Fragment>
        );
    };

    const scrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Box sx={classes.container}>
            <FormControl style={{ width: '300px', marginBottom: '20px' }}>
                <InputLabel id="timeline-type-select">Timeline Type</InputLabel>
                <Select
                    labelId="timeline-type-select"
                    id="timeline-type-select"
                    value={type}
                    label="Timeline Type"
                    onChange={(e) => setType(e.target.value as TimelineTypeEnum)}
                >
                    <MenuItem value={TimelineTypeEnum.None}>All Events</MenuItem>
                    {TimelineTypes.sort((a, b) => a.label.toLocaleString().localeCompare(b.label.toLocaleString())).map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                            {type.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {isFetching ? (
                <Box display="flex" justifyContent="center" sx={classes.container}>
                    <CircularProgress />
                </Box>
            ) : Boolean(groupedTimelines?.length) ? (
                groupedTimelines.map((gTimeline, index) => (
                    <Stack alignItems="center" position="relative" key={`timeline-parent-${index}`}>
                        {renderDateLabel(gTimeline.Key)}
                        {
                            // @ts-ignore
                            <Timeline position="alternate" style={{ width: '100%' }}>
                                {gTimeline.Values.map((timeline, cIndex) => (
                                    <TimelineItem key={`timeline=item-${cIndex}`}>
                                        <TimelineOppositeContent style={{ margin: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                                            <Typography variant="subtitle2" component="span">
                                                {moment(timeline.CreationTime).isValid() ? moment(timeline.CreationTime).format('hh:mm a') : '-'}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            {renderTimelineIcon(timeline.Type)}
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant="h6" component="span">
                                                {TimelineTypes.find((t) => t.value === timeline.Type)?.label}
                                            </Typography>
                                            {!Boolean(timeline.DetailId) || timeline.DetailId.includes('000000000000000000000000') ? (
                                                <Card variant="outlined" sx={classes.timelineCard}>
                                                    <Typography className="p-15">{timeline.Description}</Typography>
                                                </Card>
                                            ) : (
                                                <Card
                                                    variant="outlined"
                                                    onClick={() =>
                                                        !Boolean(timeline.DocumentEngagement) && handleOnClickTimelineDetail(timeline.DetailId, cIndex)
                                                    }
                                                    sx={classes.timelineCard}
                                                >
                                                    <CardActionArea className="p-15">{timeline.Description}</CardActionArea>
                                                    <Collapse
                                                        in={timeline.IsFetchingEngagementDetail || Boolean(timeline.DocumentEngagement?.Id)}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <Box sx={classes.timelineDetail}>
                                                            {timeline.IsFetchingEngagementDetail ? (
                                                                <Box display="flex" justifyContent="center">
                                                                    <CircularProgress size={20} />
                                                                </Box>
                                                            ) : Boolean(timeline.DocumentEngagement) ? (
                                                                <Stack spacing={1}>
                                                                    <Typography variant="body1" component="span">
                                                                        <strong>Means of Engagement: </strong>
                                                                        {meansOfEngagementEnums.find(
                                                                            (m) => m.value === timeline.DocumentEngagement.MeansOfEngagement
                                                                        )?.text || '-'}
                                                                    </Typography>
                                                                    <Typography variant="body1" component="span">
                                                                        <strong>Provider:</strong> {timeline.DocumentEngagement.EngagedProvider?.Name} (
                                                                        {timeline.DocumentEngagement.EngagedProvider?.Role})
                                                                    </Typography>
                                                                    <Typography variant="body1" component="span">
                                                                        <strong>Reason for visit: </strong>
                                                                        {timeline.DocumentEngagement.ReasonForVisit?.Name || '-'}
                                                                    </Typography>
                                                                    {timeline.DocumentEngagement.IsShowStaffReferral ? (
                                                                        <Typography variant="body1" component="span">
                                                                            <strong>Staff Referral: </strong>
                                                                            {timeline.DocumentEngagement.StaffReferral?.ProviderName || '-'}
                                                                        </Typography>
                                                                    ) : null}
                                                                    {timeline.DocumentEngagement.isShowPatientOutReach ? (
                                                                        <Typography variant="body1" component="span">
                                                                            <strong>Patient outreach/patient initiated: </strong>
                                                                            {timeline.DocumentEngagement.PatientOutreachOrInitiatedType || '-'}
                                                                        </Typography>
                                                                    ) : null}
                                                                    <Typography variant="body1" component="span">
                                                                        <strong>Visited Time: </strong>
                                                                        {moment(timeline.DocumentEngagement.VisitedTime).isValid()
                                                                            ? moment(timeline.DocumentEngagement.VisitedTime).format('DD MMM yyyy hh:mm A')
                                                                            : '-'}
                                                                    </Typography>
                                                                    <Stack direction="row">
                                                                        <Typography variant="body1" component="span">
                                                                            <strong>Notes: </strong>
                                                                        </Typography>
                                                                        &nbsp;
                                                                        <span dangerouslySetInnerHTML={{ __html: timeline.DocumentEngagement.Notes }} />
                                                                    </Stack>
                                                                </Stack>
                                                            ) : null}
                                                        </Box>
                                                    </Collapse>
                                                </Card>
                                            )}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        }
                    </Stack>
                ))
            ) : (
                <Box display="flex" justifyContent="center" sx={classes.container}>
                    <Typography variant="subtitle1">No events to display.</Typography>
                </Box>
            )}
            {showScrollerButton && (
                <Fab onClick={scrollToTop} sx={classes.scrollTopButton} color="primary" size="small">
                    <KeyboardArrowUp />
                </Fab>
            )}
        </Box>
    );
};

export default Timelines;
