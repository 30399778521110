export enum TimePeriodFilterEnum {
    AllDescription = 'All',
    All = 0,
    Last3MonthsDescription = 'Last 3 Months',
    Last3Months = 1,
    Last6MonthsDescription = 'Last 6 Months',
    Last6Months = 2,
    Last1YearDescription = 'Last 1 Year',
    Last1Year = 3,
    CustomDescription = 'Custom',
    Custom = 4,
}