import React from 'react';
import WidgetBox from '../WidgetBox';
import CallItem from '../../../Models/OfficeOnboarding/CallItem.model';
import { Grid, Paper, TextField } from '@mui/material';

const CurrentCallList = (props: any) => {
    console.log('CurrentCallList props: ', props.callList);
    let callRows = undefined;
    if (props.callList) {
        callRows = props.callList.map((item: CallItem) => (
            <Grid item margin={2}>
                <Paper elevation={2}>
                    <Grid container p={2} spacing={2} alignItems="flex-start" justifyContent="space-evenly" direction="column">
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="First Name"
                                disabled
                                value={item.FirstName}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Last Name"
                                disabled
                                value={item.LastName}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Population"
                                disabled
                                value={item.PopulationName}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Zip Code"
                                disabled
                                value={item.Zip}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        minHeight: '15px',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Times Assigned"
                                disabled
                                value={item.TimesAssigned}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        minHeight: '15px',
                                        padding: '5px',
                                        color: 'black',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        ));
    }
    return (
        <WidgetBox title={`Onboarder's Current Call List: ${callRows.length} Left`}>
            <Grid direction="row" alignItems="center" justifyContent="center" container spacing={1}>
                {React.Children.toArray(callRows)}
            </Grid>
        </WidgetBox>
    );
};

export default CurrentCallList;
