import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { fetchMemberDemoGraphicInfo } from './memberDetail.slice';
import { convertEncountersToEncounterList, setDateOfService, setDraftEncounterList } from './procedureSignOffEncounter.slice';
import { convertEncountersToList } from './encounter.slice';
import { fetchProvider } from './shared.slice';

const claimResubmission = createSlice({
    name: 'claimResubmission',
    initialState: {
        claimNumber: '',
        dateOfService: '',
        showModal: false,
    },
    reducers: {
        _setClaimNumber(state, action) {
            state.claimNumber = action.payload;
        },
        _setDateOfService(state, action) {
            state.dateOfService = action.payload;
        },
        _setShowModal(state, action) {
            state.showModal = action.payload;
        },
    },
});

const { _setClaimNumber, _setDateOfService, _setShowModal} = claimResubmission.actions;

export const fetchClaimResubmitData = (claimNumber: string, memberId: string, dateOfService: string, callback: Function): AppThunk =>
async (dispatch) => {
    dispatch(_setClaimNumber(claimNumber));
    dispatch(_setDateOfService(dateOfService));

    const response = await axios.get(`${API_URL}/encounter/getencountersfromclaim?claimNumber=${claimNumber}`);
    if(response.data.length > 0) {
        dispatch(fetchProvider(response.data[0].CreatedBy.Id));
        dispatch(setDateOfService(response.data[0].DateOfService));
        let encounterList = await convertEncountersToEncounterList(response.data);
        encounterList = convertEncountersToList(encounterList);
        dispatch(setDraftEncounterList(encounterList));
    }
    dispatch(fetchMemberDemoGraphicInfo(memberId, callback));
};

export const setShowModal = (value: boolean): AppThunk =>
    (dispatch) => {
       dispatch(_setShowModal(value));
};

export default claimResubmission.reducer;
