import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, CircularProgress, Snackbar, Alert, Chip, Button, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import classes from './Styles';
import { RootState } from '../../reducers';

import { getCarePlanByMemberId, getReasonForDelete, getRoles, resetCarePlanError, updateLastReviewedBy } from '../../store/carePlan.slice';
import { getActiveProviders } from '../../store/provider.slice';
import Goal from './Goals';
import Plans from './Plans';
import SchedulePlans from './SchedulePlans';
import Barriers from './Barriers';
import Strengths from './Strengths';
import Metrics from './Metrics';
import NextSteps from './NextSteps';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';

const IntegratedCarePlan = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [isReviewed, setIsReviewed] = useState<boolean>(false);
    const [showReviewAlert, setShowReviewAlert] = useState<boolean>(false);
    const memberId = props.memberId || params.memberId;
    const { isFetching, error, integratedCarePlan, isSavingReviewHistory } = useSelector((state: RootState) => state.carePlan);
    const reviewDetail = Boolean(integratedCarePlan?.ReviewHistory?.length) ? integratedCarePlan?.ReviewHistory[0] : null;

    useEffect(() => {
        dispatch(getCarePlanByMemberId(memberId));
        dispatch(getReasonForDelete());
        dispatch(getRoles());
        dispatch(getActiveProviders(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClickSave = () => {
        if (isReviewed) {
            dispatch(updateLastReviewedBy(memberId, handleCloseAlert));
        }
    };

    const handleCloseAlert = () => {
        setShowReviewAlert(false);
        setIsReviewed(false);
    };

    const handleOnShowReviewAlert = () => {
        setShowReviewAlert(true);
    };

    const renderReviewAlert = () => {
        return (
            <Modal open={showReviewAlert} onClose={handleCloseAlert}>
                <Box sx={classes.modalPopupForm}>
                    <div className="mui-modal-header">
                        <Typography variant="h6" component="h6">
                            Reviewed Care Plan
                        </Typography>
                    </div>
                    <div className="mui-modal-body">
                        <Stack direction="row" justifyContent="flex-end" paddingRight={2}>
                            <FormControlLabel
                                sx={{ m: 0 }}
                                label="Reviewed Care Plan with Member"
                                control={
                                    <Checkbox
                                        checked={isReviewed}
                                        size="small"
                                        name="Reviewed Care Plan with Member"
                                        onChange={(e) => setIsReviewed(e.target.checked)}
                                    />
                                }
                            />
                        </Stack>
                    </div>
                    <div className="mui-modal-footer">
                        <Button
                            size="small"
                            type="button"
                            variant="contained"
                            onClick={() => handleOnClickSave()}
                            disabled={isSavingReviewHistory}
                            endIcon={isSavingReviewHistory ? <CircularProgress size={18} color="inherit" /> : null}
                        >
                            Save
                        </Button>
                        <Button size="small" type="button" onClick={handleCloseAlert} variant="outlined" disabled={isSavingReviewHistory}>
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
                <Grid container direction="column" spacing={2} className="content-panel">
                    {isFetching ? (
                        <Grid item className="content-form">
                            <Box display="flex" justifyContent="center" className="loader-container">
                                <CircularProgress />
                            </Box>
                        </Grid>
                    ) : (
                        <>
                            <Grid item className="content-form" sx={{ m: 3 }}>
                                <Stack direction="row" style={{ float: 'left' }}>
                                    <strong>Last Reviewed By : </strong>
                                    {Boolean(reviewDetail) ? (
                                        <Chip
                                            size="small"
                                            label={`${reviewDetail.LastReviewedBy?.Name} - ${moment(reviewDetail.LastReviewedOn).format('MM/DD/yyyy')}`}
                                        />
                                    ) : (
                                        ' - '
                                    )}
                                </Stack>
                                <Stack direction="row" justifyContent="flex-end">
                                    <Button variant="contained" className="text-transform-none" onClick={handleOnShowReviewAlert}>
                                        Reviewed Care Plan
                                    </Button>
                                </Stack>
                                <Stack spacing={2} marginY={2}>
                                    <Goal memberId={memberId} />
                                    <SchedulePlans memberId={memberId} />
                                    <Plans memberId={memberId} />
                                    <Barriers memberId={memberId} />
                                    <Strengths memberId={memberId} />
                                    <Metrics memberId={memberId} />
                                    <NextSteps memberId={memberId} />
                                </Stack>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Snackbar
                    open={error?.Show}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClose={() => dispatch(resetCarePlanError())}
                >
                    <Alert onClose={() => dispatch(resetCarePlanError())} severity="error" sx={{ width: '100%' }}>
                        {error?.Message}
                    </Alert>
                </Snackbar>
                {renderReviewAlert()}
            </>
        </LocalizationProvider>
    );
};

export default IntegratedCarePlan;
