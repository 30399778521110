import { Button, Chip, Dialog, DialogActions, DialogContent, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { apiString as API_URL } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, setShowMessage } from '../../store/shared.slice';
import GetAppIcon from '@mui/icons-material/GetApp';
import { RootState } from '../../reducers';
import MessageDisplay from '../Shared/MessageDisplay';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import b64toBlob from 'b64-to-blob';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { parentUrl } from '../../utils/constants';

const DataCorrections = (props) => {
    const history = useNavigate();
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const fetchRunDataCorrections = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/releasedatafixes`);
            console.log('fetchRunDataCorrections RESPONSE: ', response);
            // dispatch(setShowMessage(true, 'Successfully Updated', 'success'));
            dispatch(setShowMessage(true, response.data, 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const processHIE = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/processhie`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const uploadPatienPanel = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/uploadpatientpanel`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const processClaims = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/processclaims`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const downloadPatientPanelErrors = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/downloadpatientpanelerrors`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const uploadHL7Handler = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/generatehl7`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const downloadCovidAndLabs = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/downloadcovidandlabs`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const checkActiveEligibilityStatus = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/CheckActiveEligibilityStatus`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const checkActiveUndeterminedEligibilityStatus = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/CheckActiveUndeterminedEligibilityStatus`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const processEligibilityStatus = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/ProcessEligibilityStatus`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const uploadIcd10 = () => {
        history(`/admin/datacorrections/icd10`);
    };

    const fetchTCErrorsReport = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/FetchTCErrorsReport`);
            console.log('fetchTCErrorsReport RESPONSE: ', response);
            if (response.status === 200) {
                const data = await response.data;
                const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                const today = new Date();
                const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
                const fileName =
                    (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
                    '.' +
                    today.getDate() +
                    '.' +
                    today.getFullYear() +
                    '-' +
                    randomNumber;
                document.body.appendChild(a);
                a.href = blobUrl;
                a.download = fileName + '.xlsx';
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                dispatch(setShowMessage(true, 'Success', 'success'));
                dispatch(setIsLoading(false));
            }
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const fetchFixTCFlagErrors = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/datamanipulation/FixTCFlagErrors`);
            console.log('fetchFixTCFlagErrors RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            setOpen(false);
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            setOpen(false);
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const uploadAllergyClasses = async (e) => {
        dispatch(setIsLoading(true));
        try {
            let formData = new FormData();
            let file = e.target.files[0];
            formData.append('File', file);

            const response = await axios.put(`${API_URL}/allergies/UploadAllergyClasses`, formData, {
                headers: {},
            });
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const uploadMedicationRxNorm = async (e) => {
        dispatch(setIsLoading(true));
        try {
            let formData = new FormData();
            let file = e.target.files[0];
            formData.append('File', file);

            const response = await axios.put(`${API_URL}/allergies/UploadMedicationsAndRxNorm`, formData, {
                headers: {},
            });
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    // Veradigm Testing below

    const [veradigmUpdates, setVeradigmUpdates] = useState([]);
    const [viewModal, setViewModal] = useState(false);

    const getVeradigmUpdates = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.post(`${API_URL}/integrations/sync-veradigm-medications`);
            console.log('RESPONSE: ', response);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const viewVeradigmAllergyUpdates = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/allergies/GetMembersWithAllergiesFromVeradigm`);
            console.log('RESPONSE: ', response);
            setVeradigmUpdates(response.data);
            setViewModal(true);
            dispatch(setShowMessage(true, 'Success', 'success'));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setShowMessage(true, 'Error occurred', 'error'));
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const ViewVeradigmUpdatesDialog = () => {
        const columns: GridColDef[] = [
            {
                field: 'Name',
                headerName: 'Name',
                minWidth: 200,
                flex: 1,
                valueGetter(params) {
                    return `${params.row.FirstName} ${params.row.LastName}`;
                },
            },
        ];
        return (
            <Dialog open={viewModal} onClose={() => setViewModal(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Members who have new allergies downloaded from Veradigm
                    </Typography>
                    <div style={{ height: '50vh', width: '100%' }}>
                        <DataGrid
                            rows={veradigmUpdates}
                            columns={columns}
                            // pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.id}/member-profile`, '_blank');
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ minWidth: '120px' }} variant="outlined" onClick={() => setViewModal(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    // Veradigm Testing above

    const ConfirmationDialog = () => {
        return (
            <Dialog open={open} onClose={() => setOpen(false)}>
                {/* <DialogTitle>Confirmation</DialogTitle> */}
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Are you sure you want to fix Transional Care status errors for all members?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ minWidth: '120px' }} variant="outlined" onClick={() => setOpen(false)}>
                        No
                    </Button>
                    <Button sx={{ minWidth: '120px' }} variant="contained" onClick={fetchFixTCFlagErrors}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ViewVeradigmUpdatesDialog />
            <ConfirmationDialog />
            <MessageDisplay />
            {isLoading && (
                <Typography marginBottom={1} color={'var(--success)'} variant="body1">
                    Loading...
                </Typography>
            )}
            <Grid container spacing={2}>
                <Grid item xs>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Data Corrections" />
                        </Divider>
                        <Typography marginBottom={1} color={'#333'} variant="body1">
                            All corrections are idempotent
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <Button disabled={isLoading} variant="contained" onClick={fetchRunDataCorrections}>
                                Run Data Corrections
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Transitional Care" />
                        </Divider>
                        <Stack direction="column" spacing={2}>
                            <Button disabled={isLoading} variant="contained" onClick={fetchTCErrorsReport}>
                                <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                                Download TC Status Errors Report
                            </Button>
                            <Button disabled={isLoading} variant="contained" color="error" onClick={() => setOpen(true)}>
                                Fix Transitional Care Status Errors
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="HIE" />
                        </Divider>
                        <Stack direction="column" spacing={2}>
                            <Button disabled={isLoading} variant="contained" onClick={downloadPatientPanelErrors}>
                                Download Patient Panel Errors
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={processHIE}>
                                Process HIE ADT alerts Download
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={uploadHL7Handler}>
                                Upload HL7 to Contexture
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={uploadPatienPanel}>
                                Upload Patient Panel to Contexture
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={downloadCovidAndLabs}>
                                Download Covid Alerts
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Claims" />
                        </Divider>
                        <Stack direction="column" spacing={2}>
                            <Button disabled={isLoading} variant="contained" onClick={processClaims}>
                                Process Claims
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={uploadIcd10}>
                                Upload ICD10
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Waystar Eligibility" />
                        </Divider>
                        <Stack direction="column" spacing={2}>
                            <Button disabled={isLoading} variant="contained" onClick={checkActiveEligibilityStatus}>
                                Get Active Eligibility Status from Waystar
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={checkActiveUndeterminedEligibilityStatus}>
                                Get Active Undetermined Eligibility Status from Waystar
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={processEligibilityStatus}>
                                Process Waystar Eligibility Results
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Allergy Classes" />
                        </Divider>
                        <Stack spacing={2} alignItems={'center'}>
                            <form id={'upload-form'}>
                                <Button
                                    disabled={isLoading}
                                    size="small"
                                    color="success"
                                    variant="contained"
                                    id="upload-btn"
                                    component="label"
                                    sx={{ textAlign: 'center' }}
                                >
                                    Upload Allergy Classes
                                    <input
                                        id="file"
                                        name="File"
                                        onChange={(e) => {
                                            uploadAllergyClasses(e);
                                        }}
                                        type="file"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        hidden
                                    />
                                </Button>
                            </form>
                            <form id={'upload-rxnorm'}>
                                <Button
                                    disabled={isLoading}
                                    size="small"
                                    color="success"
                                    variant="contained"
                                    id="upload-rxnorm-btn"
                                    component="label"
                                    sx={{ textAlign: 'center' }}
                                >
                                    Upload Medication RxNorm
                                    <input
                                        id="file"
                                        name="File"
                                        onChange={(e) => {
                                            uploadMedicationRxNorm(e);
                                        }}
                                        type="file"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        hidden
                                    />
                                </Button>
                            </form>
                            <Button disabled={isLoading} variant="contained" onClick={getVeradigmUpdates}>
                                Get Veradigm Updates
                            </Button>
                            <Button disabled={isLoading} variant="contained" onClick={viewVeradigmAllergyUpdates}>
                                View Veradigm Allergy Updates
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default DataCorrections;
