import { Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { apiString as API_URL } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import phoneFormat from '../../../../utils/phoneFormat';
import WidgetLoading from '../../Shared/WidgetLoading';
import { isDefaultDate } from '../../../../utils/common';

const ReschedulingOpportunities = (props) => {
    const [reschedulingOpportunities, setReschedulingOpportunities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getReschedulingOpportunities = async () => {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/onboarding/Rescheduling`);
        setReschedulingOpportunities(response.data);
        setIsLoading(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'AHCCCS_ID',
            headerName: 'AHCCCS ID',
            minWidth: 140,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 140,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 140,
        },
        {
            field: 'DateOfBirth',
            headerName: 'Date Of Birth',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DateOfBirth) ? '-' : localDateFormat(new Date(params.row.DateOfBirth))}`,
        },
        {
            field: 'Address1',
            headerName: 'Address1',
            minWidth: 140,
        },
        {
            field: 'Address2',
            headerName: 'Address2',
            minWidth: 140,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 140,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 80,
        },
        {
            field: 'ZipCode',
            headerName: 'Zip Code',
            minWidth: 140,
        },
        {
            field: 'PhoneNumbers',
            headerName: 'Phone Numbers',
            minWidth: 140,
            renderCell: (params) => {
                return (
                    <>
                        <Stack>
                            {params.row.PhoneNumbers?.map((item, key) => {
                                return (
                                    <Link sx={{ p: 1 }} underline="none" key={key} href={'tel:' + item}>
                                        {phoneFormat(item)}
                                    </Link>
                                );
                            })}
                        </Stack>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getReschedulingOpportunities();
    }, []);

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'}>
                        Rescheduling Opportunities
                    </Typography>
                </Grid>

                <Grid item xs={12} marginTop={2} position="relative">
                    {isLoading && <WidgetLoading />}
                    <div style={{ width: '100%', height: '60vh' }}>
                        <DataGrid
                            rows={reschedulingOpportunities}
                            columns={columns}
                            pageSize={15}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => Math.random()}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                height: '59vh',
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default ReschedulingOpportunities;
