import axios from 'axios';
import { TimePeriodFilterEnum } from '../../Enum/TimePeriodFilterEnum';
import { WidgetTimeFilterEnum } from '../../Enum/WidgetTimeFilterEnum';
import { apiString as api } from '../../utils/constants';
import { RequestDayFilterEnum } from '../../Enum/RequestDayFilterEnum';
import { MemberStatusEnum } from '../../Enum/MemberStatusEnum';
import { FacilityTypeEnum } from '../../Enum/FacilityTypeEnum';

export const selectedDayFilterList = function () {
    let dayList = [];
    dayList.push({ Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription });
    return dayList;
};

export const dayFilterList = function () {
    let dayList = [];
    dayList.push({ Id: WidgetTimeFilterEnum.Last3Days, Value: WidgetTimeFilterEnum.Last3DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last5Days, Value: WidgetTimeFilterEnum.Last5DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last7Days, Value: WidgetTimeFilterEnum.Last7DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last10Days, Value: WidgetTimeFilterEnum.Last10DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last15Days, Value: WidgetTimeFilterEnum.Last15DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription });
    dayList.push({ Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription });
    return dayList;
};

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 50 * 4.5,
            width: 250,
        },
    },
};

export const fetchCptCodeGroups = async () => {
    const response = await axios.get(`${api}/encounter/getcptcodegroupspreview`);
    return response.data;
};

export const fetchRequestTypes = async () => {
    const response = await axios.get(`${api}/request/getrequesttype`);
    return response.data;
};

export const fetchSurveyChart = async (providerId: string, dateFilter: TimePeriodFilterEnum, dateFrom: string, dateTo: string) => {
    const response = await axios.get(`${api}/widget/getsurveychart?providerId=${providerId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response.data;
};

export const fetchEncounterSummary = async (
    providerId: string,
    teamId: string,
    encounterType: string,
    healthPlanId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getencounterchart?providerId=${providerId}&teamId=${teamId}&encounterType=${encounterType}&healthPlanId=${healthPlanId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const fetchMembersWithoutEncounter = async (
    teamId: string,
    healthPlanId: string,
    populationId: number,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/widget/memberswithoutencounter?teamId=${teamId}&healthPlanId=${healthPlanId}&populationId=${populationId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const fetchCleanClaimsChart = async (
    providerId: string,
    teamId: string,
    healthPlanId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getcleanclaimschart?providerId=${providerId}&teamId=${teamId}&healthPlanId=${healthPlanId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const fetchSubmittedClaimsChart = async (
    providerId: string,
    teamId: string,
    healthPlanId: string,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getsubmittedclaimschart?providerId=${providerId}&teamId=${teamId}&healthPlanId=${healthPlanId}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const fetchRevenueMetrics = async (healthPlanId: string, populationId: number, geography: string, defaultDate: string, comparisonDate: string) => {
    const response = await axios.get(
        `${api}/widget/revenuemetrics?healthPlanId=${healthPlanId}&populationId=${populationId}&geography=${geography}&defaultDate=${defaultDate}&comparisonDate=${comparisonDate}`
    );
    return response.data;
};
export const fetchRevenueMetricsComparisonCount = async (
    healthPlanId: string,
    populationId: number,
    geography: string,
    comparisonDate: string,
    status: MemberStatusEnum
) => {
    const response = await axios.get(
        `${api}/widget/revenuemetricscomparisoncount?healthPlanId=${healthPlanId}&populationId=${populationId}&geography=${geography}&comparisonDate=${comparisonDate}&status=${status}`
    );
    return response.data;
};
export const fetchTeamInPersonVisitsSummaryChart = async (
    healthPlanId: string,
    populationId: number,
    providerId: string,
    teamId: string,
    timeFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean,
    prepareMembers: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getteaminpersonvisitssummary?healthPlanId=${healthPlanId}&populationId=${populationId}&providerId=${providerId}&teamId=${teamId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}&prepareMembers=${prepareMembers}`
    );
    return response.data;
};
export const fetchEngagementSummaryChart = async (
    providerId: string,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeMembers: boolean,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getengagementsummary?providerId=${providerId}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeMembers=${includeMembers}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const fetchSchedulingSummary = async (
    timeFilter: WidgetTimeFilterEnum,
    healthPlanId: string,
    populationId: number,
    providerId: string,
    teamId: string,
    schedulingStaff: string,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean,
    includeInActiveProviders?: boolean,
    includeInActiveStaffs?: boolean,
    prepareMembers?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getschedulingsummary?timeFilter=${timeFilter}&healthPlanId=${healthPlanId}&populationId=${populationId}&providerId=${providerId}&teamId=${teamId}&schedulingStaff=${schedulingStaff}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}&includeInActiveStaffs=${includeInActiveStaffs}&prepareMembers=${prepareMembers}`
    );
    return response.data;
};
export const fetchDocumentTrackingSummary = async (
    providerId: string,
    teamId: string,
    timeFilter: TimePeriodFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean,
    includeInActiveProviders?: boolean,
    prepareMembers?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getdocumenttrackingsummary?providerId=${providerId}&teamId=${teamId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}&prepareMembers=${prepareMembers}`
    );
    return response.data;
};
export const fetchInPersonVisitSummary = async (
    teamId: string,
    providerId: string,
    year: number,
    month: number,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getinpersonvisitsummary?teamId=${teamId}&providerId=${providerId}&year=${year}&month=${month}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const fetchSchedulerSummary = async (providerId: string, timeFilter: WidgetTimeFilterEnum, dateFrom?: string, dateTo?: string) => {
    const response = await axios.get(
        `${api}/widget/getschedulersummary?providerId=${providerId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
    return response.data;
};
export const fetchTeamMemberSummary = async (
    healthPlanId: string,
    populationId: number,
    teamId: string,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getteammembersummary?healthPlanId=${healthPlanId}&populationId=${populationId}&teamId=${teamId}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const fetchSocialWorkerRequestSummary = async (
    providerId: string,
    dayFilter: RequestDayFilterEnum,
    dateFilter: TimePeriodFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveProviders?: boolean,
    prepareMembers?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getsocialworkerrequestsummary?providerId=${providerId}&dayFilter=${dayFilter}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}&prepareMembers=${prepareMembers}`
    );
    return response.data;
};
export const fetchCrossDisciplineCare = async (
    teamId: string,
    dateFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    showInActiveTeams?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getcrossdisciplinecare?teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${showInActiveTeams}`
    );
    return response.data;
};
export const fetchGoalSummaryChart = async (teamId: string, showInActiveTeams: boolean) => {
    const response = await axios.get(`${api}/widget/getgoalnotmetmembers?teamId=${teamId}&includeInActiveTeams=${showInActiveTeams}`);
    return response.data;
};
export const fetchCarePlanNotReviewedMembersChart = async (teamId: string, showInActiveTeams: boolean) => {
    const response = await axios.get(`${api}/widget/getcareplannotreviewedmembers?teamId=${teamId}&includeInActiveTeams=${showInActiveTeams}`);
    return response.data;
};
export const exportDocumentTrackingSummary = async (
    providerId: string,
    teamId: string,
    timeFilter: TimePeriodFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportdocumenttrackingmembers?providerId=${providerId}&teamId=${teamId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const exportTeamInPersonVisitMembers = async (
    healthPlanId: string,
    populationId: number,
    providerId: string,
    teamId: string,
    timeFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveTeams: boolean,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportteaminpersonvisitmembers?healthPlanId=${healthPlanId}&populationId=${populationId}&providerId=${providerId}&teamId=${teamId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const exportSchedulingSummary = async (
    timeFilter: WidgetTimeFilterEnum,
    healthPlanId: string,
    populationId: number,
    providerId: string,
    teamId: string,
    schedulingStaff: string,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean,
    includeInActiveProviders?: boolean,
    includeInActiveStaffs?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportschedulingsummary?timeFilter=${timeFilter}&healthPlanId=${healthPlanId}&populationId=${populationId}&providerId=${providerId}&teamId=${teamId}&schedulingStaff=${schedulingStaff}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}&includeInActiveStaffs=${includeInActiveStaffs}`
    );
    return response.data;
};

export const exportEngagementSummary = async (
    providerId: string,
    teamId: string,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportEngagementSummary?providerId=${providerId}&teamId=${teamId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};

export const exportMembersWithoutEncounter = async (
    teamId: string,
    healthPlanId: string,
    populationId: number,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportmemberswithoutencounter?teamId=${teamId}&healthPlanId=${healthPlanId}&populationId=${populationId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const exportSocialWorkerRequestSummary = async (
    providerId: string,
    dayFilter: RequestDayFilterEnum,
    dateFilter: TimePeriodFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportsocialworkerrequestsummary?providerId=${providerId}&dayFilter=${dayFilter}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
export const exportTeamMemberSummary = async (
    healthPlanId: string,
    populationId: number,
    teamId: string,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportteammembersummary?healthPlanId=${healthPlanId}&populationId=${populationId}&teamId=${teamId}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const fetchFuhFumUndeterminedSummary = async (
    provider: string,
    meansOfAttempt: string,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/fuhfum/fuhfumundeterminedwidget?provider=${provider}&meansOfAttempt=${meansOfAttempt}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};

export const exportFuhFumUndeterminedSummary = async (
    provider: string,
    meansOfAttempt: string,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/fuhfum/exportfuhfumundeterminedmembers?provider=${provider}&meansOfAttempt=${meansOfAttempt}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};

export const fetchFuhFumInProgressSummary = async (
    provider: string,
    daysSinceDischarge: string,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/fuhfum/fuhfuminprogresswidget?provider=${provider}&daysSinceDischarge=${daysSinceDischarge}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};

export const exportFuhFumInProgressSummary = async (
    provider: string,
    daysSinceDischarge: string,
    timeFilter: WidgetTimeFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveProviders?: boolean
) => {
    const response = await axios.get(
        `${api}/fuhfum/exportfuhfuminprogressmembers?provider=${provider}&daysSinceDischarge=${daysSinceDischarge}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};

export const fetchFuhFumClosedSummary = async (selectedFilter: string, timeFilter: TimePeriodFilterEnum, dateFrom?: string, dateTo?: string) => {
    const response = await axios.get(
        `${api}/fuhfum/fuhfumclosedwidget?fuhFumQualityCheck=${selectedFilter}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
    return response.data;
};

export const exportFuhFumClosedSummary = async (selectedFilter: string, timeFilter: TimePeriodFilterEnum, dateFrom?: string, dateTo?: string) => {
    const response = await axios.get(
        `${api}/fuhfum/exportfuhfumclosedmembers?fuhFumQualityCheck=${selectedFilter}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
    return response.data;
};

export const fetchFuhFumExceptionSummary = async () => {
    const respone = await axios.get(`${api}/fuhfum/fuhfumexceptionwidget`);
    return respone.data;
};

export const exportFuhFumExceptionSummary = async () => {
    const response = await axios.get(`${api}/fuhfum/exportfuhfumexceptionmembers`);
    return response.data;
};

export const fetchFuhFumQualityMetrics = async (timeFilter: WidgetTimeFilterEnum, dateFrom?: string, dateTo?: string) => {
    const response = await axios.get(`${api}/fuhfum/fuhfumqualitymetrics?timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response.data;
};

export const exportFuhFumQualityMetrics = async (timeFilter: WidgetTimeFilterEnum, dateFrom?: string, dateTo?: string) => {
    const response = await axios.get(`${api}/fuhfum/exportfuhfumqualitymetrics?timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response.data;
};
export const fetchTeamLevelTransitionalCareSummary = async (
    teamId: string,
    facility: FacilityTypeEnum,
    timeFilter: TimePeriodFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean,
    prepareMembers?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getteamleveltransitionalcaresummary?teamId=${teamId}&facility=${facility}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}&prepareMembers=${prepareMembers}`
    );
    return response.data;
};
export const exportTeamLevelTransitionalCareSummary = async (
    teamId: string,
    facility: FacilityTypeEnum,
    timeFilter: TimePeriodFilterEnum,
    dateFrom?: string,
    dateTo?: string,
    includeInActiveTeams?: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportteamleveltransitionalcaresummary?teamId=${teamId}&facility=${facility}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const fetchIndividualInPersonVisitsSummaryChart = async (
    providerId: string,
    timeFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveProviders: boolean,
    prepareMembers: boolean
) => {
    const response = await axios.get(
        `${api}/widget/getindividualinpersonvisitssummary?providerId=${providerId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}&prepareMembers=${prepareMembers}`
    );
    return response.data;
};
export const exportIndividualInPersonVisitMembers = async (
    providerId: string,
    timeFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includeInActiveProviders: boolean
) => {
    const response = await axios.get(
        `${api}/widget/exportindividualinpersonvisitmembers?providerId=${providerId}&timeFilter=${timeFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};

export const fetchFuhFumFullProgramReferralSummary = async (memberName: string, provider: string, includeInActiveProviders?: boolean) => {
    const response = await axios.get(
        `${api}/fuhfum/fuhfumfullprogramreferralwidget?memberName=${memberName}&provider=${provider}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};
