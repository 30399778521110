import { Box, Button, FormControl, Grid, InputLabel, Link, ListSubheader, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridColumnVisibilityModel, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { RootState } from '../../reducers';
import {
    fetchClaimsData,
    fetchClaimsByClaimNumber,
    fetchClaimResponses,
    setClaimNumberSearchValue,
    setStatusValue,
    setDosStart,
    setDosEnd,
    setProviderClaimsSelectedProviderId,
} from '../../store/providerClaims.slice';
import Loading from '../Shared/Loading';
import { parentUrl } from '../../utils/constants';
import MessageDisplay from '../Shared/MessageDisplay';
import Authorization from '../../utils/Authorization';
import ClaimResubmission from './ClaimResubmission';
import { fetchClaimResubmitData, setShowModal } from '../../store/claimResubmission.slice';
import ProcedureSignOffEncounter from '../Assessments/ProcedureSignOff/ProcedureSignOffEncounter';
import { fetchEncountersByDate, setEncounterChange, setShowProcedureSignOffModal } from '../../store/procedureSignOffEncounter.slice';
import { getStatuses } from '../../store/claimsManagement.slice';

const ProviderClaims = () => {
    const authData = new Authorization();
    const { claimsData, claimNumberSearchValue, statusValue, dosStart, dosEnd, selectedProviderId } = useSelector((state: RootState) => state.providerClaims);
    const { encounterChange } = useSelector((state: RootState) => state.procedureSignOffEncounter);
    const { encounterClaimProviders } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();
    const [claimResponseButtonDisplay, setClaimResponseButtonDisplay] = useState(false);
    const [localProviders, setLocalProviders] = useState([]);

    const [loadingMessage, setLoadingMessage] = useState('Loading...');

    const diagnosisFormatHandler = (params: GridRenderCellParams<any>) => {
        return params.value ? (
            <Stack>
                <Typography>{params.value.Code} -</Typography>
                <Typography color={'#999'}>{params.value.Name}</Typography>
            </Stack>
        ) : (
            <Typography></Typography>
        );
    };

    const showResubmit = (claimNumber: string, memberId: string, dateOfService: string) => {
        dispatch(
            fetchClaimResubmitData(claimNumber, memberId, dateOfService, () => {
                dispatch(setShowModal(true));
            })
        );
    };

    const showProcedureSignOffEncounter = (status: string, memberId: string, dateOfService: string) => {
        dispatch(
            fetchEncountersByDate(status, memberId, dateOfService, () => {
                dispatch(setShowProcedureSignOffModal(true));
            })
        );
    };

    const columns: GridColDef[] = [
        {
            field: 'Edit',
            headerName: 'Edit',
            minWidth: 130,
            flex: 0.75,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Button
                        className="button-120"
                        variant="contained"
                        onClick={() => {
                            showProcedureSignOffEncounter(params.row.Status, params.row.MemberId, params.row.DateOfService);
                        }}
                    >
                        Edit
                    </Button>
                );
            },
        },
        {
            field: 'Resubmit',
            headerName: 'Resubmit',
            minWidth: 130,
            flex: 0.75,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Button
                        className="button-120"
                        variant="contained"
                        disabled={params.row.EncounterStatusValue !== 'Rejected'}
                        onClick={() => {
                            showResubmit(params.row.TraceId, params.row.MemberId, params.row.DateOfService);
                        }}
                    >
                        Resubmit
                    </Button>
                );
            },
        },
        {
            field: 'DateProcessed',
            headerName: 'Date Processed',
            flex: 0.75,
        },
        {
            field: 'TraceId',
            headerName: 'Claim Number',
            flex: 1.35,
        },
        {
            field: 'EncounterStatusValue',
            headerName: 'Status',
            flex: 0.75,
        },
        {
            field: 'Message',
            headerName: 'Last Message',
            flex: 1.25,
        },
        {
            field: 'DateOfService',
            headerName: 'DOS',
            flex: 0.75,
        },
        {
            field: 'AHCCCSId',
            headerName: 'AHCCCS ID',
            flex: 0.75,
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Link
                        target={'_blank'}
                        underline="hover"
                        href={`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/document-engagement`}
                        color={'#337ab7'}
                    >
                        {params.value}
                    </Link>
                );
            },
        },
        {
            field: 'PrimaryHealthPlan',
            headerName: 'Primary Health Plan',
            flex: 1,
        },
        {
            field: 'CreatedBy',
            headerName: 'Creating Provider',
            flex: 1,
        },
        {
            field: 'CreatedDate',
            headerName: 'Created Date',
            type: 'dateTime',
            flex: 1,
        },
        {
            field: 'ApprovedBy',
            headerName: 'Submitting Provider',
            flex: 1,
        },
        {
            field: 'ApprovedDate',
            headerName: 'Submitted Date',
            type: 'dateTime',
            flex: 1,
        },
        {
            field: 'CPTCode',
            headerName: 'CPT Code',
            flex: 0.75,
        },
        {
            field: 'PrimaryDiagnosis',
            headerName: 'Primary DX',
            flex: 1,
            renderCell: diagnosisFormatHandler,
        },
        {
            field: 'SecondaryDiagnosis',
            headerName: 'Secondary DX',
            flex: 1,
            renderCell: diagnosisFormatHandler,
        },
        {
            field: 'TertiaryDiagnosis',
            headerName: 'Tertiary DX',
            flex: 1,
            renderCell: diagnosisFormatHandler,
        },
        {
            field: 'QuaternaryDiagnosis',
            headerName: 'Quaternary DX',
            flex: 0.75,
            renderCell: diagnosisFormatHandler,
        },
        {
            field: 'CHPErrorMessage',
            headerName: 'CHP Error Message',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <div style={{ maxWidth: '100%' }}>
                        <Typography sx={{ color: 'error.main' }}>{params.value}</Typography>
                    </div>
                );
            },
        },
        {
            field: 'CHPErrorMessageDate',
            headerName: 'CHP Error Date',
            type: 'dateTime',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <div style={{ maxWidth: '100%' }}>
                        <Typography sx={{ color: 'error.main' }}>{params.value}</Typography>
                    </div>
                );
            },
        },
    ];

    const forReviewColumnModel = {
        Edit: true,
        Message: false,
        DateProcessed: false,
        TraceId: false,
        ApprovedBy: false,
        ApprovedDate: false,
        EncounterId: false,
        Resubmit: false,
        CHPErrorMessage: false,
        CHPErrorMessageDate: false,
    };

    const toSubmitColumnModel = {
        ...forReviewColumnModel,
        EncounterId: true,
        TraceId: false,
        CreatedBy: false,
        CreatedDate: false,
        ApprovedBy: true,
        ApprovedDate: true,
        CHPErrorMessage: true,
        CHPErrorMessageDate: true,
    };

    const submitColumnModel = {
        ...toSubmitColumnModel,
        TraceId: true,
        Edit: false,
    };

    const acceptedColumnModel = {
        EncounterId: false,
        TraceId: true,
        CreatedBy: true,
        CreatedDate: false,
        PrimaryDiagnosis: false,
        SecondaryDiagnosis: false,
        TertiaryDiagnosis: false,
        QuaternaryDiagnosis: false,
        ApprovedBy: false,
        ApprovedDate: false,
        Resubmit: false,
        Edit: false,
        CHPErrorMessage: false,
        CHPErrorMessageDate: false,
    };

    const rejectedColumnModel = {
        ...acceptedColumnModel,
        EncounterId: true,
        Resubmit: true,
        Edit: false,
    };

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(forReviewColumnModel);

    const changeHeaders = (paramStatus: number = null) => {
        if (!paramStatus) {
            paramStatus = statusValue;
        }
        setClaimResponseButtonDisplay(false);

        if (paramStatus === 1) {
            setColumnVisibilityModel(forReviewColumnModel);
        } else if (paramStatus === 2) {
            setColumnVisibilityModel(toSubmitColumnModel);
        } else if (paramStatus === 4) {
            setColumnVisibilityModel(submitColumnModel);
            setClaimResponseButtonDisplay(false);
        } else if (paramStatus === 5) {
            setColumnVisibilityModel(acceptedColumnModel);
        } else if (paramStatus === 6) {
            setColumnVisibilityModel(rejectedColumnModel);
        }
    };

    const searchByClaimNumber = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            setLoadingMessage('Loading...');
            dispatch(fetchClaimsByClaimNumber(selectedProviderId, claimNumberSearchValue));
        }
    };

    const getClaimResponseHandler = () => {
        setLoadingMessage('Downloading Response Files...');
        dispatch(fetchClaimResponses(searchClaims));
    };

    const changeProvider = (providerId: string) => {
        dispatch(setProviderClaimsSelectedProviderId(providerId));
    };

    const searchClaims = () => {
        if (claimNumberSearchValue) {
            searchByClaimNumber({});
        } else {
            setLoadingMessage('Loading...');
            dispatch(fetchClaimsData(selectedProviderId, statusValue, dosStart, dosEnd));
            changeHeaders();
        }
    };

    useEffect(() => {
        if (authData.isInPermission('View Claims Management')) {
            setLocalProviders(encounterClaimProviders);
        } else {
            setLocalProviders(encounterClaimProviders.filter((x) => x.Id === authData.UserId));
            dispatch(setProviderClaimsSelectedProviderId(authData.UserId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encounterClaimProviders]);

    useEffect(() => {
        changeHeaders(statusValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, statusValue]);

    useEffect(() => {
        if (encounterChange) {
            searchClaims();
            dispatch(setEncounterChange(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, encounterChange]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MessageDisplay />
            <ClaimResubmission />
            <ProcedureSignOffEncounter />
            <Box>
                {<Loading message={loadingMessage} />}
                {/* <Grid
                    container
                    justifyContent={'flex-end'}
                    spacing={3}
                    style={{ padding: '16px 16px 16px 16px' }}
                > */}
                <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                    <Grid item xs={2} textAlign={'left'}>
                        <Typography variant="h5">{statusValue > 2 ? 'Claims' : 'Member Encounters'}</Typography>
                        <Typography variant="h6" className={statusValue > 2 ? 'colorsuccess' : 'colorerror'}>
                            {statusValue > 2 ? 'Exist in Waystar' : 'Not in Waystar'}
                        </Typography>
                        <br />
                        <Stack direction="column" alignItems={'left'} justifyContent={'flex-end'} spacing={3}>
                            {claimResponseButtonDisplay && (
                                <Button
                                    onClick={getClaimResponseHandler}
                                    style={{
                                        minWidth: '120px!important',
                                        marginRight: 'auto',
                                    }}
                                    variant="contained"
                                >
                                    Get Claim Responses
                                </Button>
                            )}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Provider"
                                    disabled={!authData.isInPermission('View Claims Management')}
                                    value={selectedProviderId}
                                    onChange={(e) => changeProvider(e.target.value)}
                                >
                                    <MenuItem key={''} value={''}>
                                        {`Select A Provider`}
                                    </MenuItem>
                                    {localProviders.map(function (provider) {
                                        return (
                                            <MenuItem key={provider.Id} value={provider.Id}>
                                                {`${provider.LastName}, ${provider.FirstName}`}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {statusValue > 2 ? (
                                <>
                                    <FormControl>
                                        <InputLabel htmlFor="outlined-adornment-password">Search By Claim Number</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type="text"
                                            value={claimNumberSearchValue}
                                            onChange={(e) => {
                                                dispatch(setClaimNumberSearchValue(e.target.value as string));
                                            }}
                                            onKeyUp={(event: any) => {
                                                if (event.key === 'Enter') {
                                                    searchByClaimNumber(event);
                                                }
                                            }}
                                            label="Search By Claim Number"
                                        />
                                    </FormControl>
                                    <Typography>OR</Typography>
                                </>
                            ) : null}
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={statusValue}
                                    label="Status"
                                    onChange={(e) => {
                                        dispatch(setClaimNumberSearchValue(''));
                                        dispatch(setStatusValue(e.target.value as number));
                                    }}
                                >
                                    {getStatuses().map((statusByType, index) => [
                                        <ListSubheader key={index}>{statusByType.Name}</ListSubheader>,
                                        statusByType.List.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value} sx={{ paddingLeft: 4 }}>
                                                    {status.label}
                                                </MenuItem>
                                            );
                                        }),
                                    ])}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <DesktopDatePicker
                                    label="DOS Start Date:"
                                    value={dosStart}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        setClaimNumberSearchValue('');
                                        dispatch(setDosStart(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <FormControl>
                                <DesktopDatePicker
                                    label="DOS End Date:"
                                    value={dosEnd}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        setClaimNumberSearchValue('');
                                        dispatch(setDosEnd(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <Button onClick={searchClaims} style={{ minWidth: '120px!important' }} variant="contained">
                                Search
                            </Button>
                            {/* <Button
                                onClick={() => {
                                    dispatch(downloadClaimsHandler(claimsData));
                                }}
                                style={{ minWidth: '120px!important' }}
                                variant="contained"
                            >
                                Download
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <div style={{ height: '88vh', width: '100%' }}>
                            <DataGrid
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                                rows={claimsData}
                                columns={columns}
                                pageSize={15}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.EncounterId}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root': {
                                        // display: 'none',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default ProviderClaims;
