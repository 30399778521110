import { CarePlanStatusEnum } from '../../Enum/CarePlanStatusEnum';
import BaseEntity from '../BaseEntity.model';
import { Barrier } from './Barrier.model';
import { Goal } from './Goal.model';
import { Metric } from './Metric.model';
import { NextStep } from './NextStep.model';
import { Plan } from './Plan.model';
import { ReviewHistory } from './ReviewHistory.model';
import { SchedulePlan } from './SchedulePlan.model';
import { Strength } from './Strength.model';

export default interface IIntegratedCarePlan extends BaseEntity {
    MemberId: string;
    Goals: Goal[];
    Barriers: Barrier[];
    Plans: Plan[];
    Metrics: Metric[];
    NextSteps: NextStep[];
    Strengths: Strength[];
    SchedulePlans: SchedulePlan[];
    Status: CarePlanStatusEnum;
    CreatedBy: string;
    CreationTime: string;
    ApprovedBy: string;
    ApprovedTime: string;
    RejectedBy: string;
    RejectedTime: string;
    ReasonForRejection: string;
    ReviewHistory: ReviewHistory[];
}

export class IntegratedCarePlan implements IIntegratedCarePlan {
    Id: string | null;
    MemberId: string;
    Goals: Goal[];
    Barriers: Barrier[];
    Plans: Plan[];
    Metrics: Metric[];
    NextSteps: NextStep[];
    Strengths: Strength[];
    SchedulePlans: SchedulePlan[];
    Status: CarePlanStatusEnum;
    CreatedBy: string;
    CreationTime: string;
    ApprovedBy: string;
    ApprovedTime: string;
    RejectedBy: string;
    RejectedTime: string;
    ReasonForRejection: string;
    ReviewHistory: ReviewHistory[];

    constructor() {
        this.Id = null;
        this.MemberId = '';
        this.Goals = [] as Goal[];
        this.Barriers = [] as Barrier[];
        this.Plans = [] as Plan[];
        this.Metrics = [] as Metric[];
        this.NextSteps = [] as NextStep[];
        this.Strengths = [] as Strength[];
        this.SchedulePlans = [] as SchedulePlan[];
        this.ReviewHistory = null;
    }
}
