import { AppBar, Divider, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme, useMediaQuery } from '@mui/material';
import logo from '../../../assets/images/Chp_Logo.png';
import GroupsIcon from '@mui/icons-material/Groups';
import PhoneIcon from '@mui/icons-material/Phone';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DescriptionIcon from '@mui/icons-material/Description';
import Authorization from '../../../utils/Authorization';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import LogoutIcon from '@mui/icons-material/Logout';

import NotificationsComponents from './NotificationsComponents/NotificationsComponents';
import { logout } from '../../../store/authentication.slice';
import HamburgerMenu from './HamburgerMenu';

const ProviderNavbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const path = location.pathname.split('/')[2];

    const [authData, setAuthData] = useState(new Authorization());

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        setIsMobileView(isMobile);
    }, [isMobile]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/', { replace: true });
        handleClose();
    };

    useEffect(() => {
        setAuthData(new Authorization());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {location.pathname.indexOf('provider') > -1 && authData.UserId?.length > 0 ? (
                <header>
                    <AppBar id="provider-navbar" color="default" position="sticky" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Toolbar>
                            <img src={logo} alt="logo" style={{ width: isMobileView ? '130px' : 'auto' }} />
                            {isMobileView ? null : (
                                <Tabs
                                    sx={{
                                        marginX: 'auto',
                                        [`& .MuiTab-root`]: { fontSize: '12px', ':hover': { textDecoration: 'none' } },
                                        [`& .MuiTab-root.Mui-selected`]: { color: '#1976d2', background: 'rgba(25, 118, 210, 0.07)' },
                                    }}
                                    textColor="inherit"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    value={path}
                                    indicatorColor="primary"
                                    TabIndicatorProps={{ style: { height: '5px' } }}
                                >
                                    {<Tab label="Dashboard" icon={<DashboardIcon />} value={'metricsummary'} component={NavLink} to="metricsummary" />}
                                    {authData.isInPermission('View members') && (
                                        <Tab label="Members" icon={<GroupsIcon />} component={NavLink} value={'members-list'} to="members-list" />
                                    )}
                                    {authData.isInPermission('View the requests') && (
                                        <Tab label="Requests" icon={<FileOpenIcon />} component={NavLink} value={'requests'} to="requests" />
                                    )}
                                    {authData.isInPermission('View onboarding') && (
                                        <Tab
                                            label="Onboarding"
                                            icon={<PhoneIcon />}
                                            component={NavLink}
                                            value={'onboarding-workflow'}
                                            to="onboarding-workflow"
                                        />
                                    )}
                                    {authData.isInPermission('View my schedule') && (
                                        <Tab label="My Schedule" icon={<CalendarMonthIcon />} component={NavLink} value={'myschedule'} to="myschedule" />
                                    )}
                                    {authData.isInPermission('View all appointment') && (
                                        <Tab label="Schedules" icon={<CalendarMonthIcon />} component={NavLink} value={'schedules'} to="schedules" />
                                    )}
                                    {authData.isInPermission('View Reports') && (
                                        <Tab label="Reports" icon={<DescriptionIcon />} component={NavLink} value={'reports'} to="reports" />
                                    )}
                                    {authData.isInPermission('View HIE') && (
                                        <Tab label="HIE" icon={<LocalHospitalIcon />} component={NavLink} value={'hie'} to="hie" />
                                    )}
                                    {authData.isInPermission('View Scheduling') && (
                                        <Tab
                                            label="Office Scheduling"
                                            icon={<CalendarMonthIcon />}
                                            component={NavLink}
                                            value={'officescheduling'}
                                            to="officescheduling"
                                        />
                                    )}
                                    {authData.isInPermission('View Claims Page') && (
                                        <Tab
                                            label="Claims"
                                            icon={<MedicalInformationIcon />}
                                            component={NavLink}
                                            value={'claims-management'}
                                            to="claims-management"
                                        />
                                    )}
                                </Tabs>
                            )}

                            {authData.UserType === 'Provider' && !isMobileView ? (
                                <NotificationsComponents />
                            ) : authData.UserType === 'Provider' && isMobileView ? (
                                <Stack flex={1} direction="row" alignItems="center" spacing={2}>
                                    <HamburgerMenu />
                                    <Typography variant="body2" sx={{ borderRadius: 0 }}>
                                        {authData.UserName}
                                    </Typography>
                                </Stack>
                            ) : null}
                            {authData.UserType === 'Provider' && isMobileView ? (
                                <IconButton onClick={logoutHandler} sx={{ borderRadius: 0, background: 'white' }}>
                                    <LogoutIcon />
                                </IconButton>
                            ) : (
                                <>
                                    <IconButton onClick={handleClick} sx={{ marginLeft: 2, borderRadius: 0, background: 'white' }}>
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                navigate('upcomingappointments');
                                                handleClose();
                                            }}
                                        >
                                            Upcoming Appointments
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                navigate('changeproviderpassword');
                                                handleClose();
                                            }}
                                        >
                                            Change Password
                                        </MenuItem>

                                        {authData.isInPermission('Provider Leave') && (
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`providerleave`);
                                                    handleClose();
                                                }}
                                            >
                                                Provider's Leave
                                            </MenuItem>
                                        )}

                                        {authData.isInPermission('Application settings') && (
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`basicsettings`);
                                                    handleClose();
                                                }}
                                            >
                                                Settings
                                            </MenuItem>
                                        )}

                                        {authData.IsAdmin && <Divider />}
                                        {authData.IsAdmin && (
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`/admin/providers`);
                                                    handleClose();
                                                }}
                                            >
                                                Switch to Admin
                                            </MenuItem>
                                        )}
                                        <Divider />
                                        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                                    </Menu>
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Outlet />
                </header>
            ) : null}
        </>
    );
};

export default ProviderNavbar;
