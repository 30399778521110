import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    Checkbox,
    IconButton,
    TextField,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Typography,
    Box,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    FormControlLabel,
    Modal,
} from '@mui/material';
import { Form, Formik } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import * as Yup from 'yup';
import { RootState } from '../../../reducers';
import moment from 'moment';
import classes from './Styles';

import {
    addPlan,
    editPlans,
    addPlanProgress,
    setIntegratedCarePlan,
    setNewPlanName,
    toggleShowPlanDeleteAlert,
    toggleShowPlanEditMode,
    setSelectedPlan,
} from '../../../store/carePlan.slice';
import { Plan } from '../../../Models/CarePlans/Plan.model';
import { copyObject } from '../../../utils/common';
import { CarePlanEnum } from '../../../Enum/CarePlanEnum';
import { CarePlanStatus } from '../../../utils/mappings';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { SummaryNote } from '../../../Models/DocumentEngagements/SummaryNote.model';
import { IntegratedCarePlan } from '../../../Models/CarePlans/IntegratedCarePlan.model';

const planDeleteFormSchema = Yup.object().shape({
    ReasonForDelete: Yup.string().nullable().required('Select reason.'),
});

const PlanList: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [showDeletedPlans, setShowDeletedPlans] = useState<boolean>(false);
    const [editedPlanIds, setEditedPlanIds] = useState<string[]>([]);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const dispatch = useDispatch();

    const { integratedCarePlan, selectedPlan, newPlanName, reasonsForDelete, showPlanDeleteAlert, isSavingPlan, isSavingPlanProgress, showPlanEditMode } =
        useSelector((state: RootState) => state.carePlan);

    const plans = integratedCarePlan.Plans?.filter((g) => showDeletedPlans || !Boolean(g.DeletedDate));

    const handleOnEditPlan = (id: string, val: any, index: number) => {
        setEditedPlanIds([...editedPlanIds, id]);
        let data = copyObject(integratedCarePlan) as IntegratedCarePlan;
        let selectedPlanInfo = data.Plans.find((p) => p.Id === id);
        if (Boolean(selectedPlanInfo)) {
            selectedPlanInfo.Name = val;
            dispatch(setIntegratedCarePlan(data));
        }
    };

    const handleOnToggleSelectPlan = (isSelected: boolean, id: string) => {
        let data = copyObject(integratedCarePlan);
        data.Plans.forEach((plan) => {
            if (plan.Id === id) {
                plan.IsSelected = isSelected;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnClickAddNew = () => {
        if (Boolean(newPlanName)) {
            dispatch(addPlan(memberId, newPlanName));
        }
    };

    const handleOnCheck = (isShow: boolean) => {
        setShowDeletedPlans(isShow);
    };

    const handleOnOpenProgress = (id: string) => {
        let data = copyObject(integratedCarePlan);
        data.Plans.forEach((plan) => {
            if (plan.Id === id) {
                plan.IsOpenProgress = !plan.IsOpenProgress;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnChangeNewProgress = (id: string, note: string) => {
        let data = copyObject(integratedCarePlan);
        data.Plans.forEach((plan) => {
            if (plan.Id === id) {
                plan.NewProgressNote = note;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const addNewProgress = (id: string) => {
        const plan = integratedCarePlan.Plans.find((g) => g.Id === id);
        dispatch(addPlanProgress(memberId, plan.Id, plan.NewProgressNote));
    };

    const handleOnStatusChange = (id: string, status: CarePlanEnum) => {
        let data = copyObject(integratedCarePlan);
        data.Plans.forEach((plan) => {
            if (plan.Id === id) {
                plan.Status = status;
                plan.IsSelected = status === CarePlanEnum.Achieved ? true : plan.IsSelected;
                dispatch(editPlans(memberId, [plan]));
            }
        });

        dispatch(setIntegratedCarePlan(data));

        if (status === CarePlanEnum.Achieved) {
            let engagementDraftData = copyObject(engagementDraft);
            engagementDraftData.SummaryNotes = engagementDraftData.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.Plan) || [];
            data.Plans.filter((g) => g.IsSelected).forEach((plan) => {
                const summaryNote = {
                    Id: plan.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: plan.Status === CarePlanEnum.Achieved ? `Achieved Plan : ${plan.Name}` : plan.Name,
                    Type: SummaryNoteTypeEnum.Plan,
                    IsConfirm: false,
                    ReferenceIds: [],
                    OrderBy: 2,
                    DisplayName: 'Plan',
                    CategoryGroupId: '',
                } as SummaryNote;
                engagementDraftData.SummaryNotes.push(summaryNote);
                if (plan.Id === id && plan.Status === CarePlanEnum.Achieved) {
                    engagementDraftData.ProgressNotes = engagementDraftData.ProgressNotes || '';
                    engagementDraftData.ProgressNotes += '<p>';
                    engagementDraftData.ProgressNotes += `Achieved Plan : ${plan.Name}`;
                    engagementDraftData.ProgressNotes += '</p>';
                }
            });

            dispatch(saveEngagementDraft(engagementDraftData));
        }
    };

    const handleOnClickSave = () => {
        if (showPlanEditMode) {
            if (Boolean(editedPlanIds.length)) {
                dispatch(
                    editPlans(
                        memberId,
                        integratedCarePlan.Plans.filter((p) => editedPlanIds.some((e) => e === p.Id))
                    )
                );
            } else {
                dispatch(toggleShowPlanEditMode(false));
            }
        } else {
            dispatch(toggleShowPlanEditMode(true));
            setEditedPlanIds([]);
        }
    };

    const handleOnClickDelete = (plan: Plan) => {
        dispatch(setSelectedPlan({ ...plan, SelectedForDelete: true }));
        dispatch(toggleShowPlanDeleteAlert(true));
    };

    const handleOnDeleteSubmit = (values) => {
        values.Status = CarePlanEnum.Deleted;
        values.DeletedDate = new Date().toISOString();
        dispatch(editPlans(memberId, [values]));
    };

    const handleCloseAlert = () => dispatch(toggleShowPlanDeleteAlert(false));

    const toggleOnNewPlan = () => {
        dispatch(setNewPlanName(''));
        setOpenNew(!openNew);
    };

    const renderDeleteAlert = () => {
        return (
            <Modal open={showPlanDeleteAlert} onClose={handleCloseAlert}>
                <Box sx={classes.modalPopupForm}>
                    <div className="mui-modal-header">
                        <Typography variant="h6" component="h6">
                            Delete Plan
                        </Typography>
                    </div>
                    <Formik initialValues={selectedPlan} onSubmit={handleOnDeleteSubmit} validationSchema={planDeleteFormSchema}>
                        {({ values, errors, handleChange }) => (
                            <Form>
                                <div className="mui-modal-body">
                                    <FormControl fullWidth={true} error={Boolean(errors?.ReasonForDelete)} size="small">
                                        <InputLabel required>Reason for Deleting</InputLabel>
                                        <Select
                                            size="small"
                                            value={Boolean(values.ReasonForDelete) ? values.ReasonForDelete : ''}
                                            label="Reason for Deleting*"
                                            name="ReasonForDelete"
                                            onChange={handleChange}
                                        >
                                            {reasonsForDelete.map((reason, index) => (
                                                <MenuItem key={`delete_reason_${index}`} value={reason.Name}>
                                                    {reason.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors?.ReasonForDelete}</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="mui-modal-footer">
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        disabled={isSavingPlan}
                                        endIcon={isSavingPlan ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        Delete
                                    </Button>
                                    <Button size="small" type="button" onClick={handleCloseAlert} variant="outlined" disabled={isSavingPlan}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        );
    };

    const renderPlan = (plan: Plan, index: number) => {
        return (
            <>
                <TableRow
                    key={plan.Id}
                    sx={
                        plan.Status === CarePlanEnum.Achieved
                            ? classes.achievedText
                            : plan.Status === CarePlanEnum.NotMet
                            ? classes.notMetText
                            : plan.Status === CarePlanEnum.Deleted
                            ? classes.deletedText
                            : classes.pointer
                    }
                >
                    {showPlanEditMode && plan.Status !== CarePlanEnum.Achieved && plan.Status !== CarePlanEnum.Deleted ? (
                        <TableCell scope="row" colSpan={showDeletedPlans ? 7 : 6}>
                            <div className="d-flex-row">
                                <span className="count">{index + 1}</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    sx={classes.textInput}
                                    fullWidth
                                    name="Name"
                                    value={plan.Name}
                                    onChange={(e) => handleOnEditPlan(plan.Id, e.target.value, index)}
                                />
                            </div>
                        </TableCell>
                    ) : (
                        <>
                            <TableCell scope="row" onClick={() => handleOnOpenProgress(plan.Id)}>
                                <div className="d-flex-row">
                                    <span className="count">{index + 1}</span> {plan.Name}
                                </div>
                            </TableCell>
                            <TableCell width="150px" onClick={() => handleOnOpenProgress(plan.Id)}>
                                {plan.CreatedBy?.Name}
                            </TableCell>
                            <TableCell width="180px" onClick={() => handleOnOpenProgress(plan.Id)}>
                                {moment(plan.CreatedAt).isValid() ? moment(plan.CreatedAt).format('MM-DD-yyyy hh:mm a') : '-'}
                            </TableCell>
                            {showDeletedPlans ? (
                                <TableCell width="120px" onClick={() => handleOnOpenProgress(plan.Id)}>
                                    {plan.Status === CarePlanEnum.Deleted
                                        ? moment(plan.DeletedDate).isValid()
                                            ? `Deleted On ${moment(plan.DeletedDate).format('MM-DD-yyyy')}`
                                            : '-'
                                        : '-'}
                                </TableCell>
                            ) : null}
                            <TableCell width="120px" onClick={() => handleOnOpenProgress(plan.Id)}>
                                {CarePlanStatus.find((c) => c.Id === plan.Status)?.Name}
                            </TableCell>
                            <TableCell width="110px">
                                <Checkbox
                                    checked={Boolean(plan.IsSelected)}
                                    name="IsSelected"
                                    size="small"
                                    onChange={(e) => handleOnToggleSelectPlan(e.target.checked, plan.Id)}
                                />
                            </TableCell>
                            <TableCell width="140px" className="hover-visble">
                                {plan.Status !== CarePlanEnum.Achieved && plan.Status !== CarePlanEnum.Deleted ? (
                                    <Box sx={classes.actionButtons}>
                                        <Tooltip title="Achieved" placement="top">
                                            <IconButton onClick={() => handleOnStatusChange(plan.Id, CarePlanEnum.Achieved)}>
                                                <DoneIcon color="success" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Not Met" placement="top">
                                            <IconButton onClick={() => handleOnStatusChange(plan.Id, CarePlanEnum.NotMet)}>
                                                <DoNotDisturbIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete" placement="top">
                                            <IconButton onClick={() => handleOnClickDelete(plan)}>
                                                {false ? <CircularProgress size={18} /> : <DeleteIcon color="warning" />}
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                ) : null}
                            </TableCell>
                        </>
                    )}
                </TableRow>
                <TableRow style={{ backgroundColor: 'transparent' }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={showDeletedPlans ? 7 : 6}>
                        <Collapse in={plan.IsOpenProgress} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1, p: 0 }}>
                                <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                                    <TableBody>
                                        {plan.Progress?.length
                                            ? plan.Progress.map((progress) => (
                                                  <TableRow key={progress.Id}>
                                                      <TableCell>
                                                          <IconButton>
                                                              <ThumbUpOffAltIcon />
                                                          </IconButton>
                                                          {progress.Name}
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                            : null}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    sx={classes.textInput}
                                                    fullWidth
                                                    name="Name"
                                                    placeholder="Add Progress Entry"
                                                    value={plan.NewProgressNote}
                                                    autoFocus
                                                    onChange={(e) => handleOnChangeNewProgress(plan.Id, e.target.value)}
                                                    onKeyUp={(e) => e.key === 'Enter' && addNewProgress(plan.Id)}
                                                    InputProps={{
                                                        endAdornment: isSavingPlanProgress ? <CircularProgress size={15} /> : null,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    };

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction="row" columnGap={2}>
                                    <Typography sx={classes.planTitle}>Plans</Typography>
                                    <FormControlLabel
                                        sx={{ m: 0 }}
                                        control={
                                            <Checkbox
                                                checked={showDeletedPlans}
                                                size="small"
                                                name="Show Deleted Plans"
                                                onChange={(e) => handleOnCheck(e.target.checked)}
                                            />
                                        }
                                        label="Show Deleted Plans"
                                    />
                                </Stack>
                            </TableCell>
                            <TableCell width="150px">Provider</TableCell>
                            <TableCell width="180px">Created Date</TableCell>
                            {showDeletedPlans ? <TableCell width="120px">Deleted Date</TableCell> : null}
                            <TableCell width="120px">Status</TableCell>
                            <TableCell width="110px">Select Plan</TableCell>
                            <TableCell width="140px" align="right">
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={handleOnClickSave}>
                                        {isSavingPlan ? <CircularProgress size={18} /> : showPlanEditMode ? <DoneIcon /> : <EditIcon />}
                                    </Button>
                                    <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={toggleOnNewPlan}>
                                        {openNew ? <KeyboardArrowUpIcon /> : <AddIcon />}
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {plans?.length ? (
                    <Table size="small">
                        <TableBody>{plans?.map(renderPlan)}</TableBody>
                    </Table>
                ) : !openNew ? (
                    <div className="no-data-container">No plans to diaplay</div>
                ) : null}
            </TableContainer>
            <Collapse in={openNew} timeout="auto" unmountOnExit>
                <TableContainer sx={classes.tableContainer}>
                    <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        sx={classes.textInput}
                                        fullWidth
                                        name="Name"
                                        autoFocus
                                        placeholder="Enter new plan here"
                                        value={newPlanName}
                                        onChange={(e) => dispatch(setNewPlanName(e.target.value))}
                                    />
                                </TableCell>
                                <TableCell width="140px">
                                    <Box sx={classes.actionButtons}>
                                        <IconButton onClick={handleOnClickAddNew} disabled={!Boolean(newPlanName)}>
                                            {isSavingPlan ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                        </IconButton>
                                        <IconButton onClick={toggleOnNewPlan}>
                                            <HighlightOffSharpIcon color="error" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
            {renderDeleteAlert()}
        </>
    );
};

export default PlanList;
