import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    CircularProgress,
    Box,
    Autocomplete,
    TextField,
    Popper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    Typography,
    Stack,
    Link,
    ListItem,
    FormControl,
    FormHelperText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../../reducers';
import {
    fetchCptCodeGroups,
    fetchDiagnosisCodesFromClaims,
    getCPTUnits,
    getDefaultDiagnosis,
    getICD10DiagnosisByKeyword,
    getPastDiagnosisByMember,
    resetDisgnosysList,
    setDefaultDiagnosis,
    updateDefaultDiagnosis,
} from '../../../store/encounter.slice';
import { CPTcode } from '../../../Models/Shared/CPTCodeGroup';
import { copyObject, getCPTUserRole } from '../../../utils/common';
import { DiagnosisDraft, DiagnosisPointer } from '../../../Models/DocumentEngagements/DiagnosisDraft.model';
import { ICD10 } from '../../../Models/Shared/ICD10.model';
import { setSelectedRole } from '../../../store/assessment.slice';
import classes from '../Styles';
import { EncounterList } from '../../../Models/Encounters/EncounterList';
import { DiagnosisTypeEnum } from '../../../Enum/DiagnosisTypeEnum';
import { getDiagnosisByMember } from '../../../store/clinicalData.slice';
import {
    setDraftEncounterList,
    getICD10DiagnosisByCategoryUpdateList,
    prepareICD10Hierarchy,
    setClaimError,
} from '../../../store/procedureSignOffEncounter.slice';
import { roleEnum } from '../../../utils/assessments';
import Authorization from '../../../utils/Authorization';

const PopperMy = function (props) {
    return <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />;
};

class DiagnosisFromClaimsState {
    Show: boolean;
    Index?: number;
    DxIndex?: number;
    ParentPropName: string;
    PropName: string;

    constructor() {
        this.Show = false;
        this.Index = null;
    }
}

const defaultDiagnosisSchema = Yup.object().shape({
    Diagnosis: Yup.string().nullable().required('Primary diagnosis is required.'),
    FirstDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisFirstList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
    SecondDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisSecondList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
    ThirdDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisThirdList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
    FourthDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisFourthList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
});

const ProcedureSignOffEncounterEdit = (dxError) => {
    const dispatch = useDispatch();
    const [focusInputName, setFocusInputName] = useState('');
    const [showCptErrorMsg] = useState(false);
    const [showDefaultDiagnosisModal, setShowDefaultDiagnosisModal] = useState<boolean>(false);
    const [diagnosisFromClaims, setDiagnosisFromClaims] = useState<DiagnosisFromClaimsState>(new DiagnosisFromClaimsState());
    const {
        cptCodeCategories,
        cptUnits,
        diagnosisFromClaims: claimsDiagnosis,
        memberPastDiagnosys,
        memberDefaultDiagnosis,
        isFetchingDisgnosys,
        diagnosys,
        isFetching,
        isSavingDefaultDiagnosis,
    } = useSelector((state: RootState) => state.encounter);
    const authData = new Authorization();
    const { diagnosises, isDiagnosisFetching } = useSelector((state: RootState) => state.clinicalData);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { currentProvider } = useSelector((state: RootState) => state.shared);
    const { draftEncounterList } = useSelector((state: RootState) => state.procedureSignOffEncounter);
    const { selectedRole } = useSelector((state: RootState) => state.assessment);
    const diagnosisFromClaimsId = 'DiagnosisFromClaims';
    const diagnosisesByFilter = diagnosises.map((d) => {
        return { Id: d.Id, Code: d.Code, Name: d.Name, ICDcodeVersion: d.ICDcodeVersion } as ICD10;
    });
    const memberPastDiagnosysByFilter = memberPastDiagnosys.filter((d) => !(diagnosisesByFilter || []).some((cd) => cd.Code === d.Code));
    const claimsDiagnosisByFilter = claimsDiagnosis.filter(
        (d) => ![...(diagnosisesByFilter || []), ...memberPastDiagnosysByFilter].some((cd) => cd.Code === d.Code)
    );
    const isHraMember = selectedMember?.IsInHra;

    console.log('isHraMember - ' + isHraMember);

    useEffect(() => {
        dispatch(getCPTUnits());
        dispatch(resetDisgnosysList());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentProvider && selectedMember) {
            dispatch(setSelectedRole(currentProvider.Role.RoleName));
            dispatch(getDefaultDiagnosis(selectedMember.Id));
            dispatch(fetchCptCodeGroups(isHraMember, true));
            dispatch(fetchDiagnosisCodesFromClaims(selectedMember.Id, currentProvider.Role.Id));
            dispatch(getDiagnosisByMember(selectedMember.Id, currentProvider.Role.Id));
            dispatch(getPastDiagnosisByMember(selectedMember.Id, currentProvider.Role.Id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProvider, selectedMember]);

    const prepareCPTCodes = (categoryGroupId: string) => {
        let cptCodes = [];
        let cptUserRole = getCPTUserRole();
        if (currentProvider) {
            cptUserRole = getCPTUserRole(currentProvider.Role.RoleName);
        }
        cptCodes = (
            cptCodeCategories
                .find((c) => c.Id === categoryGroupId)
                ?.CPTRoleCodes.find(
                    (c) =>
                        c.Role === cptUserRole ||
                        ((authData.Role === roleEnum.NURSE_PRACTITIONER ||
                            authData.Role === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST ||
                            selectedRole === roleEnum.NURSE_PRACTITIONER ||
                            selectedRole === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST) &&
                            c.Role === 'HRA')
                )?.Codes || []
        ).map((item) => {
            return { ...item, Name: `${item.Code} - ${item.Description}` };
        });

        return cptCodes as CPTcode[];
    };

    const filteredCPTCodes = (categoryGroupId: string) => {
        let cptCodes = prepareCPTCodes(categoryGroupId);
        cptCodes = cptCodes.filter((c) => c.Code !== '83036');
        return cptCodes as CPTcode[];
    };

    const prepareCPTUnits = () => {
        return cptUnits.filter((c) => c.Code === 'T1016');
    };

    const handleOnChangeCpt = (val: any, propName: string, index: number) => {
        let data = copyObject(draftEncounterList);
        data.Encounters[index][propName] = val;
        if (propName === 'CategoryGroupId') {
            data.Encounters[index].CptCodeId = null;
        }

        dispatch(setDraftEncounterList(data));
    };

    const handleOnChangePrimaryDx = (val: ICD10, propName: string, index: number, dxIndex: number) => {
        const fieldValue = Boolean(val) ? `${val.Code} ${val.Name}` : '';
        let data = copyObject(draftEncounterList);
        data.Encounters[index].DiagnosisList[dxIndex][propName] = fieldValue;
        const selectedDiagnosis = Boolean(val) ? diagnosys.find((d) => d.Code === val.Code) : null;
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisPointer = selectedDiagnosis;
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFirstList = [];
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisSecondList = [];
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisThirdList = [];
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
        data.Encounters[index].DiagnosisList[dxIndex].FirstDiagnosis = null;
        data.Encounters[index].DiagnosisList[dxIndex].SecondDiagnosis = null;
        data.Encounters[index].DiagnosisList[dxIndex].ThirdDiagnosis = null;
        data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;

        dispatch(setDraftEncounterList(data));
    };

    const handleOnChangeDx = (val: any, propName: string, index: number, dxIndex: number, diagnosis?: ICD10) => {
        let data = copyObject(draftEncounterList);
        data.Encounters[index].DiagnosisList[dxIndex][propName] = val;
        if (Boolean(diagnosis)) {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisPointer = diagnosis;
        }

        if (propName === 'FirstDiagnosis') {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisSecondList = [];
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisThirdList = [];
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
            data.Encounters[index].DiagnosisList[dxIndex].SecondDiagnosis = null;
            data.Encounters[index].DiagnosisList[dxIndex].ThirdDiagnosis = null;
            data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;
        }
        if (propName === 'SecondDiagnosis') {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisThirdList = [];
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
            data.Encounters[index].DiagnosisList[dxIndex].ThirdDiagnosis = null;
            data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;
        }
        if (propName === 'ThridDiagnosis') {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
            data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;
        }

        dispatch(setDraftEncounterList(data));
    };

    const handleOnChangeDefaultDiagnosis = (val: ICD10, nextListPropName: string) => {
        const fieldValue = Boolean(val) ? `${val.Code} ${val.Name}` : '';
        let data = copyObject(memberDefaultDiagnosis.DefaultDiagnosis) as DiagnosisDraft;
        data.Diagnosis = fieldValue;
        const selectedDiagnosis = Boolean(val) ? diagnosys.find((d) => d.Code === val.Code) : null;
        if (Boolean(selectedDiagnosis)) {
            data.DiagnosisPointer = {
                Id: selectedDiagnosis.Id,
                Name: selectedDiagnosis.Name,
                Code: selectedDiagnosis.Code,
                ICDcodeVersion: selectedDiagnosis.ICDcodeVersion,
                DiagnosisType: DiagnosisTypeEnum.Primary,
            } as DiagnosisPointer;
        } else {
            data.DiagnosisPointer = null;
        }
        data.DiagnosisFirstList = [];
        data.DiagnosisSecondList = [];
        data.DiagnosisThirdList = [];
        data.DiagnosisFourthList = [];
        data.FirstDiagnosis = null;
        data.SecondDiagnosis = null;
        data.ThirdDiagnosis = null;
        data.FourthDiagnosis = null;

        dispatch(setDefaultDiagnosis({ ...memberDefaultDiagnosis, DefaultDiagnosis: data }));
        if (Boolean(val?.Code)) {
            dispatch(getICD10DiagnosisByCategoryUpdateList(val.Code, 0, 0, nextListPropName, true));
        }
    };

    const handleOnChangeDifalultDx = (val: any, propName: string, nextListPropName: string = '') => {
        let data = copyObject(memberDefaultDiagnosis.DefaultDiagnosis) as DiagnosisDraft;
        data[propName] = val;

        if (propName === 'FirstDiagnosis') {
            data.DiagnosisSecondList = [];
            data.DiagnosisThirdList = [];
            data.DiagnosisFourthList = [];
            data.SecondDiagnosis = null;
            data.ThirdDiagnosis = null;
            data.FourthDiagnosis = null;
        }
        if (propName === 'SecondDiagnosis') {
            data.DiagnosisThirdList = [];
            data.DiagnosisFourthList = [];
            data.ThirdDiagnosis = null;
            data.FourthDiagnosis = null;
        }
        if (propName === 'ThridDiagnosis') {
            data.DiagnosisFourthList = [];
            data.FourthDiagnosis = null;
        }

        if (Boolean(nextListPropName)) {
            data[nextListPropName] = [];
            if (Boolean(val?.Code)) {
                dispatch(getICD10DiagnosisByCategoryUpdateList(val.Code, 0, 0, nextListPropName, true));
            }
        }
        dispatch(setDefaultDiagnosis({ ...memberDefaultDiagnosis, DefaultDiagnosis: data }));
    };

    const handleOnClickAdd = () => {
        let data = copyObject(draftEncounterList);
        let tempId = (Math.random() + 1).toString(36).substring(7);
        data.Encounters.push({ tempId, DiagnosisList: [] });
        dispatch(setDraftEncounterList(data));
    };

    const handleOnClickRemove = (index) => {
        let data = copyObject(draftEncounterList);
        data.Encounters = data.Encounters.filter((item, i) => i !== index);
        dispatch(setDraftEncounterList(data));
    };

    const handleOnClickAddDiagnosis = (index: number) => {
        let data = copyObject(draftEncounterList) as EncounterList;
        if (data.Encounters[index].DiagnosisList?.some((d) => !d.Diagnosis)) {
            dispatch(setClaimError({ Show: true, Message: 'Please select a diagnosis' }));
            return;
        }
        data.Encounters[index].DiagnosisList.push(new DiagnosisDraft());
        dispatch(setDraftEncounterList(data));
    };

    const handleOnClickRemoveDiagnosis = (index: number, dxIndex: number) => {
        let data = copyObject(draftEncounterList);
        data.Encounters[index].DiagnosisList = data.Encounters[index].DiagnosisList.filter((_, i) => i !== dxIndex);
        dispatch(setDraftEncounterList(data));
    };

    const prepareDiagnosis = (index) => {
        return [
            { Id: diagnosisFromClaimsId, Code: 'Add Diagnosis from Claims', Name: '' },
            ...diagnosys.filter((d) => {
                const val = `${d.Code} ${d.Name}`;
                if (draftEncounterList.Encounters[index]?.DiagnosisList.some((d) => d.Diagnosis === val)) {
                    return false;
                } else {
                    return true;
                }
            }),
        ];
    };

    const handleOnClickSetDefaultPrimary = (index: number) => {
        let data = copyObject(draftEncounterList);
        data.Encounters[index].DiagnosisList[0] = memberDefaultDiagnosis.DefaultDiagnosis;
        dispatch(setDraftEncounterList(data));
    };

    const handleOnChangeSelect = (val, index, dxIndex, bindResponsePropName) => {
        if (Boolean(val) && Boolean(val.Code)) {
            dispatch(getICD10DiagnosisByCategoryUpdateList(val.Code, index, dxIndex, bindResponsePropName));
        }
    };

    const handleOnSelectDxFromClaims = (val: any, index: number, dxIndex: number) => {
        dispatch(prepareICD10Hierarchy(val, index, dxIndex));
    };

    const renderDiagnosisList = (title: string, diagnosises: ICD10[], selectedDiagnosis: string[]) => {
        if (!Boolean(diagnosises?.length)) {
            return null;
        }

        return (
            <Stack spacing={2} marginBottom={2}>
                <Typography variant="subtitle2" component="label">
                    {title}
                </Typography>
                <Box>
                    {diagnosises.map((diagnosis, index) => (
                        <Chip
                            key={`diagnosis-${diagnosis.Id}-${index}`}
                            sx={{ mb: 2, mr: 2 }}
                            disabled={selectedDiagnosis.some((s) => s === `${diagnosis.Code.replaceAll('.', '')}`)}
                            onClick={() => {
                                handleOnSelectDxFromClaims(diagnosis.Code, diagnosisFromClaims.Index, diagnosisFromClaims.DxIndex);
                                setDiagnosisFromClaims(new DiagnosisFromClaimsState());
                            }}
                            label={`${diagnosis.Code} ${diagnosis.Name}`}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                </Box>
            </Stack>
        );
    };

    const renderDiagnosisFromClaimsModal = () => {
        const selectedDiagnosis = [];
        draftEncounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.Diagnosis?.substring(0, 3)));

        draftEncounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) =>
            selectedDiagnosis.push(d.DiagnosisPointer?.Code.replaceAll('.', ''))
        );

        draftEncounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) =>
            selectedDiagnosis.push(d.FirstDiagnosis?.Code.replaceAll('.', ''))
        );

        draftEncounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) =>
            selectedDiagnosis.push(d.SecondDiagnosis?.Code.replaceAll('.', ''))
        );

        draftEncounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) =>
            selectedDiagnosis.push(d.ThirdDiagnosis?.Code.replaceAll('.', ''))
        );

        draftEncounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) =>
            selectedDiagnosis.push(d.FourthDiagnosis?.Code.replaceAll('.', ''))
        );

        return (
            <Dialog
                open={diagnosisFromClaims.Show}
                maxWidth={false}
                PaperProps={{
                    sx: { alignSelf: 'flex-start' },
                }}
            >
                <DialogTitle>Select Diagnosis</DialogTitle>
                <DialogContent dividers sx={{ width: '800px', maxWidth: '100%' }}>
                    {isFetching || isDiagnosisFetching ? (
                        <Box display="flex" justifyContent="center" className="loader-container">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <React.Fragment>
                            {renderDiagnosisList('Clinical Data', diagnosisesByFilter, selectedDiagnosis)}
                            {renderDiagnosisList('Past Encounters', memberPastDiagnosysByFilter, selectedDiagnosis)}
                            {renderDiagnosisList('Claims', claimsDiagnosisByFilter, selectedDiagnosis)}
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button size="small" type="button" onClick={() => setDiagnosisFromClaims(new DiagnosisFromClaimsState())} variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleOnCloseDefaultDiagnosis = () => {
        setShowDefaultDiagnosisModal(false);
        dispatch(getDefaultDiagnosis(selectedMember.Id));
    };

    const handleSubmitDefaultDiagnosis = (values: DiagnosisDraft) => {
        dispatch(
            updateDefaultDiagnosis({ ...memberDefaultDiagnosis, DefaultDiagnosis: values, MemberId: selectedMember.Id }, () => {
                setShowDefaultDiagnosisModal(false);
            })
        );
    };

    const renderDefaultPrimaryDiagnosis = () => {
        const inputName = 'default-primary-auto-complete';

        return (
            <Dialog sx={classes.form} open={showDefaultDiagnosisModal} onClose={handleOnCloseDefaultDiagnosis} scroll="paper">
                <Formik
                    initialValues={memberDefaultDiagnosis.DefaultDiagnosis}
                    onSubmit={handleSubmitDefaultDiagnosis}
                    validationSchema={defaultDiagnosisSchema}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue, errors, handleChange, handleBlur, submitCount }: FormikProps<DiagnosisDraft>) => (
                        <Form>
                            <DialogTitle id="scroll-dialog-drafts" display="flex" alignItems="center" justifyContent="space-between">
                                <span>Set Default Primary Diagnosis</span>
                            </DialogTitle>
                            <DialogContent dividers={true} style={{ width: '500px' }}>
                                <Autocomplete
                                    id={`primary-auto-select-default`}
                                    fullWidth
                                    isOptionEqualToValue={(option, value) => option.Name === value.Name}
                                    getOptionLabel={(option: any) => (typeof option === 'object' ? `${option.Code} ${option.Name}` : option || '')}
                                    value={values.Diagnosis as any}
                                    options={diagnosys || []}
                                    loading={isFetchingDisgnosys && focusInputName === inputName}
                                    freeSolo
                                    onChange={(e, value: any) => handleOnChangeDefaultDiagnosis(value, 'DiagnosisFirstList')}
                                    PopperComponent={PopperMy}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Diagnosis"
                                            required
                                            variant="outlined"
                                            onChange={(e) => {
                                                dispatch(getICD10DiagnosisByKeyword(e.target.value, currentProvider?.Role?.Id));
                                            }}
                                            onFocus={() => setFocusInputName(inputName)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isFetchingDisgnosys && focusInputName === inputName ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            error={Boolean(errors.Diagnosis) && Boolean(submitCount)}
                                        />
                                    )}
                                />
                                {Boolean(values.DiagnosisFirstList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.FirstDiagnosis || errors.FirstDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-first-type-select`}
                                            value={Boolean(values.FirstDiagnosis?.Code) ? values.FirstDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) => {
                                                const selectedDiagnosis = values.DiagnosisFirstList.find((d) => d.Code === e.target.value);
                                                handleOnChangeDifalultDx(selectedDiagnosis, 'FirstDiagnosis', 'DiagnosisSecondList');
                                            }}
                                            error={Boolean(errors.FirstDiagnosis || errors.FirstDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisFirstList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.FirstDiagnosis || errors.FirstDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                                {Boolean(values.DiagnosisSecondList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.SecondDiagnosis || errors.SecondDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-second-select`}
                                            value={Boolean(values.SecondDiagnosis?.Code) ? values.SecondDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) => {
                                                const selectedDiagnosis = values.DiagnosisSecondList.find((d) => d.Code === e.target.value);
                                                handleOnChangeDifalultDx(selectedDiagnosis, 'SecondDiagnosis', 'DiagnosisThirdList');
                                            }}
                                            error={Boolean(errors.SecondDiagnosis || errors.SecondDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisSecondList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.SecondDiagnosis || errors.SecondDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                                {Boolean(values.DiagnosisThirdList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.ThirdDiagnosis || errors.ThirdDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-third-select`}
                                            value={Boolean(values.ThirdDiagnosis?.Code) ? values.ThirdDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) => {
                                                const selectedDiagnosis = values.DiagnosisThirdList.find((d) => d.Code === e.target.value);
                                                handleOnChangeDifalultDx(selectedDiagnosis, 'ThirdDiagnosis', 'DiagnosisFourthList');
                                            }}
                                            error={Boolean(errors.ThirdDiagnosis || errors.ThirdDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisThirdList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.ThirdDiagnosis || errors.ThirdDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                                {Boolean(values.DiagnosisFourthList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.FourthDiagnosis || errors.FourthDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-fourth-select`}
                                            value={Boolean(values.FourthDiagnosis?.Code) ? values.FourthDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) =>
                                                handleOnChangeDifalultDx(
                                                    values.DiagnosisFourthList.find((d) => d.Code === e.target.value),
                                                    'FourthDiagnosis'
                                                )
                                            }
                                            error={Boolean(errors.FourthDiagnosis || errors.FourthDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisFourthList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.FourthDiagnosis || errors.FourthDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className="text-transform-none"
                                    disabled={!Boolean(values.Diagnosis) || isSavingDefaultDiagnosis}
                                    endIcon={isSavingDefaultDiagnosis ? <CircularProgress size={18} color="inherit" /> : null}
                                >
                                    Save
                                </Button>
                                <Button variant="outlined" onClick={handleOnCloseDefaultDiagnosis} className="text-transform-none">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            <Grid item className="content-form">
                {isLoading || isFetching ? (
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Stack direction="row" justifyContent="space-between" spacing={2} marginBottom={4}>
                            <Button variant="outlined" onClick={() => setShowDefaultDiagnosisModal(true)}>
                                Set default primary diagnosis
                            </Button>
                            <Typography>
                                <span style={{ color: 'grey' }}>Assessment By:</span> {currentProvider?.FullName + '(' + currentProvider?.Role?.RoleName + ')'}
                            </Typography>
                        </Stack>
                        <TableContainer component={Paper} sx={classes.procedureOffTable}>
                            <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="35%">CPT Category</TableCell>
                                        <TableCell width="35%">Primary CPT</TableCell>
                                        <TableCell width="20%"></TableCell>
                                        <TableCell width="10%"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {draftEncounterList.Encounters?.map((encounter, index) => (
                                        <React.Fragment key={`encounter_list_${index}_${encounter.Id || encounter.tempId}`}>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <Select
                                                        fullWidth
                                                        id={`admission-type-select-${index}`}
                                                        value={encounter.CategoryGroupId}
                                                        size="small"
                                                        className="hide-textinput-label"
                                                        onChange={(e) => handleOnChangeCpt(e.target.value, 'CategoryGroupId', index)}
                                                    >
                                                        {cptCodeCategories.map((category) => (
                                                            <MenuItem key={`menu_item_${category.Id}`} value={category.Id}>
                                                                {category.GroupName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Autocomplete
                                                        id={`cpt-codes-auto-complete-${index}`}
                                                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                                        getOptionLabel={(option) => (option as any).Name}
                                                        inputValue={
                                                            prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId)?.Name || ''
                                                        }
                                                        value={
                                                            (prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId) ||
                                                                null) as any
                                                        }
                                                        options={filteredCPTCodes(encounter.CategoryGroupId)}
                                                        getOptionDisabled={(option) =>
                                                            draftEncounterList.Encounters.some((e) => e.CptCodeId === option.Id && e.CptCodeId !== encounter.Id)
                                                        }
                                                        size="small"
                                                        freeSolo
                                                        onChange={(e, value) => handleOnChangeCpt((value as any)?.Id, 'CptCodeId', index)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className="hide-textinput-label"
                                                                variant="outlined"
                                                                error={showCptErrorMsg && !Boolean(encounter.CptCodeId)}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {encounter.CptCodeId &&
                                                    prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId) ? (
                                                        <Select
                                                            fullWidth
                                                            id={`units-type-select-${index}`}
                                                            value={encounter.Units}
                                                            size="small"
                                                            className="hide-textinput-label"
                                                            defaultValue={1}
                                                            onChange={(e) => handleOnChangeCpt(e.target.value, 'Units', index)}
                                                        >
                                                            <MenuItem value={0} disabled>
                                                                Select Units
                                                            </MenuItem>
                                                            {prepareCPTUnits().map((unit, index) => (
                                                                <MenuItem key={`menu_item_${unit.Id}`} value={unit.Units}>
                                                                    {unit.Units * unit.UnitValue} {unit.UnitLabel}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell component="th" align="right">
                                                    <IconButton aria-label="delete" style={{ color: 'red' }} onClick={() => handleOnClickRemove(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} className={draftEncounterList.Encounters.length !== index + 1 ? 'bottom-border' : ''}>
                                                    <Box sx={{ pt: 0 }}>
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography variant="h6" component="label">
                                                                Diagnosis
                                                            </Typography>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="primary"
                                                                onClick={() => handleOnClickAddDiagnosis(index)}
                                                                disabled={encounter.DiagnosisList?.length > 3}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Stack>
                                                        <Table size="small" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                            <TableBody>
                                                                {(encounter.DiagnosisList || []).map((dx, dxIndex) => {
                                                                    const canShowDefault =
                                                                        dxIndex === 0 && Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.Diagnosis);
                                                                    const paddingClassName = canShowDefault ? 'pb-50' : '';
                                                                    const defaultDiagnosis = paddingClassName
                                                                        ? memberDefaultDiagnosis?.DefaultDiagnosis?.Diagnosis.split(' ')[0]
                                                                        : '';

                                                                    return (
                                                                        <TableRow
                                                                            key={`diagnosis-row-${index}-${dxIndex}`}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell width="2.5%" className={paddingClassName}>
                                                                                {dxIndex + 1}
                                                                            </TableCell>
                                                                            <TableCell sx={{ pl: 0 }}>
                                                                                <Autocomplete
                                                                                    id={`primary-auto-select-${index}-${dxIndex}`}
                                                                                    fullWidth
                                                                                    isOptionEqualToValue={(option, value) => option.Name === value.Name}
                                                                                    getOptionLabel={(option: any) =>
                                                                                        typeof option === 'object'
                                                                                            ? `${option.Code} ${option.Name}`
                                                                                            : option || ''
                                                                                    }
                                                                                    value={dx.Diagnosis as any}
                                                                                    options={prepareDiagnosis(index)}
                                                                                    loading={
                                                                                        isFetchingDisgnosys &&
                                                                                        focusInputName === `primary-auto-complete-${index}-${dxIndex}`
                                                                                    }
                                                                                    freeSolo
                                                                                    size="small"
                                                                                    renderOption={(props, option) =>
                                                                                        option.Id === diagnosisFromClaimsId ? (
                                                                                            <ListItem
                                                                                                {...props}
                                                                                                onClick={null}
                                                                                                sx={classes.selectInputLinkItem}
                                                                                            >
                                                                                                <Link
                                                                                                    onClick={() =>
                                                                                                        setDiagnosisFromClaims({
                                                                                                            Show: true,
                                                                                                            ParentPropName: 'PrimaryDiagnosis',
                                                                                                            PropName: 'Diagnosis',
                                                                                                            Index: index,
                                                                                                            DxIndex: dxIndex,
                                                                                                        })
                                                                                                    }
                                                                                                >
                                                                                                    {option.Code}
                                                                                                </Link>
                                                                                            </ListItem>
                                                                                        ) : (
                                                                                            <ListItem {...props}>{`${option.Code} ${option.Name}`}</ListItem>
                                                                                        )
                                                                                    }
                                                                                    onChange={(e, value: any) => {
                                                                                        handleOnChangePrimaryDx(value, 'Diagnosis', index, dxIndex);
                                                                                        handleOnChangeSelect(value, index, dxIndex, 'DiagnosisFirstList');
                                                                                    }}
                                                                                    PopperComponent={PopperMy}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            className="hide-textinput-label"
                                                                                            variant="outlined"
                                                                                            onChange={(e) =>
                                                                                                dispatch(
                                                                                                    getICD10DiagnosisByKeyword(
                                                                                                        e.target.value,
                                                                                                        currentProvider?.Role?.Id
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                            onFocus={() =>
                                                                                                setFocusInputName(`primary-auto-complete-${index}-${dxIndex}`)
                                                                                            }
                                                                                            InputProps={{
                                                                                                ...params.InputProps,
                                                                                                endAdornment: (
                                                                                                    <React.Fragment>
                                                                                                        {isFetchingDisgnosys &&
                                                                                                        focusInputName === `primary-auto-complete-${index}` ? (
                                                                                                            <CircularProgress color="inherit" size={20} />
                                                                                                        ) : null}
                                                                                                        {params.InputProps.endAdornment}
                                                                                                    </React.Fragment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {canShowDefault ? (
                                                                                    <Button
                                                                                        sx={{ fontSize: '10px' }}
                                                                                        size="small"
                                                                                        className="mt-10"
                                                                                        onClick={() => handleOnClickSetDefaultPrimary(index)}
                                                                                    >
                                                                                        Set {defaultDiagnosis} as primary diagnosis
                                                                                    </Button>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisFirstList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-first-type-select-${index}`}
                                                                                        value={Boolean(dx.FirstDiagnosis?.Code) ? dx.FirstDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) => {
                                                                                            const selectedDiagnosis = dx.DiagnosisFirstList.find(
                                                                                                (d) => d.Code === e.target.value
                                                                                            );
                                                                                            handleOnChangeDx(
                                                                                                selectedDiagnosis,
                                                                                                'FirstDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            );
                                                                                            handleOnChangeSelect(
                                                                                                selectedDiagnosis,
                                                                                                index,
                                                                                                dxIndex,
                                                                                                'DiagnosisSecondList'
                                                                                            );
                                                                                        }}
                                                                                        error={!Boolean(dx.FirstDiagnosis?.Code) && dxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisFirstList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisSecondList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-second-select-${index}`}
                                                                                        value={Boolean(dx.SecondDiagnosis?.Code) ? dx.SecondDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) => {
                                                                                            const selectedDiagnosis = dx.DiagnosisSecondList.find(
                                                                                                (d) => d.Code === e.target.value
                                                                                            );
                                                                                            handleOnChangeDx(
                                                                                                selectedDiagnosis,
                                                                                                'SecondDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            );
                                                                                            handleOnChangeSelect(
                                                                                                selectedDiagnosis,
                                                                                                index,
                                                                                                dxIndex,
                                                                                                'DiagnosisThirdList'
                                                                                            );
                                                                                        }}
                                                                                        error={!Boolean(dx.SecondDiagnosis?.Code) && dxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisSecondList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisThirdList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-third-select-${index}`}
                                                                                        value={Boolean(dx.ThirdDiagnosis?.Code) ? dx.ThirdDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) => {
                                                                                            const selectedDiagnosis = dx.DiagnosisThirdList.find(
                                                                                                (d) => d.Code === e.target.value
                                                                                            );
                                                                                            handleOnChangeDx(
                                                                                                selectedDiagnosis,
                                                                                                'ThirdDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            );
                                                                                            handleOnChangeSelect(
                                                                                                selectedDiagnosis,
                                                                                                index,
                                                                                                dxIndex,
                                                                                                'DiagnosisFourthList'
                                                                                            );
                                                                                        }}
                                                                                        error={!Boolean(dx.ThirdDiagnosis?.Code) && dxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisThirdList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisFourthList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-fourth-select-${index}`}
                                                                                        value={Boolean(dx.FourthDiagnosis?.Code) ? dx.FourthDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) =>
                                                                                            handleOnChangeDx(
                                                                                                dx.DiagnosisFourthList.find((d) => d.Code === e.target.value),
                                                                                                'FourthDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            )
                                                                                        }
                                                                                        error={!Boolean(dx.FourthDiagnosis?.Code) && dxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisFourthList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell width="5%" className={paddingClassName}>
                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    style={{ color: 'red' }}
                                                                                    onClick={() => handleOnClickRemoveDiagnosis(index, dxIndex)}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            size="small"
                            sx={{ mt: 3 }}
                            onClick={handleOnClickAdd}
                            style={{ textTransform: 'none' }}
                        >
                            Add More
                        </Button>
                    </>
                )}
                {renderDiagnosisFromClaimsModal()}
            </Grid>
            {renderDefaultPrimaryDiagnosis()}
        </Grid>
    );
};

export default ProcedureSignOffEncounterEdit;
