import { Button, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveProviders } from '../../store/provider.slice';
import { getTCCRoleProviders } from '../../store/provider.slice';
import { getLicensedRoleProviders } from '../../store/provider.slice';
import Authorization from '../../utils/Authorization';
import { useNavigate } from 'react-router';
import {
    getCustomDashboardTabs,
    getStartingRoleWidgets,
    setSelectedTab,
    setSelectedTabModal,
    setShowWidgetsMenu,
    setWidgetsToRender,
    setIsWidgetExisting,
} from '../../store/smartDashboard.slice';
import { RootState } from '../../reducers';
import { allWidgetsList } from './Shared/AllWidgetsList';
import SetVisibleWidgetsDialog from './SetVisibleWidgetsDialog/SetVisibleWidgetsDialog';
import { useHealthPlanContext } from '../../hooks/healthPlanContext';
import { usePopulationContext } from '../../hooks/populationContext';
import { useHedisMetricsContext } from '../../hooks/hedisMetricsContext';
import { getRequestType } from '../../store/request.slice';
import { fetchPopulationList } from '../../store/adminMembers.slice';
import { fetchTeamsName, fetchNpOrBhTeams } from '../../store/teams.slice';
import Loading from '../Shared/Loading';
import AlertDialog from '../AlertDialog/AlertDialog';
import { setIsLoading } from '../../store/shared.slice';

interface Props {
    comp: JSX.Element;
}

const Wrapper: React.FunctionComponent<Props> = (props) => {
    const { comp: Comp } = props;
    return <>{Comp}</>;
};

const SmartDashboard = (props) => {
    const auth = new Authorization();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { customTabsList, selectedTab, widgetsToRender, isWidgetExisting, widgetFromMetricSummary } = useSelector((state: RootState) => state.smartDashboard);
    const { teams } = useSelector((state: RootState) => state.teams);

    const { setStatus, setTeam, loadedTeam, setLoadedTeam } = useHedisMetricsContext();
    const { getHealthPlans } = useHealthPlanContext();
    const { setPopulation, populations, getPopulations, setAnalyticPopulation, analyticPopulations, getAnalyticPopulations } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [showWidgetAlert, setShowWidgetAlert] = useState<boolean>(false);

    const tabChangeHandler = (e, newValue) => {
        dispatch(setSelectedTab(newValue));
    };

    const showWidgetsMenuHandler = (selectedTab) => {
        dispatch(setSelectedTabModal(selectedTab));
        dispatch(setShowWidgetsMenu(true));
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        dispatch(setShowWidgetsMenu(false));
        dispatch(fetchPopulationList());
        if (!Boolean(populations?.length)) {
            getPopulations();
        }

        if (!Boolean(analyticPopulations?.length)) {
            getAnalyticPopulations();
        }

        if (!isWidgetExisting) {
            setTimeout(() => {
                setShowWidgetAlert(true);
                dispatch(setIsLoading(false));
            }, 1500);
        }

        setIsFetched(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetched, setIsFetched, setPopulation, setStatus, setAnalyticPopulation]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        const loggedInTeamId = auth.TeamId;
        if (Boolean(loggedInTeamId) && !loadedTeam) {
            const teamInfo = teams.find((t) => t.Id === loggedInTeamId);
            if (Boolean(teamInfo)) {
                setTeam(loggedInTeamId);
                setLoadedTeam(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    useEffect(() => {
        if (customTabsList?.length > 0) {
            const newWidgetsToRender = customTabsList.map((tab) =>
                tab.visibleWidgets.map((widget, index) => <Wrapper key={index} comp={allWidgetsList.find((w) => w.name === widget)?.component} />)
            );
            dispatch(setWidgetsToRender(newWidgetsToRender));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customTabsList]);

    useEffect(() => {
        dispatch(getActiveProviders(true));
        dispatch(getTCCRoleProviders(true));
        dispatch(getLicensedRoleProviders(true));
        dispatch(fetchTeamsName());
        dispatch(fetchNpOrBhTeams());
        dispatch(getRequestType());
        dispatch(getStartingRoleWidgets(auth.Role));
        dispatch(getCustomDashboardTabs(auth.UserId, auth.Role));
        getHealthPlans();
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToMetrics = () => {
        history(`/provider/metricsummary`);
    };

    const handleOnConfirmed = () => {
        dispatch(setIsWidgetExisting(true));
        dispatch(setShowWidgetsMenu(true));
        setShowWidgetAlert(false);
    };

    return (
        <>
            <Loading message="Loading..." />
            <SetVisibleWidgetsDialog />
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign={'right'} p={2} marginY={2} position="sticky" top={0} width={'100vw'} zIndex={1200} sx={{ background: '#ffffff' }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Tabs value={selectedTab} onChange={tabChangeHandler}>
                            {customTabsList?.map((item, index) => {
                                return <Tab key={index} value={index} label={item.tabName} />;
                            })}
                        </Tabs>
                        <Stack direction="row">
                            <Button
                                variant="text"
                                sx={{ marginLeft: 'auto', paddingRight: '15px' }}
                                onClick={() => {
                                    navigateToMetrics();
                                }}
                            >
                                Back To Metrics
                            </Button>
                            <Button sx={{ marginLeft: 'auto' }} onClick={() => showWidgetsMenuHandler(selectedTab)}>
                                Customize Dashboard
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
                {widgetsToRender?.map((list, index) => {
                    return selectedTab === index && list;
                })}
            </Grid>

            <AlertDialog
                open={showWidgetAlert}
                title="Confirmation"
                cancelText="Cancel"
                okText="Continue"
                onConfirm={() => handleOnConfirmed()}
                onClose={() => {
                    dispatch(setIsWidgetExisting(true));
                    dispatch(setShowWidgetsMenu(false));
                    setShowWidgetAlert(false);
                }}
                message={`Widget ${widgetFromMetricSummary} has not been added to your dashboard. Would you like to add it now?`}
            />
        </>
    );
};

export default SmartDashboard;
